<template>
  <div style="margin-top: 30px">
    <b-overlay :show="showLoading" no-wrap />

    <validation-observer ref="AssureRules">
      <b-form>
        <!--Assuré principal-->
        <b-row>
          <b-col cols="12">
            <b-row>
              <!-- Date d'effet -->
              <b-col md="3">
                <span v-b-popover.hover.top.html="infoDateEffet" v-ripple.400="'rgba(113, 102, 240, 0.15)'" title="Informations"  class="" variant="outline-primary">

                  <b-form-group label="Date d'effet *" label-for="date-effet">
                    <validation-provider #default="{ errors }" name="date d'effet" rules="required">
                      <flat-pickr id="date-effet-fma" v-model="composeData.objet.dateEffet"
                        :class="errors.length > 0 ? 'is-invalid' : ''" :config="options.dateEffet" class="form-control"
                        placeholder="Date d'effet" rules="required" autocomplete="off" />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </span>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="12">
            <h4 class="title-custom-wizard"><u>Assuré principal (signataire)</u></h4>
          </b-col>

          <!-- Date de naissance -->
          <b-col md="3">
            <b-form-group label="Date de naissance *" label-for="date-naissance">
              <validation-provider #default="{ errors }" name="date de naissance" rules="required">
                <flat-pickr id="date-naissance-assure-principal-fma" v-model="composeData.assurePrincipal.dateNaissance"
                  :class="errors.length > 0 ? 'is-invalid' : ''" :config="options.dateNaissance" class="form-control"
                  placeholder="Date de naissance" autocomplete="off" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }}
                </b-form-invalid-feedback>
                <b-form-invalid-feedback :state="errorDateNaissanceProspect ? false : null"> {{ errorDateNaissanceProspect
                }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Situation familiale -->
          <b-col md="3">
            <b-form-group label="Situation familiale*" label-for="situation_familiale">
              <validation-provider #default="{ errors }" name="Situation familiale" rules="required">
                <b-form-select v-model="composeData.assurePrincipal.situation_familiale" id="situation_familiale">
                  <b-form-select-option :value="null" disabled>Choisissez</b-form-select-option>
                  <b-form-select-option v-for="(val, value) in situationFamiliale" :key="value" :value="val.value" :state="errors.length > 0 ? false : null">{{val.name}}</b-form-select-option>
                </b-form-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Capital assure -->
          <!-- <b-col md="3">
            <b-form-group label="Capital assure *" label-for="capital assure">
              <validation-provider #default="{ errors }" name="Capital assure" rules="required">
                <b-form-select v-model="composeData.objet.capital_assure" :options="capitalOptions" :state="errors.length > 0 ? false : null" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col> -->
        </b-row>
        <b-row v-if="hasConjointBySituationFamiliale()">
          <b-col md="3">
            <b-form-group label="Conjoint à assurer ?">
              <validation-provider v-slot="{ errors }" name="Conjoint à assurer" rules="required">
                <b-form-radio-group v-model="composeData.objet.isConjoint" :options="options.booleanValue" label="Conjoint à assurer"/>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <!--Conjoint-->
        <b-row v-if="hasConjointBySituationFamiliale() && composeData.objet.isConjoint == 1" class="mb-1">
          <b-col md="12" sm="12">
            <h4 class="title-custom-wizard mt-2"><u>Conjoint</u></h4>
          </b-col>
          <!-- Date de naissance -->
          <b-col md="3">
            <b-form-group label="Date de naissance *" label-for="conjoint-date-naissance">
              <validation-provider #default="{ errors }" name="date de naissance du conjoint" :rules="composeData.objet.isConjoint == 1 ? 'required' : ''">
                <flat-pickr id="date-naissance-assure-conjoint-fma" v-model="composeData.conjoint.dateNaissance" :class="errors.length > 0 ? 'is-invalid' : ''" :config="options.dateNaissance" class="form-control" placeholder="Date de naissance" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ date de naissance du conjoint est obligatoire </b-form-invalid-feedback>
                <b-form-invalid-feedback :state="errorDateNaissanceConjoint ? false : null"> {{ errorDateNaissanceConjoint
                }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Situation familiale -->
          <!-- <b-col md="3">
            <b-form-group label="Situation familiale*" label-for="situation_familiale">
              <validation-provider #default="{ errors }" name="Situation familiale" rules="required">
                <b-form-select v-model="composeData.conjoint.situation_familiale" id="situation_familiale">
                  <b-form-select-option :value="null" disabled>Choisissez</b-form-select-option>
                  <b-form-select-option v-for="(val, value) in situationFamiliale" :key="value" :value="val.value" :state="errors.length > 0 ? false : null">{{val.name}}</b-form-select-option>
                </b-form-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col> -->

          <!-- Capital conjoint -->
          <!-- <b-col md="3">
            <b-form-group label="Capital conjoint *" label-for="capital conjoint">
              <validation-provider #default="{ errors }" name="Capital conjoint" rules="required">
                <b-form-select v-model="composeData.objet.capital_conjoint" :options="capitalOptions" :state="errors.length > 0 ? false : null" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col> -->
        </b-row>
      </b-form>
    </validation-observer>
    <!-- Buttons -->
    <b-row class="mt-4">
      <b-col lg="12">
        <b-button class="float-left" size="lg" variant="primary" @click="backStep"> Précédent </b-button>
        <b-button class="float-right" size="lg" variant="primary" @click="getTarification()"> Suivant </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton, VBPopover, BCol, BForm, BFormGroup, BFormInvalidFeedback, BFormRadioGroup, BFormSelect, BOverlay, BRow, VBTooltip, BFormSelectOption
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import Datarisquebesoin from '@/shared/constants/risquebesoin'
import { mapGetters } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BForm,
    BOverlay,
    BFormGroup,
    BFormInvalidFeedback,
    BFormRadioGroup,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    BFormSelectOption
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return this.initState()
  },
  computed: {
    ...mapGetters(['getQWCheckedRisque'])
  },
  watch: {
    'composeData.objet.isConjoint': {
      immediate: true,
      handler(val) {
        if (val) {
          this.composeData.conjoint.dateNaissance = null
        }
      }
    },
    getQWCheckedRisque: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getProduits(val)
        }
      }
    }
  },
  mounted() {
    this.initDateEffet()
  },
  methods: {
    hasConjointBySituationFamiliale() {
      const situationFamilialeList = ['CONCUBIN', 'MARIE', 'PACSE']
      if (situationFamilialeList.includes(this.composeData.assurePrincipal.situation_familiale)) {
        return true
      }
      this.composeData.objet.isConjoint = 0
      return false
    },
    initState() {
      return {
        infoDateEffet: "<b>ECA</b> : la date d'effet doit être au 1er du mois et incluse dans un quadrimestre suivant la date du jour<br>",
        produit: {
          produit_id: '',
          risque_id: '',
          produit_nom: '',
          nom_assureur: '',
          nom_com_assureur: ''
        },
        show_agricole: false,
        situationFamiliale: Datarisquebesoin.SITUATIONFAMILIALE,
        capitalOptions: [
          {
            value: null,
            text: 'Choisissez',
            disabled: true,
          },
          {
            value: '20',
            text: '20',
          },
          {
            value: '40',
            text: '40',
          },
          {
            value: '60',
            text: '60',
          },
          {
            value: '80',
            text: '80',
          },
          {
            value: '100',
            text: '100',
          },
        ],
        composeData: {
          assurePrincipal: {
            situation_familiale: null,
            dateNaissance: null,
          },
          objet: {
            capital_assure: 60,
            capital_conjoint: 60,
            dateEffet: null,
            isConjoint: 0,
          },
          conjoint: {
            // situation_familiale: null,
            type_lien_fam: 'CONJOINT',
            dateNaissance: null,
          },
        },
        showLoading: false,
        errorDateNaissanceProspect: null,
        errorDateNaissanceConjoint: null,

        // Configuration

        options: {
          codePostal: {
            blocks: [5],
          },
          codePostalNaiss: {
            lowercase: true,
          },
          booleanValue: [
            {
              value: 1,
              text: 'Oui',
            },
            {
              value: 0,
              text: 'Non',
            },
          ],
          agricoleOptions: [
            {
              value: 'SALARIE_AGRICOLE',
              text: 'Salarié agricole',
            },
            {
              value: 'EXPLOITANTS_AGRICOLES',
              text: 'Exploitant agricole',
            },
          ],
          dateNaissance: {
            dateFormat: 'd/m/Y',
            maxDate: new Date(),
            allowInput: true,
            onKeyDown: (selectedDates, dateStr, instance, event) => {
              if (event.keyCode != 8) {
                const ele = document.activeElement
                let val = ele.value
                if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                  val += '/'
                }
                ele.value = val
                ele.dispatchEvent(new Event('input'))
              }
            },
          },
          dateEffet: {
            dateFormat: 'd/m/Y',
            disable: [
              function (date) {
                const today = new Date()
                today.setDate(today.getDate())
                today.setHours(0, 0, 0, 0)
                return date < today
              },
              function (date) {
                const dateMaxSL = new Date()
                const dateMaxECA = new Date()
                dateMaxSL.setMonth(dateMaxSL.getMonth() + 9)
                dateMaxECA.setFullYear(dateMaxECA.getFullYear() + 1, 0, 1);
                dateMaxSL.setHours(0, 0, 0, 0)
                dateMaxECA.setHours(0, 0, 0, 0)
                const datePlusMonth = dateMaxSL <= dateMaxECA ? dateMaxSL : dateMaxSL
                return datePlusMonth < date
              },
            ],
            allowInput: true,
            onKeyDown: (selectedDates, dateStr, instance, event) => {
              if (event.keyCode != 8) {
                let ele = document.activeElement
                let val = ele.value

                if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                  val += '/'
                }
                ele.value = val
                ele.dispatchEvent(new Event('input'))
              }
            },
          },
          config: {
            dateFormat: 'd/m/Y',
            allowInput: true,
            onKeyDown: (selectedDates, dateStr, instance, event) => {
              if (event.keyCode != 8) {
                let ele = document.activeElement;
                let val = ele.value;

                if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                  val += '/';
                }
                ele.value = val;
                ele.dispatchEvent(new Event('input'));
              }
            },
          },
        },
      }
    },
    // **********************************************
    // Functions that communicate with the backend
    // **********************************************
    async getTarification() {
      this.showLoading = true

      // First validation fields
      const isValidFields = await this.$refs.AssureRules.validate()
      if (!isValidFields) { this.showLoading = false; return 0 }

      const isValidDateEffet = await this.validationDateEffet()
      if (!isValidDateEffet) { this.showLoading = false; return 0 }

      const isValidDateNaissance = await this.validationDateNaissance()
      if (!isValidDateNaissance) { this.showLoading = false; return 0 }
      try {
        this.showLoading = true

        await this.$http.post('quickwins/getQwTarifIJH', this.composeData).then(async response => {

          await this.$store.commit('setQWObjet', { objet: this.composeData.objet })
          await this.$store.commit('setQWProspect', { prospect: this.composeData.assurePrincipal })
          await this.$store.commit('setQWConjoint', { conjoint: this.composeData.conjoint })
          await this.$store.commit('setQWItemsRisque', { itemsRisque: response.data })
          await this.$store.commit('setQWStepTarificateur', { step: { indexStep: 2, active: 'comparatif' } })
        }).catch(() => {
          this.$store.commit('setQWItemsRisque', { itemsRisque: [] })
          this.showLoading = false
        })
        this.showLoading = false
      } catch (e) {
        this.showLoading = false
        this.errors = e.response.data.errors
      }
    },
    getTarifs(dataTarifs, dataProduits) {
      // switch case for get products by assureur 
      let assureur = Object.keys(dataTarifs)[0];
      switch (assureur) {
        case 'ECA_ASSURANCES':
          // that's the point, ECA has 2 products so we get each one
          // eslint-disable-next-line no-case-declarations
          const dpECASR = dataProduits.find(produit => 'ASSUROSPI' in produit) ? dataProduits.find(produit => 'ASSUROSPI' in produit).ASSUROSPI : {}
          // 
          let prgnECASR = dpECASR.hasOwnProperty('produitGaranties') ? dpECASR.produitGaranties.find(obj => obj.produit_id == 30).produitgarantiesniveaux : {}
          //
          let filterdDataTarifsSR = dataTarifs['ECA_ASSURANCES'];
          
          let tarifSR = filterdDataTarifsSR.length > 0 ? this.getTarifFront(filterdDataTarifsSR, dpECASR, prgnECASR) : [];

          let tarifFinal = [tarifCS, tarifSR]
          let dpFinal = [dpECASR]
          return {
            tarif: tarifFinal,
            dp: dpFinal
          }
        default:
          return []
      }
    },
    getTarifFront(dt, dp, prgn) {
      let tarif = []
      if(dp.hasOwnProperty('produitGaranties')) {
        tarif = dt.map(obj => {
          const newArray = dp.produitGaranties.map(item => {
            return ({
            ...item,
            tarif: obj.optionsCompatibles[item.libelle]?.tarif,
            isShow: item.produitgarantiesniveaux.length > 0,
            isSelectedProduitGarantieNiveauID: item.produitgarantiesniveaux.find(pgn => pgn.libelle.toLowerCase() === 'non')?.id,
            produitName: dp.produit_nom
          })}).filter(item => item.tarif) // filter out undefined tarif values
          const sortedArray = newArray.sort((a, b) => a.order - b.order);
          const matchingNiveau = prgn.find(niv => niv.libelle === obj.formule)
          return {
            ...obj,
            tauxDeCommission: 0,
            tarifsBase: { ...obj.tarifs },
            optionCompatiblesWithGaranties: sortedArray || null,
            niveauGarantie: matchingNiveau || null,
          }
        })
      }
      return tarif
    },
    // ****************************************
    // Additional Functions
    // ****************************************
    backStep() {
      this.$store.commit('setQWStepTarificateur', { step: { indexStep: 0, active: 'choix' } });
      this.$store.commit('setQWCheckedRisque', null);
      this.$store.commit('setQWHasProduitAutoriser', { hasProduitAutoriser: false });
      this.$store.commit('setProduitTarificateur', { produit: {} });
    },

    getProduits(label) {
      this.$http.get(`ecaglobal/getProduitsByRisque/${label}`).then((response)=>{
        let risque = response.data
        if(Object.keys(risque).length != 0 && risque.produits.length > 0) {
          var produit = risque.produits[0]
          this.initProduit(produit)
          this.$store.commit("setProduitTarificateur", {produit: this.produit});
        } else {
          this.$store.commit("setProduitTarificateur", {produit: this.initState().produit});
        }
      }).catch((err)=>{
        
      })
    },
    initProduit(produit) {
      this.produit.produit_id       = produit.id
      this.produit.risque_id        = produit.risque_id
      this.produit.produit_nom      = produit.produit_nom
      this.produit.nom_assureur     = produit.assureur.nom_assureur
      this.produit.nom_com_assureur = produit.assureur.nom_com_assureur
    },
    // ****************************************
    // Validation functions
    // ****************************************
    changeDisabledDate(day) {
      this.options.dateEffet.disable = [
        function (date) {
          const today = new Date()
          today.setDate(today.getDate() + day)
          today.setHours(0, 0, 0, 0)
          return date < today
        },
        function (date) {
          const dateMaxSL = new Date()
          const dateMaxECA = new Date()
          dateMaxSL.setMonth(dateMaxSL.getMonth() + 9)
          dateMaxECA.setFullYear(dateMaxECA.getFullYear() + 1, 0, 1);
          dateMaxSL.setHours(0, 0, 0, 0)
          dateMaxECA.setHours(0, 0, 0, 0)
          const datePlusMonth = dateMaxSL <= dateMaxECA ? dateMaxSL : dateMaxECA
          return datePlusMonth < date
        },
      ]
    },
    validationDateEffet() {
      const dateDuJour = moment().format('YYYY-MM-DD')
      const effetDate = moment(this.composeData.objet.dateEffet, 'DD/MM/YYYY').format('YYYY-MM-DD')
      const dateDuJourPlus9Mois = moment().add(9, 'months').format('YYYY-MM-DD')
      this.changeDisabledDate(0)
      return moment(effetDate).isBetween(dateDuJour, dateDuJourPlus9Mois, null, '[]')
    },
    validationDateNaissance() {
      return true
    },
    initDateEffet() {
      const today = moment()
      const DayOfMonth = today.clone().startOf('day').format('DD')
      if (DayOfMonth >= 15 && DayOfMonth <= 31) {
        today.set('month', today.month() + 1)
        today.startOf('month').startOf('day')
      } else {
        today.startOf('day')
      }

      this.composeData.objet.dateEffet = moment(today).format('DD/MM/YYYY')
    },
  },
}
</script>

<style lang="scss"></style>

<style lang="scss" scoped>
.title-custom-wizard {
  color: #4d25bc;
  margin-bottom: 20px;
}

label {
  font-weight: 900;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  text-transform: none;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  text-transform: none;
}

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  text-transform: none;
}

::placeholder {
  /* Recent browsers */
  text-transform: none;
}</style>
