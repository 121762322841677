<template>
  <div style="margin-top: 30px">
    <b-overlay :show="showLoading" no-wrap />

    <validation-observer ref="AssureRules">
      <b-form>
        <!--Assuré principal-->
        <b-row>
          <b-col cols="12">
            <b-row>
              <!-- Date d'effet -->
              <b-col md="3">
                <span v-b-popover.hover.top.html="infoDateEffet" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  title="Information" class="" variant="outline-primary">

                  <b-form-group label="Date d'effet *" label-for="date-effet">
                    <validation-provider #default="{ errors }" name="date d'effet" rules="required">
                      <flat-pickr id="date-effet-fma" v-model="composeData.dateEffet"
                        :class="errors.length > 0 ? 'is-invalid' : ''" :config="options.dateEffet" class="form-control"
                        placeholder="Date d'effet" rules="required" autocomplete="off" />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </span>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="12">
            <h4 class="title-custom-wizard"><u>Assuré principal (signataire)</u></h4>
          </b-col>

          <!-- Date de naissance -->
          <b-col md="3">
            <b-form-group label="Date de naissance *" label-for="date-naissance">
              <validation-provider #default="{ errors }" name="date de naissance" rules="required">
                <flat-pickr id="date-naissance-assure-principal-fma" v-model="composeData.assurePrincipal.dateNaissance"
                  :class="errors.length > 0 ? 'is-invalid' : ''" :config="options.dateNaissance" class="form-control"
                  placeholder="Date de naissance" autocomplete="off" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }}
                </b-form-invalid-feedback>
                <b-form-invalid-feedback :state="errorDateNaissanceProspect ? false : null"> {{ errorDateNaissanceProspect
                }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Régime de sécurité sociale -->
          <b-col md="3">
            <b-form-group label="Régime de sécurité sociale *" label-for="regime">
              <validation-provider #default="{ errors }" name="régime de sécurité sociale" rules="required">
                <b-form-select v-model="composeData.assurePrincipal.regime" @change="changeRegime()"
                  :options="options.regime" :state="errors.length > 0 ? false : null" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Régime AMEXA -->
          <b-col md="3" v-if="show_agricole">
            <b-form-group label="Régime AMEXA" label-for="regime">
              <validation-provider v-slot="{ errors }" name="regime amexa" rules="required">
                <b-form-radio-group v-model="composeData.amexa" :options="options.agricoleOptions"></b-form-radio-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>


          <!-- Code postal -->
          <b-col md="3">
            <b-form-group label="Code postal *" label-for="code postal">
              <validation-provider #default="{ errors }" name="code postal" rules="required|length:5">
                <cleave v-model="composeData.assurePrincipal.codePostal" :class="errors.length > 0 ? 'is-invalid' : ''"
                  :options="options.codePostal" :raw="false" class="form-control" placeholder="Code postal"
                  type="number" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col md="12" v-if="!composeData.devis.loi_madelin">
            <b-alert-regime-non-salaries :regimeSelected="composeData.assurePrincipal.regime"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <b-form-group label="Nombre d'enfants à assurer *" label-for="enfant">
              <b-form-input id="enfant" type="number" @keyup="validateNbrEnfant" v-mask="'##'"
                v-model="composeData.enfantCharge" placeholder="Nombre d'enfants" />
              <b-form-invalid-feedback :state="!$v.composeData.enfantCharge.$error">
                <span v-if="!$v.composeData.enfantCharge.required">Veuillez renseigner le(s) champ(s)
                  obligatoire(s)</span>
                <span v-if="!$v.composeData.enfantCharge.between" class="error">Veuillez renseigner une valeur entre 0 et
                  10</span>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Conjoint à assurer ?">
              <validation-provider v-slot="{ errors }" name="Conjoint à assurer" rules="required">
                <b-form-radio-group v-model="composeData.isConjoint" :options="options.booleanValue"
                  label="Conjoint à assurer"></b-form-radio-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col v-if="composeData.assurePrincipal.regime == 'RÉGIME_SOCIAL'">
            <b-form-group label="Le client souhaite-t-il bénéficier de la loi Madelin ?">
              <validation-provider v-slot="{ errors }" name="madelinValue" rules="required">
                <b-form-radio-group v-model="composeData.devis.loi_madelin" :options="options.booleanValue"
                  label="madelinValue"></b-form-radio-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>

            </b-form-group>
          </b-col>

        </b-row>

        <!--Conjoint-->
        <b-row v-if="composeData.isConjoint == 1" class="mb-1">
          <b-col md="12" sm="12">
            <h4 class="title-custom-wizard mt-2"><u>Conjoint</u></h4>
          </b-col>
          <!-- Date de naissance -->
          <b-col md="3">
            <b-form-group label="Date de naissance *" label-for="conjoint-date-naissance">
              <validation-provider #default="{ errors }" name="date de naissance du conjoint"
                :rules="composeData.isConjoint == 1 ? 'required' : ''">
                <flat-pickr id="date-naissance-assure-conjoint-fma" v-model="composeData.conjoint.dateNaissance"
                  :class="errors.length > 0 ? 'is-invalid' : ''" :config="options.dateNaissance" class="form-control"
                  placeholder="Date de naissance" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ date de naissance du conjoint
                  est obligatoire </b-form-invalid-feedback>
                <b-form-invalid-feedback :state="errorDateNaissanceConjoint ? false : null"> {{ errorDateNaissanceConjoint
                }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Régime -->
          <b-col md="5">
            <b-form-group label="Régime de sécurité sociale *" label-for="conjoint-regime">
              <validation-provider #default="{ errors }" name="régime de sécurité sociale *"
                :rules="composeData.isConjoint == 1 ? 'required' : ''">
                <b-form-select v-model="composeData.conjoint.regime" :options="options.regime"
                  :state="errors.length > 0 ? false : null" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="composeData.isConjoint == 1">
          <b-col md="12" v-if="!composeData.devis.loi_madelin">
            <b-alert-regime-non-salaries :regimeSelected="composeData.conjoint.regime"/>
          </b-col>
        </b-row>
        <!-- Enfants -->
        <b-row v-if="composeData.enfantCharge > 0">
          <b-col md="12">
            <b-row v-for="(enf, index) in composeData.enfantsArray" :key="index">
              <b-col md="12">
                <h4 class="title-custom-wizard mt-2"><u>{{ `Enfant ${index + 1}` }}</u></h4>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <label for="date_naissance">Date de naissance :</label>
                  <flat-pickr class="form-control" autocomplete="off" v-model="enf.date_naissance" id="date-naissance"
                    :config="options.config" placeholder="Date de naissance" data-max-date="today" />
                  <b-form-invalid-feedback :state="!$v.composeData.enfantsArray.$each[index].date_naissance.$error">
                    Veuillez renseigner le(s) champ(s) obligatoire(s)
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <label for="regime_enf">Régime de sécurité sociale *</label>
                  <b-form-select v-model="enf.regime_obligatoire_enf" id="regime_enf" :options="options.regime" />
                  <b-form-invalid-feedback
                    :state="!$v.composeData.enfantsArray.$each[index].regime_obligatoire_enf.$error">
                    Veuillez renseigner le(s) champ(s) obligatoire(s)
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col md="12" v-if="!composeData.devis.loi_madelin">
                <b-alert-regime-non-salaries :regimeSelected="enf.regime_obligatoire_enf"/>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!-- Buttons -->
    <b-row class="mt-4">
      <b-col lg="12">
        <b-button class="float-left" size="lg" variant="primary" @click="backStep"> Précédent </b-button>
        <b-button class="float-right" size="lg" variant="primary" @click="getTarification()"> Suivant </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton, VBPopover, BCol, BForm, BFormGroup, BFormInvalidFeedback, BFormRadioGroup, BFormSelect, BOverlay, BRow, VBTooltip, BFormInput
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BAlertRegimeNonSalaries from '@core/components/b-alert/BAlertRegimeNonSalaries.vue'
import Cleave from 'vue-cleave-component'
import moment from 'moment'
import { required, between } from 'vuelidate/lib/validators'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BForm,
    BOverlay,
    BFormGroup,
    BFormInvalidFeedback,
    BFormRadioGroup,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    ToastificationContent,
    Cleave,
    BFormInput,
    BAlertRegimeNonSalaries
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      infoDateEffet:"<b>ECA</b> : A partir du 16 du mois, la date d’effet minimum doit être le 1er jour du mois suivant<br><br><b>SPVIE</b> : La date d’effet minimum doit être la date du jour+1",
      show_agricole: false,
      composeData: {
        dateEffet: null,
        enfantCharge: 0,
        assurePrincipal: {
          regime: null,
          dateNaissance: null,
          codePostal: null,
        },
        isConjoint: 0,
        enfantsArray: [],
        conjoint: {
          regime: null,
          dateNaissance: null,
        },
        devis : {
          loi_madelin :0,
        }
      },
      showLoading: false,
      errorDateNaissanceProspect: null,
      errorDateNaissanceConjoint: null,

      // Configuration

      options: {
        codePostal: {
          blocks: [5],
        },
        codePostalNaiss: {
          lowercase: true,
        },
        regime: [
          {
            value: null,
            text: 'Choisissez',
            disabled: true,
          },
          {
            value: 'REGIME_GENERAL',
            text: 'Régime général de la sécurité sociale',
          },
          {
            value: 'RÉGIME_SOCIAL',
            text: 'Régime des travailleurs non salariés et non agricoles (ex RSI)',
          },
          {
            value: 'REGIME_AGRICOLE',
            text: 'Mutualité sociale agricole (AMEXA)',
          },
          {
            value: 'REGIME_ALSACE_MOSELLE',
            text: 'Régime Alsace-Moselle',
          }
        ],
        booleanValue: [
          {
            value: 1,
            text: 'Oui',
          },
          {
            value: 0,
            text: 'Non',
          },
        ],
        agricoleOptions: [
          {
            value: 'SALARIE_AGRICOLE',
            text: 'Salarié agricole',
          },
          {
            value: 'EXPLOITANTS_AGRICOLES',
            text: 'Exploitant agricole',
          },
        ],
        dateNaissance: {
          dateFormat: 'd/m/Y',
          maxDate: new Date(),
          allowInput: true,
          onKeyDown: (selectedDates, dateStr, instance, event) => {
            if (event.keyCode != 8) {
              const ele = document.activeElement
              let val = ele.value
              if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                val += '/'
              }
              ele.value = val
              ele.dispatchEvent(new Event('input'))
            }
          },
        },
        dateEffet: {
          dateFormat: 'd/m/Y',
          disable: [
            function (date) {
              const today = new Date()
              today.setDate(today.getDate())
              today.setHours(0, 0, 0, 0)
              return date < today
            },
            function (date) {
              const dateMaxSL = new Date()
              const dateMaxECA = new Date()
              dateMaxSL.setMonth(dateMaxSL.getMonth() + 12)
              dateMaxECA.setFullYear(dateMaxECA.getFullYear() + 1, 0, 1);
              dateMaxSL.setHours(0, 0, 0, 0)
              dateMaxECA.setHours(0, 0, 0, 0)
              const datePlusMonth = dateMaxSL <= dateMaxECA ? dateMaxSL : dateMaxSL
              return datePlusMonth < date
            },
          ],
          allowInput: true,
          onKeyDown: (selectedDates, dateStr, instance, event) => {
            if (event.keyCode != 8) {
              var ele = document.activeElement
              var val = ele.value

              if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                val += '/'
              }
              ele.value = val
              ele.dispatchEvent(new Event('input'))
            }
          },
        },
        config: {
          dateFormat: "d/m/Y",
          allowInput: true,
          onKeyDown: (selectedDates, dateStr, instance, event) => {
            if (event.keyCode != 8) {
              var ele = document.activeElement;
              var val = ele.value;

              if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                val += "/";
              }
              ele.value = val;
              ele.dispatchEvent(new Event("input"));
            }
          },
        },
      },
    }
  },
  validations: {
    composeData: {
      enfantCharge: { required, between: between(0, 10) },
      enfantsArray: {
        $each: {
          date_naissance: {
            required
          },
          poursuit_etude: {
            required
          },
          regime_obligatoire_enf: {
            required
          }
        }
      }
    }
  },
  computed: {
    enfantsHasValidDateNaissance: function () {
      // let enfantNovalidDate = _.filter(this.composeData.enfantsArray, enf => enf.date_naissance && moment().diff(enf.date_naissance.split("/").reverse().join("-"), 'years', true) > 27);
      // return this.composeData.enfantCharge == 0 || enfantNovalidDate.length == 0 ? true : false
      return true;
    }
  },
  watch: {
    'composeData.enfantCharge': {
      handler: function (val, old) {
        if (val && val !== old && val <= 10)
          this.addEnfants(val)
      },
      deep: true,
    },
    'composeData.enfantsArray': {
      handler(val) {
        val = this.setPoursuitEtudeEnfantBySelectedRegime(val)
      }, deep: true
    },
  },
  mounted() {
    this.initDateEffet()
  },
  methods: {
    // **********************************************
    // Functions that communicate with the backend
    // **********************************************

    async getTarification() {
      this.showLoading = true
      this.$v.$reset();
      this.$v.composeData.$touch();

      if (this.$v.composeData.$error) {
        this.showLoading = false
        return 0;
      }
      if (!this.enfantsHasValidDateNaissance) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vous ne pouvez pas sélectionner d\'enfants de 27 ans et plus, à la date du jour',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
        this.showLoading = false
        return 0
      }

      // First validation fields
      const isValidFields = await this.$refs.AssureRules.validate()
      if (!isValidFields) { this.showLoading = false; return 0 }

      const isValidDateEffet = await this.validationDateEffet()
      if (!isValidDateEffet) { this.showLoading = false; return 0 }

      const isValidDateNaissance = await this.validationDateNaissance()
      if (!isValidDateNaissance) { this.showLoading = false; return 0 }

      // Send request to get tarification
      this.$http.post('portefeuilles/calculTarifSanteSenior', this.composeData)
        .then(res => {
          if (res.data.success) {
            if (res.data.data.dataTarifs.length > 0) {
              const { dataTarifs } = res.data.data
              const { dataProduit } = res.data.data
              let newData = [];
              for (let i = 0; i < dataTarifs.length; i++) {
                let tarif = this.getTarifs(dataTarifs[i], dataProduit)?.tarif
                let dp = this.getTarifs(dataTarifs[i], dataProduit)?.dp
                console.log('result tarif & data produit: ', tarif, dp)
                if(dp && tarif){
                  // in case of multiple products for one insurance the dp will be array!!
                  if (dp.length > 1) {
                    for (let index = 0; index < dp.length; index++) {
                      if(dp[index].hasOwnProperty('produitGaranties')) {
                        newData.push({
                          produit: dp[index],
                          tarif: tarif[index]
                        })
                      }

                    }
                  } else {
                    let mapTarif = {
                      produit: dp,
                      tarif: tarif
                    }
                    newData.push(mapTarif)
                  }
                }
              }
              // remove keys from dataproduit, we use them just for grouping tarif
              const modifiedDataProduit = dataProduit.map(item => {
                const outerKey = Object.keys(item)[0]; // Get the first (and only) key
                const innerObj = item[outerKey];
                return {
                  ...innerObj
                };
              });

              // Set in store
              this.$store.commit('setProduitTarificateur', modifiedDataProduit)
              this.$store.commit('setComposeDataTarif', this.composeData)
              this.$store.commit('setTarifsData', newData)
              this.$store.commit('setQWStepTarificateur', { step: { indexStep: 2, active: 'comparatif' } })
            } else {
              this.$store.commit('setTarifsData', [])
              this.$store.commit('setComposeDataTarif', null)
            }
            this.showLoading = false
          }
        })
        .catch(err => {
          this.$store.commit('setTarifsData', [])
          this.$store.commit('setComposeDataTarif', null)
          console.error(err)
          this.showLoading = false
        })
    },
    getTarifs(dataTarifs, dataProduits) {
      // switch case for get products by assureur 
      let assureur = Object.keys(dataTarifs)[0];
      switch (assureur) {
        case 'SL':
          let dpSL = dataProduits.find(produit => 'SL_Sante_Retraites' in produit)['SL_Sante_Retraites'];
          let prgnResultSL = dpSL.produitGaranties.find(obj => obj.produit_id === 16).produitgarantiesniveaux
          return {
            tarif: this.getTarifFront(dataTarifs['SL'], dpSL, prgnResultSL),
            dp: dpSL
          }
        case 'ECA_ASSURANCES':
          // that's the point, ECA has 2 products so we get each one
          let dpECACS = dataProduits.find(produit => 'CAPITAL_SENIOR' in produit) ? dataProduits.find(produit => 'CAPITAL_SENIOR' in produit)['CAPITAL_SENIOR'] : {};
          let dpECASR = dataProduits.find(produit => 'SERENISSIA' in produit) ? dataProduits.find(produit => 'SERENISSIA' in produit)['SERENISSIA'] : {};
          // 
          let prgnECACS = dpECACS.hasOwnProperty('produitGaranties') ? dpECACS.produitGaranties.find(obj => obj.produit_id == 19).produitgarantiesniveaux : {}
          let prgnECASR = dpECASR.hasOwnProperty('produitGaranties') ? dpECASR.produitGaranties.find(obj => obj.produit_id == 23).produitgarantiesniveaux : {}
          // 
          let filterdDataTarifsCS = dataTarifs['ECA_ASSURANCES'].filter(dt => !dt.formule.includes('ES_'));
          let filterdDataTarifsSR = dataTarifs['ECA_ASSURANCES'].filter(dt => dt.formule.includes('ES_'));
          
          let tarifCS = filterdDataTarifsCS.length > 0 ? this.getTarifFront(filterdDataTarifsCS, dpECACS, prgnECACS) : [];
          let tarifSR = filterdDataTarifsSR.length > 0 ? this.getTarifFront(filterdDataTarifsSR, dpECASR, prgnECASR) : [];

          let tarifFinal = [tarifCS, tarifSR]
          let dpFinal = [dpECACS, dpECASR]
          return {
            tarif: tarifFinal,
            dp: dpFinal
          }
        case 'SPVIE':

          let dpSPVIE =  dataProduits.find(produit => 'MALIN' in produit) ? dataProduits.find(produit => 'MALIN' in produit)['MALIN'] : {};
          let prgnResultSPV = dpSPVIE.hasOwnProperty('produitGaranties') ? dpSPVIE.produitGaranties.find(obj => obj.produit_id === 27).produitgarantiesniveaux : {}

          let dpSPVIESM = dataProduits.find(produit => 'SMART' in produit) ? dataProduits.find(produit => 'SMART' in produit)['SMART'] : {};
          let prgnResultSPVsm = dpSPVIESM.hasOwnProperty('produitGaranties') ? dpSPVIESM.produitGaranties.find(obj => obj.produit_id === 26).produitgarantiesniveaux : {}
          let dpSanteSPVIE = dataProduits.find(produit => 'SP_VIE' in produit) ? dataProduits.find(produit => 'SP_VIE' in produit)['SP_VIE'] : {};
          let prgnResultSanteSPV = dpSanteSPVIE.hasOwnProperty('produitGaranties') ? dpSanteSPVIE.produitGaranties.find(obj => obj.produit_id === 20).produitgarantiesniveaux : {}

          let filterdDataTarifsML = dataTarifs['SPVIE'].filter(dt => dt.formule.includes('For'));
          let filterdDataTarifsSM = dataTarifs['SPVIE'].filter(dt => !dt.formule.includes('For') && !dt.formule.includes('Niv'));
          let filterdDataTarifsSP = dataTarifs['SPVIE'].filter(dt => dt.formule.includes('Niv'));

          let tarifML = filterdDataTarifsML.length > 0 ? this.getTarifFront(filterdDataTarifsML,dpSPVIE,prgnResultSPV) : []
          let tarifSM = filterdDataTarifsSM.length > 0 ? this.getTarifFront(filterdDataTarifsSM,dpSPVIESM,prgnResultSPVsm) : []
          let tarifSP = filterdDataTarifsSP.length > 0 ? this.getTarifFront(filterdDataTarifsSP,dpSanteSPVIE,prgnResultSanteSPV) : []

          let tarifFinalspvie = [tarifML, tarifSM,tarifSP]
          let dpFinalspvie = [dpSPVIE, dpSPVIESM,dpSanteSPVIE]



          return {
            tarif: tarifFinalspvie,
            dp: dpFinalspvie
          }
        default:
          return []
      }
    },
    getTarifFront(dt, dp, prgn) {
      let tarif = []
      if(dp.hasOwnProperty('produitGaranties')) {
        tarif = dt.map(obj => {
          const newArray = dp.produitGaranties.map(item => {
            return ({
            ...item,
            tarif: obj.optionsCompatibles[item.libelle]?.tarif,
            isShow: item.produitgarantiesniveaux.length > 0,
            isSelectedProduitGarantieNiveauID: item.produitgarantiesniveaux.find(pgn => pgn.libelle.toLowerCase() === 'non')?.id,
            produitName: dp.produit_nom
          })}).filter(item => item.tarif) // filter out undefined tarif values
          const sortedArray = newArray.sort((a, b) => a.order - b.order);
          const matchingNiveau = prgn.find(niv => niv.libelle === obj.formule)
          return {
            ...obj,
            tauxDeCommission: 0,
            tarifsBase: { ...obj.tarifs },
            optionCompatiblesWithGaranties: sortedArray || null,
            niveauGarantie: matchingNiveau || null,
          }
        })
      }
      return tarif
    },
    // ****************************************
    // Additional Functions
    // ****************************************
    validateNbrEnfant() {
      if (this.composeData.enfantCharge > 10 || this.composeData.enfantCharge < 0) {
        return this.composeData.enfantCharge = 0
      }
    },
    backStep() {
      this.$store.commit('setQWStepTarificateur', { step: { indexStep: 0, active: 'choix' } });
      this.$store.commit("setQWCheckedRisque", null);
      this.$store.commit("setQWHasProduitAutoriser", { hasProduitAutoriser: false });
      this.$store.commit("setProduitTarificateur", { produit: {} });
    },
    addEnfants(nbrEnfant) {
      this.composeData.enfantsArray = []
      for (let index = 0; index < nbrEnfant; index++) {
        this.composeData.enfantsArray.push({
          date_naissance: null,
          poursuit_etude: 0,
          type_lien_fam: 'ENFANT',
          regime_obligatoire_enf: null
        })
      }
    },

    // ****************************************
    // Validation functions
    // ****************************************
    changeDisabledDate(day) {
      this.options.dateEffet.disable = [
        function (date) {
          const today = new Date()
          today.setDate(today.getDate() + day)
          today.setHours(0, 0, 0, 0)
          return date < today
        },
        function (date) {
          const dateMaxSL = new Date()
          const dateMaxECA = new Date()
          dateMaxSL.setMonth(dateMaxSL.getMonth() + 12)
          dateMaxECA.setFullYear(dateMaxECA.getFullYear() + 1, 0, 1);
          dateMaxSL.setHours(0, 0, 0, 0)
          dateMaxECA.setHours(0, 0, 0, 0)
          const datePlusMonth = dateMaxSL <= dateMaxECA ? dateMaxSL : dateMaxSL
          return datePlusMonth < date
        },
      ]
    },
    changeRegime() {
      if (this.composeData.assurePrincipal.regime == "REGIME_AGRICOLE") {
        this.show_agricole = true
      }
      else {
        this.show_agricole = false
      }
      if(this.composeData.assurePrincipal.regime != "RÉGIME_SOCIAL"){
        this.composeData.devis.loi_madelin = 0
      }
    },
    validationDateEffet() {
      const dateDuJour = moment().format('YYYY-MM-DD')
      const effetDate = moment(this.composeData.dateEffet, 'DD/MM/YYYY').format('YYYY-MM-DD')
      const dateDuJourPlus9Mois = moment().add(12, 'months').format('YYYY-MM-DD')
      this.changeDisabledDate(0)
      return moment(effetDate).isBetween(dateDuJour, dateDuJourPlus9Mois, null, '[]')
    },
    validationDateNaissance() {
      // Declaration
      // const endDate = moment(this.composeData.dateEffet, 'DD/MM/YYYY').subtract(55, 'years').format('YYYY-MM-DD')
      // const endDateForConjoint = moment(this.composeData.dateEffet, 'DD/MM/YYYY').subtract(40, 'years').format('YYYY-MM-DD')
      // const startDate = moment(this.composeData.dateEffet, 'DD/MM/YYYY').subtract(96, 'years').add(1, 'days').format('YYYY-MM-DD')

      // const dateNaissanceAssurePrincipal = moment(this.composeData.assurePrincipal.dateNaissance, 'DD/MM/YYYY').format('YYYY-MM-DD')
      // if (moment(dateNaissanceAssurePrincipal).isBetween(startDate, endDate, null, '[]')) {
      //   this.errorDateNaissanceProspect = null
      // } else {
      //   this.errorDateNaissanceProspect = "L'âge de l’assuré principal doit être compris entre 55 et 95 ans"
      //   return false
      // }

      // if (this.composeData.isConjoint == 1) {
      //   const dateNaissanceConjoint = moment(this.composeData.conjoint.dateNaissance, 'DD/MM/YYYY').format('YYYY-MM-DD')
      //   if (moment(dateNaissanceConjoint).isBetween(startDate, endDateForConjoint, null, '[]')) {
      //     this.errorDateNaissanceConjoint = null
      //   } else {
      //     this.errorDateNaissanceConjoint = "L'âge du conjoint doit être compris entre 40 et 95 ans"
      //     return false
      //   }
      // } else {
      //   this.errorDateNaissanceConjoint = null
      // }
      return true
    },
    initDateEffet() {
      const today = moment()
      const DayOfMonth = today.clone().startOf('day').format('DD')
      if (DayOfMonth >= 15 && DayOfMonth <= 31) {
        today.set('month', today.month() + 1)
        today.startOf('month').startOf('day')
      } else {
        today.startOf('day')
      }

      this.composeData.dateEffet = moment(today).format('DD/MM/YYYY') 
    },
    setPoursuitEtudeEnfantBySelectedRegime(data) {
      data.forEach((enf) => {
        if (enf.regime_obligatoire_enf == "REGIME_GENERAL" || (enf.regime_obligatoire_enf && this.composeData.assurePrincipal.regime && enf.regime_obligatoire_enf == this.composeData.assurePrincipal.regime)) {
          enf.poursuit_etude = 1;
        } else {
          enf.poursuit_etude = 0;
        }
      })
      return data
    },
  },
}
</script>

<style lang="scss"></style>

<style lang="scss" scoped>
.title-custom-wizard {
  color: #4d25bc;
  margin-bottom: 20px;
}

label {
  font-weight: 900;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  text-transform: none;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  text-transform: none;
}

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  text-transform: none;
}

::placeholder {
  /* Recent browsers */
  text-transform: none;
}</style>
