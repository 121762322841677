<template>
    <div style="margin-top: 30px">
      <b-overlay :show="showLoading" no-wrap />
  
      <validation-observer ref="AssureRules">
        <b-form>
          <!--Assuré principal-->
          <b-row>
            <b-col cols="12">
              <b-row>
                <!-- Date d'effet -->
                <b-col md="3">
                  <span v-b-popover.hover.top.html="infoDateEffet" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    title="Information" class="" variant="outline-primary">
  
                    <b-form-group label="Date d'effet *" label-for="date-effet">
                      <validation-provider #default="{ errors }" name="date d'effet" rules="required">
                        <flat-pickr id="date-effet-fma" v-model="composeData.objet.date_effet"
                          :class="errors.length > 0 ? 'is-invalid' : ''" :config="options.date_effet" class="form-control"
                          placeholder="Date d'effet" rules="required" autocomplete="off" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </span>
                </b-col>
              </b-row>
            </b-col>
            <!-- Assuré principal -->
            <b-col md="12">
              <h4 class="title-custom-wizard"><u>Assuré principal (signataire)</u></h4>
            </b-col>
  
            <!-- Date de naissance -->
            <b-col md="3">
              <b-form-group label="Date de naissance *" label-for="date-naissance">
                <validation-provider #default="{ errors }" name="date de naissance" rules="required">
                  <flat-pickr id="date-naissance-assure-principal-fma" v-model="composeData.assurePrincipal.date_naissance"
                    :class="errors.length > 0 ? 'is-invalid' : ''" :config="options.date_naissance" class="form-control"
                    placeholder="Date de naissance" autocomplete="off" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }}
                  </b-form-invalid-feedback>
                  <b-form-invalid-feedback :state="errorDateNaissanceProspect ? false : null"> {{ errorDateNaissanceProspect
                  }} </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
  
            <!-- Régime de sécurité sociale -->
            <!-- <b-col md="3">
              <b-form-group label="Régime de sécurité sociale *" label-for="regime">
                <validation-provider #default="{ errors }" name="régime de sécurité sociale" rules="required">
                  <b-form-select v-model="composeData.assurePrincipal.regime" @change="changeRegime()"
                    :options="options.regime" :state="errors.length > 0 ? false : null" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col> -->

            <b-col md="3">
              <b-form-group label="Situation familiale *" label-for="situationFamiliale">
                <validation-provider #default="{ errors }" name="Situation familiale" rules="required">
                  <b-form-select
                    v-model="composeData.assurePrincipal.situation_familiale"
                    :options="options.situationFamiliale"
                    :state="errors.length > 0 ? false : null "
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
  
            <!-- Régime AMEXA -->
            <!-- <b-col md="3" v-if="show_agricole">
              <b-form-group label="Régime AMEXA" label-for="regime">
                <validation-provider v-slot="{ errors }" name="regime amexa" rules="required">
                  <b-form-radio-group v-model="composeData.amexa" :options="options.agricoleOptions"></b-form-radio-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col> -->
  
  
            <!-- Code postal -->
            <!-- <b-col md="3">
              <b-form-group label="Code postal *" label-for="code postal">
                <validation-provider #default="{ errors }" name="code postal" rules="required|length:5">
                  <cleave v-model="composeData.assurePrincipal.code_postal" :class="errors.length > 0 ? 'is-invalid' : ''"
                    :options="options.code_postal" :raw="false" class="form-control" placeholder="Code postal"
                    type="number" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col> -->

             <!-- <b-col md="3">
              <b-form-group label="capital Assure *" label-for="regime">
                <validation-provider #default="{ errors }" name="capital Assure" rules="required">
                  <b-form-select v-model="composeData.objet.capital_assure"
                    :options="options.capitalAssure" :state="errors.length > 0 ? false : null" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>  -->

            
          </b-row>
          <b-row class="my-1">
            <b-col md="12">
              <b-alert-regime-non-salaries :regimeSelected="composeData.assurePrincipal.regime" />
            </b-col>
          </b-row>
          <b-row>
<!--             <b-col md="3">
              <b-form-group label="Nombre d'enfants à assurer *" label-for="enfant">
                <b-form-input id="enfant" type="number" @keyup="validateNbrEnfant" v-mask="'##'"
                  v-model="composeData.enfantCharge" placeholder="Nombre d'enfants" />
                <b-form-invalid-feedback :state="!$v.composeData.enfantCharge.$error">
                  <span v-if="!$v.composeData.enfantCharge.required">Veuillez renseigner le(s) champ(s)
                    obligatoire(s)</span>
                  <span v-if="!$v.composeData.enfantCharge.between" class="error">Veuillez renseigner une valeur entre 0 et
                    10</span>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col> -->

            <b-col md="3" v-if="checkSituationFamilial()">
            <b-form-group label="Souhaitez-vous assurer votre conjoint(e) ? *">
              <validation-provider v-slot="{ errors }" name="Conjoint à assurer" rules="required">
                <b-form-radio-group v-model="composeData.objet.isConjoint" :options="options.booleanValue"
                  label="Conjoint à assurer"></b-form-radio-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          </b-row>
  
          <!--Conjoint-->
        <b-row v-if="composeData.objet.isConjoint && checkSituationFamilial()" class="mb-1">
          <b-col md="12" sm="12">
            <h4 class="title-custom-wizard mt-2"><u>Conjoint</u></h4>
          </b-col>
          <!-- Date de naissance -->
          <b-col md="3">
            <b-form-group label="Date de naissance *" label-for="conjoint-date-naissance">
              <validation-provider #default="{ errors }" name="date de naissance du conjoint"
                :rules="checkSituationFamilial() ? 'required' : ''">
                <flat-pickr id="date-naissance-assure-conjoint-fma" v-model="composeData.conjoint.date_naissance"
                  :class="errors.length > 0 ? 'is-invalid' : ''" :config="options.date_naissance" class="form-control"
                  placeholder="Date de naissance" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ date de naissance du conjoint
                  est obligatoire </b-form-invalid-feedback>
                <b-form-invalid-feedback :state="errorDateNaissanceConjoint ? false : null"> {{ errorDateNaissanceConjoint
                }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Régime -->
         <!--  <b-col md="5">
            <b-form-group label="Régime de sécurité sociale *" label-for="conjoint-regime">
              <validation-provider #default="{ errors }" name="régime de sécurité sociale *"
                :rules="composeData.objet.isConjoint == 1 ? 'required' : ''">
                <b-form-select v-model="composeData.conjoint.regime" :options="options.regime"
                  :state="errors.length > 0 ? false : null" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col> -->
        </b-row>
        <b-row v-if="composeData.objet.isConjoint == 1">
          <b-col md="12">
            <b-alert-regime-non-salaries :regimeSelected="composeData.conjoint.regime" />
          </b-col>
        </b-row>
        <!-- Enfants -->
        <!-- <b-row v-if="composeData.enfantCharge > 0">
          <b-col md="12">
            <b-row v-for="(enf, index) in composeData.enfantsArray" :key="index">
              <b-col md="12">
                <h4 class="title-custom-wizard mt-2"><u>{{ `Enfant ${index + 1}` }}</u></h4>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <label for="date_naissance">Date de naissance :</label>
                  <flat-pickr class="form-control" autocomplete="off" v-model="enf.date_naissance" id="date-naissance"
                    :config="options.config" placeholder="Date de naissance" data-max-date="today" />
                  <b-form-invalid-feedback :state="!$v.composeData.enfantsArray.$each[index].date_naissance.$error">
                    Veuillez renseigner le(s) champ(s) obligatoire(s)
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <label for="regime_enf">Régime de sécurité sociale *</label>
                  <b-form-select v-model="enf.regime_obligatoire_enf" id="regime_enf" :options="options.regime" />
                  <b-form-invalid-feedback
                    :state="!$v.composeData.enfantsArray.$each[index].regime_obligatoire_enf.$error">
                    Veuillez renseigner le(s) champ(s) obligatoire(s)
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-alert-regime-non-salaries :regimeSelected="enf.regime_obligatoire_enf" />
              </b-col>
            </b-row>
          </b-col>
        </b-row> -->
        </b-form>
      </validation-observer>
      <!-- Buttons -->
      <b-row class="mt-4">
        <b-col lg="12">
          <b-button class="float-left" size="lg" variant="primary" @click="backStep"> Précédent </b-button>
          <b-button class="float-right" size="lg" variant="primary" @click="getTarification()"> Suivant </b-button>
        </b-col>
      </b-row>
    </div>
  </template>
  
  <script>
  import {
    BButton, VBPopover, BCol, BForm, BFormGroup, BFormInvalidFeedback, BFormRadioGroup, BFormSelect, BOverlay, BRow, VBTooltip, BFormInput
  } from 'bootstrap-vue'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import flatPickr from 'vue-flatpickr-component'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import BAlertRegimeNonSalaries from '@core/components/b-alert/BAlertRegimeNonSalaries.vue'
  import Cleave from 'vue-cleave-component'
  import moment from 'moment'
  import { required, between } from 'vuelidate/lib/validators'
  import Ripple from 'vue-ripple-directive'
  import { mapGetters } from 'vuex'
  
  export default {
    components: {
      BRow,
      BCol,
      BButton,
      BForm,
      BOverlay,
      BFormGroup,
      BFormInvalidFeedback,
      BFormRadioGroup,
      BFormSelect,
      ValidationProvider,
      ValidationObserver,
      flatPickr,
      ToastificationContent,
      Cleave,
      BFormInput,
      BAlertRegimeNonSalaries
    },
    directives: {
      'b-tooltip': VBTooltip,
      'b-popover': VBPopover,
      Ripple,
    },
    data() {
      return {
        infoDateEffet:"<b>ECA</b> : la date d'effet doit être au 1er du mois et incluse dans un quadrimestre suivant la date du jour",
        show_agricole: false,
        composeData: {
          objet:{
            date_effet: null,
            capital_assure : null,
            isConjoint: 0,
          },
          /* enfantCharge: 0, */
          assurePrincipal: {
            regime: null,
            date_naissance: null,
            code_postal: null,
            situation_familiale : null,

          },
          /* enfantsArray: [], */
          conjoint: {
            regime: null,
            date_naissance: null,
            type_lien_fam: 'CONJOINT'
          },
        },
        produit: {
          produit_id: '',
          risque_id: '',
          produit_nom: '',
          nom_assureur: '',
          nom_com_assureur: ''
        },

        showLoading: false,
        errorDateNaissanceProspect: null,
        errorDateNaissanceConjoint: null,
  
        // Configuration
  
        options: {
          code_postal: {
            blocks: [5],
          },
          codePostalNaiss: {
            lowercase: true,
          },
          regime: [
            {
              value: null,
              text: 'Choisissez',
              disabled: true,
            },
            {
              value: 'REGIME_GENERAL',
              text: 'Régime général de la sécurité sociale',
            },
            {
              value: 'RÉGIME_SOCIAL',
              text: 'Régime des travailleurs non salariés et non agricoles (ex RSI)',
            },
            {
              value: 'REGIME_AGRICOLE',
              text: 'Mutualité sociale agricole (AMEXA)',
            },
            {
              value: 'REGIME_ALSACE_MOSELLE',
              text: 'Régime Alsace-Moselle',
            }
          ],
          situationFamiliale: [
            {
              value: null,
              text: 'Choisissez',
              disabled: true,
            },
            {
              value: 'CONCUBIN',
              text: 'Concubin(e)',
            },
            {
              value: 'CELIBATAIRE',
              text: 'Célibataire',
            },
            {
              value: 'DIVORCE',
              text: 'Divorcé(e)',
            },
            {
              value: 'MARIE',
              text: 'Marié(e)',
            },
            {
              value: 'SEPARE',
              text: 'Séparé(e)',
            },
            {
              value: 'VEUF',
              text: 'Veuf(ve)',
            },
            {
              value: 'PACSE',
              text: 'Pacsé(e)',
            }
          ],
          capitalAssure : [
            {
              value: null,
              text: 'Choisissez',
              disabled: true,
            },
            {
              value : 10000,
              text : '10000'
            },
            {
              value : 20000,
              text : '20000'
            },
            {
              value : 50000,
              text : '50000'
            },
            {
              value : 70000,
              text : '70000'
            },{
              value : 70000,
              text : '10000'
            },{
              value : 110000,
              text : '110000'
            },{
              value : 120000,
              text : '120000'
            }
            ,{
              value : 130000,
              text : '130000'
            }
            ,{
              value : 140000,
              text : '140000'
            }
            ,{
              value : 150000,
              text : '150000'
            }
          ],
          booleanValue: [
            {
              value: 1,
              text: 'Oui',
            },
            {
              value: 0,
              text: 'Non',
            },
          ],
          agricoleOptions: [
            {
              value: 'SALARIE_AGRICOLE',
              text: 'Salarié agricole',
            },
            {
              value: 'EXPLOITANTS_AGRICOLES',
              text: 'Exploitant agricole',
            },
          ],
          date_naissance: {
            dateFormat: 'd/m/Y',
            maxDate: new Date(),
            allowInput: true,
            onKeyDown: (selectedDates, dateStr, instance, event) => {
              if (event.keyCode != 8) {
                const ele = document.activeElement
                let val = ele.value
                if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                  val += '/'
                }
                ele.value = val
                ele.dispatchEvent(new Event('input'))
              }
            },
          },
          date_effet: {
            dateFormat: 'd/m/Y',
            disable: [
              function (date) {
                const today = new Date()
                today.setDate(today.getDate())
                today.setHours(0, 0, 0, 0)
                return date < today
              },
              function (date) {
                const dateMaxSL = new Date()
                const dateMaxECA = new Date()
                dateMaxSL.setMonth(dateMaxSL.getMonth() + 9)
                dateMaxECA.setFullYear(dateMaxECA.getFullYear() + 1, 0, 1);
                dateMaxSL.setHours(0, 0, 0, 0)
                dateMaxECA.setHours(0, 0, 0, 0)
                const datePlusMonth = dateMaxSL <= dateMaxECA ? dateMaxSL : dateMaxSL
                return datePlusMonth < date
              },
            ],
            allowInput: true,
            onKeyDown: (selectedDates, dateStr, instance, event) => {
              if (event.keyCode != 8) {
                var ele = document.activeElement
                var val = ele.value
  
                if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                  val += '/'
                }
                ele.value = val
                ele.dispatchEvent(new Event('input'))
              }
            },
          },
          config: {
            dateFormat: "d/m/Y",
            allowInput: true,
            onKeyDown: (selectedDates, dateStr, instance, event) => {
              if (event.keyCode != 8) {
                var ele = document.activeElement;
                var val = ele.value;
  
                if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                  val += "/";
                }
                ele.value = val;
                ele.dispatchEvent(new Event("input"));
              }
            },
          },
        },
      }
    },
    validations: {
      composeData: {
        /* enfantCharge: { required, between: between(0, 10) },
        enfantsArray: {
          $each: {
            date_naissance: {
              required
            },
            poursuit_etude: {
              required
            },
            regime_obligatoire_enf: {
              required
            }
          }
        } */
      }
    },
    computed: {
      ...mapGetters(["getQWCheckedRisque"]),
      /* enfantsHasValidDateNaissance: function () {
        // let enfantNovalidDate = _.filter(this.composeData.enfantsArray, enf => enf.date_naissance && moment().diff(enf.date_naissance.split("/").reverse().join("-"), 'years', true) > 27);
        // return this.composeData.enfantCharge == 0 || enfantNovalidDate.length == 0 ? true : false
        return true;
      } */
    },
    watch: {
      getQWCheckedRisque: {
        immediate: true,
        handler(val) {
          if(val) {
            console.log('tartze',val)
            this.getProduits(val)
          }
        }
      }, 
      
      /* 'composeData.enfantCharge': {
        handler: function (val, old) {
          if (val && val !== old && val <= 10)
            this.addEnfants(val)
        },
        deep: true,
      }, */
      /* 'composeData.enfantsArray': {
        handler(val) {
          val = this.setPoursuitEtudeEnfantBySelectedRegime(val)
        }, deep: true
      }, */
    },
    mounted() {
      this.initDateEffet()
    },
    methods: {
      // **********************************************
      // Functions that communicate with the backend
      // **********************************************
      getProduits(label) {
        this.$http.get(`ecaglobal/getProduitsByRisque/${label}`).then((response)=>{
          let risque = response.data
          if(Object.keys(risque).length != 0 && risque.produits.length > 0) {
            var produit = risque.produits[0]
            this.initProduit(produit)
            this.$store.commit("setProduitTarificateur", {produit: this.produit});
          } else {
            this.$store.commit("setProduitTarificateur", {produit: this.initState().produit});
          }
        }).catch((err)=>{
          
        })
      },
      checkSituationFamilial() {
        const specialSituations = ['CONCUBIN', 'MARIE', 'PACSE'];
        if(specialSituations.includes(this.composeData.assurePrincipal.situation_familiale))
        {
          return true
        }
        this.composeData.objet.isConjoint = 0
        return false;
      },
      initProduit(produit) {
        this.produit.produit_id       = produit.id
        this.produit.risque_id        = produit.risque_id
        this.produit.produit_nom      = produit.produit_nom
        this.produit.nom_assureur     = produit.assureur.nom_assureur
        this.produit.nom_com_assureur = produit.assureur.nom_com_assureur
      },
      async getTarification() {
        this.showLoading = true
        this.$v.$reset();
        this.$v.composeData.$touch();
  
        if (this.$v.composeData.$error) {
          this.showLoading = false
          return 0;
        }
  
        // First validation fields
        const isValidFields = await this.$refs.AssureRules.validate()
        if (!isValidFields) { this.showLoading = false; return 0 }
  
        const isValidDateEffet = await this.validationDateEffet()
        if (!isValidDateEffet) { this.showLoading = false; return 0 }
  

        // Send request to get tarification
        await this.$http.post('quickwins/calculTarifAccidentDeces', this.composeData)
          .then(async (res) => {
            console.log('planette',res.data.data)
          await this.$store.commit("setQWObjet", {objet: this.composeData.objet});
          await this.$store.commit("setQWProspect", {prospect: this.composeData.assurePrincipal});
          await this.$store.commit("setQWConjoint", {conjoint: this.composeData.conjoint});
          await this.$store.commit("setQWItemsRisque", {itemsRisque: res.data.data});
          await this.$store.commit("setQWStepTarificateur", {step: {indexStep: 2, active: 'comparatif'}});

          }).catch(err => {
            this.$store.commit("setQWItemsRisque", { itemsRisque: []});
            this.showLoading = false
          })
          this.showLoading = false
      },
      
      // ****************************************
      // Additional Functions
      // ****************************************
      /* validateNbrEnfant() {
        if (this.composeData.enfantCharge > 10 || this.composeData.enfantCharge < 0) {
          return this.composeData.enfantCharge = 0
        }
      }, */
      backStep() {
        this.$store.commit('setQWStepTarificateur', { step: { indexStep: 0, active: 'choix' } });
        this.$store.commit("setQWCheckedRisque", null);
        this.$store.commit("setQWHasProduitAutoriser", { hasProduitAutoriser: false });
        this.$store.commit("setProduitTarificateur", { produit: {} });
      },
      /* addEnfants(nbrEnfant) {
        this.composeData.enfantsArray = []
        for (let index = 0; index < nbrEnfant; index++) {
          this.composeData.enfantsArray.push({
            date_naissance: null,
            poursuit_etude: 0,
            type_lien_fam: 'ENFANT',
            regime_obligatoire_enf: null
          })
        }
      }, */
  
      // ****************************************
      // Validation functions
      // ****************************************
      changeDisabledDate(day) {
        this.options.date_effet.disable = [
          function (date) {
            const today = new Date()
            today.setDate(today.getDate() + day)
            today.setHours(0, 0, 0, 0)
            return date < today
          },
          function (date) {
            const dateMaxSL = new Date()
            const dateMaxECA = new Date()
            dateMaxSL.setMonth(dateMaxSL.getMonth() + 9)
            dateMaxECA.setFullYear(dateMaxECA.getFullYear() + 1, 0, 1);
            dateMaxSL.setHours(0, 0, 0, 0)
            dateMaxECA.setHours(0, 0, 0, 0)
            const datePlusMonth = dateMaxSL <= dateMaxECA ? dateMaxSL : dateMaxECA
            return datePlusMonth < date
          },
        ]
      },
      changeRegime() {
        if (this.composeData.assurePrincipal.regime == "REGIME_AGRICOLE") {
          this.show_agricole = true
        }
        else {
          this.show_agricole = false
        }
      },
      validationDateEffet() {
        const dateDuJour = moment().format('YYYY-MM-DD')
        const effetDate = moment(this.composeData.date_effet, 'DD/MM/YYYY').format('YYYY-MM-DD')
        const dateDuJourPlus9Mois = moment().add(9, 'months').format('YYYY-MM-DD')
        this.changeDisabledDate(0)
        return moment(effetDate).isBetween(dateDuJour, dateDuJourPlus9Mois, null, '[]')
      },
      validationDateNaissance() {
        // Declaration
        // const endDate = moment(this.composeData.dateEffet, 'DD/MM/YYYY').subtract(55, 'years').format('YYYY-MM-DD')
        // const endDateForConjoint = moment(this.composeData.dateEffet, 'DD/MM/YYYY').subtract(40, 'years').format('YYYY-MM-DD')
        // const startDate = moment(this.composeData.dateEffet, 'DD/MM/YYYY').subtract(96, 'years').add(1, 'days').format('YYYY-MM-DD')
  
        // const dateNaissanceAssurePrincipal = moment(this.composeData.assurePrincipal.dateNaissance, 'DD/MM/YYYY').format('YYYY-MM-DD')
        // if (moment(dateNaissanceAssurePrincipal).isBetween(startDate, endDate, null, '[]')) {
        //   this.errorDateNaissanceProspect = null
        // } else {
        //   this.errorDateNaissanceProspect = "L'âge de l’assuré principal doit être compris entre 55 et 95 ans"
        //   return false
        // }
  
        // if (this.composeData.isConjoint == 1) {
        //   const dateNaissanceConjoint = moment(this.composeData.conjoint.dateNaissance, 'DD/MM/YYYY').format('YYYY-MM-DD')
        //   if (moment(dateNaissanceConjoint).isBetween(startDate, endDateForConjoint, null, '[]')) {
        //     this.errorDateNaissanceConjoint = null
        //   } else {
        //     this.errorDateNaissanceConjoint = "L'âge du conjoint doit être compris entre 40 et 95 ans"
        //     return false
        //   }
        // } else {
        //   this.errorDateNaissanceConjoint = null
        // }
        return true
      },
      initDateEffet() {
        const today = moment()
        const DayOfMonth = today.clone().startOf('day').format('DD')
        if (DayOfMonth >= 15 && DayOfMonth <= 31) {
          today.set('month', today.month() + 1)
          today.startOf('month').startOf('day')
        } else {
          today.startOf('day')
        }
  
        this.composeData.date_effet = moment(today).format('DD/MM/YYYY')
      },
      setPoursuitEtudeEnfantBySelectedRegime(data) {
        data.forEach((enf) => {
          if (enf.regime_obligatoire_enf == "REGIME_GENERAL" || (enf.regime_obligatoire_enf && this.composeData.assurePrincipal.regime && enf.regime_obligatoire_enf == this.composeData.assurePrincipal.regime)) {
            enf.poursuit_etude = 1;
          } else {
            enf.poursuit_etude = 0;
          }
        })
        return data
      },
    },
  }
  </script>
  
  <style lang="scss"></style>
  
  <style lang="scss" scoped>
  .title-custom-wizard {
    color: #4d25bc;
    margin-bottom: 20px;
  }
  
  label {
    font-weight: 900;
  }
  
  ::-webkit-input-placeholder {
    /* WebKit browsers */
    text-transform: none;
  }
  
  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    text-transform: none;
  }
  
  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    text-transform: none;
  }
  
  :-ms-input-placeholder {
    /* Internet Explorer 10+ */
    text-transform: none;
  }
  
  ::placeholder {
    /* Recent browsers */
    text-transform: none;
  }</style>
  