<template>
  <div id="lg-popover" style="witdh:100%; margin-top:30px">
    <b-overlay :show="showLoading" no-wrap />


    <div v-for="option in BesoinClientOptions" :key="option.value" :value="option.value" style="witdh:100%;">
      <b-popover container="lg-popover" :target="option.value" placement="buttom" triggers="hover">

        <template #title>
          {{ option.infoBull.title }}

        </template>

        <div class="text-justify" v-html="option.infoBull.description"></div>
      </b-popover>
    </div>
    <div v-if="objetEmprunteur.has_pret_secondaire">
      <div v-for="option in BesoinClientOptions" :key="option.value" :value="option.value" style="witdh:100%;">
        <b-popover container="lg-popover" :target="option.value + '1'" placement="buttom" triggers="hover">

          <template #title>
            {{ option.infoBull.title }}

          </template>

          <div class="text-justify" v-html="option.infoBull.description"></div>
        </b-popover>
      </div>
    </div>
    <div v-if="objetEmprunteur.emprunteur.has_co_emprunteur">
      <div v-for="option in BesoinClientOptions" :key="option.value" :value="option.value" style="witdh:100%;">
        <b-popover container="lg-popover" :target="option.value + '2'" placement="buttom" triggers="hover">

          <template #title>
            {{ option.infoBull.title }}

          </template>

          <div class="text-justify" v-html="option.infoBull.description"></div>
        </b-popover>
      </div>
      <div v-if="objetEmprunteur.has_pret_secondaire">
        <div v-for="option in BesoinClientOptions" :key="option.value" :value="option.value" style="witdh:100%;">
          <b-popover container="lg-popover" :target="option.value + '3'" placement="buttom" triggers="hover">

            <template #title>
              {{ option.infoBull.title }}

            </template>

            <div class="text-justify" v-html="option.infoBull.description"></div>
          </b-popover>
        </div>
      </div>
    </div>



    <b-popover container="body" target="my_popover_1" placement="buttom" triggers="hover">
      <template #title>
        Kilomètres professionnel par an
      </template>
      Réalisé avec un véhicule terrestre à moteur et hors domicile/travail

    </b-popover>



    <validation-observer ref="AssureRules">
      <b-form>
        <app-collapse id="collapse-besoin" type="margin">
          <b-row>
            <b-col md="12">
              <b-alert v-show="errorsTarif.length > 0" variant="danger" show class="mt-1">
                <div class="alert-body">
                  <ul class="mb-0">
                    <li v-for="(error, index) in errorsTarif" :key="`erreur-${index}`">
                      {{ error.message }}
                    </li>
                  </ul>
                </div>
              </b-alert>
            </b-col>
          </b-row>

          <!-- Assuré principal (emprunteur) -->
          <app-collapse-item ref="tab-collapse-item-0" :is-visible="true"
            title="<h4 class='title-custom-wizard'><u>Assuré principal</u></h4>">
            <b-row>
              <b-col md="12">
                <b-row>
                  <b-col md="4">
                    <b-form-group>
                      <label class="mb-1">Type d'assuré *</label>
                      <validation-provider #default="{ errors }" name="type d'assuré" rules="required">
                        <b-form-radio-group v-model="objetEmprunteur.emprunteur.type_assure" name="radio-type-assure"
                          :state="errors.length > 0 ? false : null" class="demo-inline-spacing">
                          <template v-for="option in TypeAssure">
                            <b-form-radio v-if="option.value != 'CO_EMPRUNTEUR'" :key="option.value" :value="option.value"
                              class="mt-0">
                              <small>{{ option.text }}</small>
                            </b-form-radio>
                          </template>
                        </b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le choix est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="3">
                    <b-form-group label="Civilité *" label-for="assure_civilite">
                      <validation-provider #default="{ errors }" name="civilité" rules="required">
                        <b-form-select id="assure_civilite" v-model="assure.civilite" :options="civilite"
                          :state="errors.length > 0 ? false : null" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le champ est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>



                  <b-col md="3">
                    <b-form-group label="Date de naissance *" label-for="date-naissance">
                      <div
                        v-if="assure.statut_gda === 'VERT' || assure.statut_gda === 'ROUGE' || assure.statut_gda === 'ORANGE'"
                        class="form-control" readonly disabled>
                        {{ assure.date_naissance }}
                      </div>
                      <validation-provider v-else #default="{ errors }" name="date de naissance" rules="required">
                        <flat-pickr id="date-naissance" v-model="assure.date_naissance"
                          :class="errors.length > 0 ? 'is-invalid' : ''" :config="configDate" class="form-control"
                          placeholder="Date de naissance" autocomplete="off"
                          @input="initPrecision(assure.statut, 'emprunteur', assure.date_naissance)" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le champ est obligatoire
                        </b-form-invalid-feedback>
                        <b-form-invalid-feedback :state="errorDateNaissanceProspect ? false : null">
                          {{ errorDateNaissanceProspect }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Catégorie socioprofessionnelle" label-for="categorie_socioprofessionnelle">
                      <validation-provider v-slot="{ errors }" name="Catégorie socioprofessionnelle" rules="required"
                        vid="categorie_socioprofessionnelle">
                        <b-form-select id="categorie_socioprofessionnelle" v-model="assure.statut"
                          :options="ActiviteProfessionnelle"
                          @change="initPrecision(assure.statut, 'emprunteur', assure.date_naissance)" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le champ est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3"
                    v-if="assure.statut == 'SANS_EMPLOI' || assure.statut == 'PROFESSION_LIBERALE' || assure.statut == 'FONCTIONNAIRE_ASSIMILE'">
                    <b-form-group label="Merci de préciser*" label-for="precision-profession">
                      <validation-provider v-slot="{ errors }" name="Merci de préciser Assure" rules="required"
                        vid="precision-profession">
                        <b-form-select v-model="objetEmprunteur.emprunteur.precision_profession"
                          :options="TypePrecisionOptions[assure.statut]" id="precision-profession"
                          :state="errors.length > 0 ? false : null" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> Le champ est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Profession" label-for="profession">
                      <b-form-input id="profession" v-model="objetEmprunteur.emprunteur.profession"
                        class="text-capitalize" placeholder="Exemple : Boulanger" type="text" />
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Code postal *" label-for="code-postal">
                      <validation-provider #default="{ errors }" name="code postal" rules="required|length:5">
                        <cleave id="code-postal" v-model="assure.moyen_contact.code_postal"
                          :class="errors.length > 0 ? 'is-invalid' : ''" :options="options.codePostal" :raw="false"
                          class="form-control" placeholder="Code postal" type="tel" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le champ est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Ville *" label-for="ville">
                      <validation-provider #default="{ errors }" name="ville" rules="required">
                        <b-form-select v-model="assure.moyen_contact.ville_id" :state="errors.length > 0 ? false : null"
                          :class="errors.length > 0 ? 'is-invalid' : ''">
                          <b-form-select-option :value="null">
                            Choisissez
                          </b-form-select-option>
                          <b-form-select-option v-for="ville in villeOptions" :key="ville.id" :value="ville.id">
                            {{ ville.ville }}
                          </b-form-select-option>
                        </b-form-select>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le champ est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group class="mb-0">
                      <label for="fumeur" class="mb-1">
                        Fumeur ?
                        <span v-b-popover.hover.top="'Ne fumant pas ou ayant arrêté de fumer (Cigarette ou Vapotage électronique) depuis plus de 24 mois.'"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'" title="Non fumeur" class="ml-1 cursor-pointer"
                          variant="outline-primary">
                          <feather-icon icon="InfoIcon" size="20" />
                        </span>
                      </label>
                      <validation-provider #default="{ errors }" name="fumeur" rules="required">
                        <b-form-radio-group v-model="objetEmprunteur.emprunteur.fumeur" name="radio-fumeur"
                          :state="errors.length > 0 ? false : null">
                          <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                            {{ option.text }}
                          </b-form-radio>
                        </b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le choix est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group class="mb-0">
                      <label for="contrat-temps-plein" class="mb-1">
                        Activité professionnelle à temps plein au vu de la durée habituelle prévue au contrat de travail ?
                      </label>
                      <validation-provider #default="{ errors }" name="contrat temps plein" rules="required">
                        <b-form-radio-group v-model="objetEmprunteur.emprunteur.is_contrat_temps_plein"
                          name="radio-contrat-temps-plein" :state="errors.length > 0 ? false : null"
                          @change="objetEmprunteur.emprunteur.en_conge = null">
                          <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                            {{ option.text }}
                          </b-form-radio>
                        </b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le choix est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col v-if="!objetEmprunteur.emprunteur.is_contrat_temps_plein" md="3">
                    <b-form-group class="mb-0">
                      <label for="en-conge" class="mb-1">
                        En congé parental, maternité, paternité, sabbatique ?
                        <span v-b-popover.hover.top="'Congé parental, maternité, paternité, sabbatique ou autre congé.'"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'" title="En congé" class="ml-1 cursor-pointer"
                          variant="outline-primary">
                          <feather-icon icon="InfoIcon" size="20" />
                        </span>
                      </label>
                      <validation-provider #default="{ errors }" name="en congé" rules="required">
                        <b-form-radio-group v-model="objetEmprunteur.emprunteur.en_conge" name="radio-en-congé"
                          :state="errors.length > 0 ? false : null">
                          <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                            {{ option.text }}
                          </b-form-radio>
                        </b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le choix est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group>
                      <label for="deplacement-pro" class="mb-1">
                        Déplacement à titre professionnel ?
                      </label>
                      <span id="my_popover_1" v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="ml-1 cursor-pointer">
                        <feather-icon icon="InfoIcon" size="20" />
                      </span>
                      <validation-provider v-slot="{ errors }" name="deplacement a titre professionnel" rules="required">
                        <b-form-select id="deplacement-pro" v-model="objetEmprunteur.emprunteur.deplacement_pro"
                          :options="DeplacementeProfessionnelOptions" :state="errors.length > 0 ? false : null" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le champ est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group class="mb-0">
                      <label for="activite-manuel-regulier" class="mb-1">
                        Activité avec manutention/travaux manuel régulier ?
                      </label>
                      <validation-provider #default="{ errors }" name="activite manuel regulier" rules="required">
                        <b-form-radio-group v-model="objetEmprunteur.emprunteur.activite_manuel_regulier"
                          name="radio-activite-manuel-regulier" :state="errors.length > 0 ? false : null">
                          <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                            {{ option.text }}
                          </b-form-radio>
                        </b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le choix est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group class="mb-0">
                      <label for="travail-hauteur" class="mb-1">
                        Travail en hauteur ?
                        <span
                            v-b-popover.hover.top="'Activité professionnelle à plus de 15 mètres de hauteur'"
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            title="Travail en hauteur"
                            class="ml-1 cursor-pointer"
                            variant="outline-primary"
                        >
                                      <feather-icon icon="InfoIcon" size="20" />
                                  </span>
                      </label>
                      <validation-provider #default="{ errors }" name="travail en hauteur" rules="required">
                        <b-form-radio-group v-model="objetEmprunteur.emprunteur.travail_hauteur"
                          name="radio-travail-hauteur" :state="errors.length > 0 ? false : null">
                          <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                            {{ option.text }}
                          </b-form-radio>
                        </b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le choix est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="9" class="mt-1">
                    <b-form-group class="mb-0">
                      <label for="en-cours" class="mb-1">
                        En incluant ce projet, l'assuré a-t-il des encours de prêts immobiliers assurés (hors prêt
                        professionnels) supérieurs à 200 000 € ?
                      </label>
                      <validation-provider #default="{ errors }" name="en cours" rules="required">
                        <b-form-radio-group v-model="objetEmprunteur.emprunteur.en_cours" @change="fillFranchiseList('E')"
                          name="radio-en-cours" :state="errors.length > 0 ? false : null">
                          <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                            {{ option.text }}
                          </b-form-radio>
                        </b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le choix est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="9" class="my-2">


                    <b-row>
                      <b-col md="12" class="pt-1">
                        <b-form-group class="mb-0">
                          <label class="float-left pr-4 mb-2">Ajouter un Co-assuré ?</label>
                          <validation-provider v-slot="{ errors }" name="is co-emprunteur" rules="required"
                            vid="is_co_emprunteur">
                            <b-form-radio-group id="is_co_emprunteur_option"
                              v-model="objetEmprunteur.emprunteur.has_co_emprunteur" :options="BooleanValue"
                              @change="toggleIsSouscipteur" />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                              Le choix est obligatoire
                            </b-form-invalid-feedback>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </app-collapse-item>
          <!-- Co-emprunteur -->
          <app-collapse-item v-if="objetEmprunteur.emprunteur.has_co_emprunteur" ref="tab-collapse-item-1"
            :is-visible="true" title="<h4 class='title-custom-wizard'><u>Co-assuré</u></h4>">
            <b-row>
              <b-col md="12">
                <b-row>
                  <b-col md="3">
                    <b-form-group>
                      <label class="mb-1">Type d'assuré *</label>
                      <validation-provider #default="{ errors }" name="type Co-assuré" rules="required">
                        <b-form-radio-group v-model="objetEmprunteur.co_emprunteur.type_assure" name="radio-co-assure"
                          :state="errors.length > 0 ? false : null" class="demo-inline-spacing">
                          <template v-for="option in TypeAssure">
                            <b-form-radio v-if="option.value != 'EMPRUNTEUR'" :key="option.value" :value="option.value"
                              class="mt-0">
                              <small>{{ option.text }}</small>
                            </b-form-radio>
                          </template>
                        </b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le choix est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Quel est le lien du Co-assuré avec le souscripteur ?"
                      label-for="lien du co-emprunteur">
                      <validation-provider #default="{ errors }" name="lien du co-emprunteur" rules="required">
                        <b-form-select v-model="objetEmprunteur.lien" :options="LienOptions"
                          :state="errors.length > 0 ? false : null" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le champ est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col v-if="objetEmprunteur.lien" md="12" class="py-2">
                    <b-row v-if="objetEmprunteur.lien != 'CONJOINT'">
                      <div class="col-12 mb-1">
                        <label>Qui est le Co-assuré ?</label>
                      </div>
                    </b-row>
                    <div v-if="objetEmprunteur.lien == 'ENFANT'">
                      <b-row v-for="(enf, index) in enfantsArray" :key="`enfant-${index}`" class="mb-1">
                        <b-col md="12">
                          <b-row>
                            <b-col md="1" class="d-inline text-center" style="margin-top:30px">
                              <b-form-group class="d-inline">
                                <b-form-radio v-model="enf.state" :value="true" name="enfant" class="d-inline"
                                  @change="toggleConnexite(enf.state, index, objetEmprunteur.lien)" />
                              </b-form-group>
                            </b-col>
                            <b-col md="10">
                              <b-row>
                                <b-col md="3">
                                  <b-form-group label="Civilité *" label-for="enfant_civilite">
                                    <validation-provider #default="{ errors }" :name="`civilité enfant{${index}}`"
                                      :rules="`requiredIfChecked:${enf.state}`">
                                      <b-form-select id="enfant_civilite" v-model="enf.civilite" :options="civilite"
                                        :state="errors.length > 0 ? false : null" />
                                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                        Le champ est obligatoire
                                      </b-form-invalid-feedback>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                                <b-col md="3">
                                  <b-form-group label="Date de naissance *" label-for="date-naissance">
                                    <div
                                      v-if="enf.statut_gda === 'VERT' || enf.statut_gda === 'ROUGE' || enf.statut_gda === 'ORANGE'"
                                      class="form-control" readonly disabled>
                                      {{ enf.date_naissance }}
                                    </div>
                                    <validation-provider v-else #default="{ errors }"
                                      :name="`date de naissance enfant{${index}}`"
                                      :rules="`requiredIfChecked:${enf.state}`">
                                      <flat-pickr id="date-naissance" v-model="enf.date_naissance" class="form-control"
                                        :class="errors.length > 0 ? 'is-invalid' : ''" :config="configDate"
                                        placeholder="Date de naissance" data-max-date="today"
                                        @input="initPrecision(enf.statut, 'co_emprunteur', enf.date_naissance, enf.state)" />
                                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                        Le champ est obligatoire
                                      </b-form-invalid-feedback>

                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                                <b-col md="3">
                                  <b-form-group label="Catégorie socioprofessionnelle *"
                                    :label-for="`activite_professionnelle_enfant${index}`">
                                    <validation-provider #default="{ errors }"
                                      :name="`activité professionnelle enfant{${index}}`"
                                      :rules="`requiredIfChecked:${enf.state}`">
                                      <b-form-select :id="`activite_professionnelle_enfant{${index}}`"
                                        v-model="enf.statut" :options="ActiviteProfessionnelle"
                                        :state="errors.length > 0 ? false : null"
                                        @change="initPrecision(enf.statut, 'co_emprunteur', enf.date_naissance, enf.state)" />
                                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                        Le champ est obligatoire
                                      </b-form-invalid-feedback>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>


                              </b-row>
                            </b-col>
                            <b-col v-if="!enf.id" md="1" class="text-center" style="margin-top:30px">
                              <div class="d-inline" @click="removeConnexite(index, objetEmprunteur.lien)">
                                <feather-icon icon="TrashIcon" size="22" class="mr-50 cursor-pointer text-danger" />
                              </div>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col md="10" offset="1">
                          <b-row>




                          </b-row>
                        </b-col>
                        <b-col md="10" offset="1">
                          <b-row>
                            <!-- <b-col md="3">
                            <b-form-group>
                            <label for="situation familiale">Situation familiale</label>
                                <validation-provider v-slot="{ errors }" :name="`situation familiale enfant{${index}}`" :rules="`requiredIfChecked:${enf.state}`" vid="enf_situation_familiale">
                                <b-form-select v-model="enf.situation_familiale"  :options="SituationFamilialeOptions" id="situation_familiale" :state="errors.length > 0 ? false : null" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> Le champ est obligatoire</b-form-invalid-feedback>
                                </validation-provider>
                            </b-form-group>
                        </b-col> -->

                            <template v-if="enf.state">
                              <b-col md="3"
                                v-if="enf.statut == 'SANS_EMPLOI' || enf.statut == 'PROFESSION_LIBERALE' || enf.statut == 'FONCTIONNAIRE_ASSIMILE'">
                                <b-form-group label="Merci de préciser"
                                  :label-for="`precision-profession-enfant{${index}}`">
                                  <validation-provider v-slot="{ errors }" :name="`Merci de préciser enfant{${index}}`"
                                    :rules="`requiredIfChecked:${enf.state}`"
                                    :vid="`precision-profession-enfant{${index}}`">
                                    <b-form-select v-model="objetEmprunteur.co_emprunteur.precision_profession"
                                      :options="TypePrecisionOptions[enf.statut]"
                                      :id="`precision-profession-enfant{${index}}`"
                                      :state="errors.length > 0 ? false : null" />
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> Le champ est
                                      obligatoire </b-form-invalid-feedback>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col md="3">
                                <b-form-group label="Profession" :label-for="`profession-co-emprunteur-enfant{${index}}`">
                                  <b-form-input :id="`profession-co-emprunteur-enfant{${index}}`"
                                    v-model="objetEmprunteur.co_emprunteur.profession" class="text-capitalize"
                                    placeholder="Exemple : Boulanger" type="text" />
                                </b-form-group>
                              </b-col>
                              <b-col md="3">
                                <b-form-group label="Code postal *" :label-for="`code-postal-enfant{${index}}`">
                                  <validation-provider #default="{ errors }" :name="`code postal enfant {${index}}`"
                                    :rules="`requiredIfChecked:${enf.state}`">
                                    <cleave :id="`code-postal-enfant{${index}}`" v-model="enf.moyen_contact.code_postal"
                                      :class="errors.length > 0 ? 'is-invalid' : ''" :options="options.codePostal"
                                      :raw="false" class="form-control" placeholder="Code postal" type="tel"
                                      @input="enf.state ? getVilleByCodePostal($event, index, objetEmprunteur.lien) : ''" />
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                      Le champ est obligatoire
                                    </b-form-invalid-feedback>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col md="3">
                                <b-form-group label="Ville *" :label-for="`ville-enfant{${index}}`">
                                  <validation-provider #default="{ errors }" :name="`ville enfant {${index}}`"
                                    :rules="`requiredIfChecked:${enf.state}`">
                                    <b-form-select v-model="enf.moyen_contact.ville_id"
                                      :state="errors.length > 0 ? false : null"
                                      :class="errors.length > 0 ? 'is-invalid' : ''">
                                      <b-form-select-option :value="null">
                                        Choisissez
                                      </b-form-select-option>
                                      <b-form-select-option v-for="ville in villeOptionsCoEmprunteur" :key="ville.id"
                                        :value="ville.id">
                                        {{ ville.ville }}
                                      </b-form-select-option>
                                    </b-form-select>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                      Le champ est obligatoire
                                    </b-form-invalid-feedback>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                            </template>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row class="mt-1 mb-2">
                        <b-col md="11" sm="11" :offset="enfantsArray.length > 0 ? 1 : 0">
                          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="md" variant="outline-primary"
                            @click="addConnexite(objetEmprunteur.lien)">
                            <feather-icon icon="PlusIcon" />
                            <span class="align-middle">Ajouter</span>
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>
                    <div v-if="objetEmprunteur.lien == 'PARENT'">
                      <b-row v-for="(par, index) in parentsArray" :key="`parent-${index}`" class="mb-1">
                        <b-col md="12">
                          <b-row>
                            <b-col md="1" class="d-inline text-center" style="margin-top:30px">
                              <b-form-group class="d-inline">
                                <b-form-radio v-model="par.state" :value="true" name="parent" class="d-inline"
                                  @change="toggleConnexite(par.state, index, objetEmprunteur.lien)" />
                              </b-form-group>
                            </b-col>
                            <b-col md="10">
                              <b-row>
                                <b-col md="3">
                                  <b-form-group label="Civilité *" label-for="parent_civilite">
                                    <validation-provider #default="{ errors }" :name="`civilité parent{${index}}`"
                                      :rules="`requiredIfChecked:${par.state}`">
                                      <b-form-select id="parent_civilite" v-model="par.civilite" :options="civilite"
                                        :state="errors.length > 0 ? false : null" />
                                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est
                                        obligatoire
                                      </b-form-invalid-feedback>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>

                                <b-col md="3">
                                  <b-form-group label="Date de naissance *" label-for="date-naissance">
                                    <div
                                      v-if="par.statut_gda === 'VERT' || par.statut_gda === 'ROUGE' || par.statut_gda === 'ORANGE'"
                                      class="form-control" readonly disabled>
                                      {{ par.date_naissance }}
                                    </div>

                                    <validation-provider v-else #default="{ errors }"
                                      :name="`date de naissance parent{${index}}`"
                                      :rules="`requiredIfChecked:${par.state}`">
                                      <flat-pickr id="date-naissance" v-model="par.date_naissance" class="form-control"
                                        :class="errors.length > 0 ? 'is-invalid' : ''" :config="configDate"
                                        placeholder="Date de naissance" data-max-date="today"
                                        @input="initPrecision(par.statut, 'co_emprunteur', par.date_naissance, par.state)" />
                                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est
                                        obligatoire
                                      </b-form-invalid-feedback>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                                <b-col md="3">
                                  <b-form-group label="Catégorie socioprofessionnelle *"
                                    :label-for="`activite_professionnelle_parent${index}`">
                                    <validation-provider #default="{ errors }"
                                      :name="`activité professionnelle parent{${index}}`"
                                      :rules="`requiredIfChecked:${par.state}`">
                                      <b-form-select :id="`activite_professionnelle_parent{${index}}`"
                                        v-model="par.statut" :options="ActiviteProfessionnelle"
                                        :state="errors.length > 0 ? false : null"
                                        @change="initPrecision(par.statut, 'co_emprunteur', par.date_naissance, par.state)" />
                                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                        Le champ est obligatoire
                                      </b-form-invalid-feedback>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>

                              </b-row>
                            </b-col>
                            <b-col v-if="!par.id" md="1" class="text-center" style="margin-top:30px">
                              <div class="d-inline" @click="removeConnexite(index, objetEmprunteur.lien)">
                                <feather-icon icon="TrashIcon" size="22" class="mr-50 cursor-pointer text-danger" />
                              </div>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col md="10" offset="1">
                          <b-row>



                          </b-row>
                        </b-col>
                        <b-col md="10" offset="1">
                          <b-row>
                            <!-- <b-col md="3">
                        <b-form-group>
                          <label for="situation familiale">Situation familiale</label>
                          <validation-provider #default="{ errors }" :name="`situation familiale parent{${index}}`"
                            :rules="`requiredIfChecked:${par.state}`" vid="par_situation_familiale">
                            <b-form-select v-model="par.situation_familiale" :options="SituationFamilialeOptions" id="situation_familiale"
                              :state="errors.length > 0 ? false : null" />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> Le champ est obligatoire
                            </b-form-invalid-feedback>
                          </validation-provider>
                        </b-form-group>
                      </b-col> -->

                            <template v-if="par.state">
                              <b-col md="3"
                                v-if="par.statut == 'SANS_EMPLOI' || par.statut == 'PROFESSION_LIBERALE' || par.statut == 'FONCTIONNAIRE_ASSIMILE'">
                                <b-form-group label="Merci de préciser"
                                  :label-for="`precision-profession-parent{${index}}`">
                                  <validation-provider v-slot="{ errors }" :name="`Merci de préciser parent{${index}}`"
                                    :rules="`requiredIfChecked:${par.state}`"
                                    :vid="`precision-profession-parent{${index}}`">
                                    <b-form-select v-model="objetEmprunteur.co_emprunteur.precision_profession"
                                      :options="TypePrecisionOptions[par.statut]"
                                      :id="`precision-profession-parent{${index}}`"
                                      :state="errors.length > 0 ? false : null" />
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> Le champ est
                                      obligatoire </b-form-invalid-feedback>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col md="3">
                                <b-form-group label="Profession" :label-for="`profession-co-emprunteur-parent{${index}}`">
                                  <b-form-input :id="`profession-co-emprunteur-parent{${index}}`"
                                    v-model="objetEmprunteur.co_emprunteur.profession" class="text-capitalize"
                                    placeholder="Exemple : Boulanger" type="text" />
                                </b-form-group>
                              </b-col>
                              <b-col md="3">
                                <b-form-group label="Code postal *" :label-for="`code-postal-parent{${index}}`">
                                  <validation-provider #default="{ errors }" :name="`code postal parent {${index}}`"
                                    rules="required|length:5">
                                    <cleave :id="`code-postal-parent{${index}}`" v-model="par.moyen_contact.code_postal"
                                      :class="errors.length > 0 ? 'is-invalid' : ''" :options="options.codePostal"
                                      :raw="false" class="form-control" placeholder="Code postal" type="tel"
                                      @input="par.state ? getVilleByCodePostal($event, index, objetEmprunteur.lien) : ''" />
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                      Le champ est obligatoire
                                    </b-form-invalid-feedback>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col md="3">
                                <b-form-group label="Ville *" :label-for="`ville-parent{${index}}`">
                                  <validation-provider #default="{ errors }" :name="`ville parent {${index}}`"
                                    rules="required">
                                    <b-form-select v-model="par.moyen_contact.ville_id"
                                      :state="errors.length > 0 ? false : null"
                                      :class="errors.length > 0 ? 'is-invalid' : ''">
                                      <b-form-select-option :value="null">
                                        Choisissez
                                      </b-form-select-option>
                                      <b-form-select-option v-for="ville in villeOptionsCoEmprunteur" :key="ville.id"
                                        :value="ville.id">
                                        {{ ville.ville }}
                                      </b-form-select-option>
                                    </b-form-select>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                      Le champ est obligatoire
                                    </b-form-invalid-feedback>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                            </template>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row class="mt-1 mb-2">
                        <b-col md="11" sm="11" :offset="parentsArray.length > 0 ? 1 : 0">
                          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="md" variant="outline-primary"
                            @click="addConnexite(objetEmprunteur.lien)">
                            <feather-icon icon="PlusIcon" />
                            <span class="align-middle">Ajouter</span>
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>
                    <div v-if="objetEmprunteur.lien == 'CONJOINT'">
                      <b-row class="mb-1">
                        <b-col md="12">
                          <b-row>
                            <b-col md="1" class="d-inline text-center" style="margin-top:30px">
                              <b-form-group class="d-inline">
                                <b-form-radio :checked="true" :value="true" class="d-inline" name="conjoint" />
                              </b-form-group>
                            </b-col>
                            <b-col md="10">
                              <b-row>
                                <b-col md="3">
                                  <b-form-group label="Civilité *" label-for="conjoint_civilite">
                                    <validation-provider #default="{ errors }" name="civilité conjoint" rules="required">
                                      <b-form-select id="conjoint_civilite" v-model="conjoint.civilite"
                                        :options="civilite" :state="errors.length > 0 ? false : null" />
                                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                        Le champ est obligatoire
                                      </b-form-invalid-feedback>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                                <b-col md="3">
                                  <b-form-group label="Date de naissance *" label-for="date-naissance">
                                    <div
                                      v-if="conjoint.statut_gda === 'VERT' || conjoint.statut_gda === 'ROUGE' || conjoint.statut_gda === 'ORANGE'"
                                      class="form-control" readonly disabled>
                                      {{ conjoint.date_naissance }}
                                    </div>
                                    <validation-provider v-else #default="{ errors }" name="date naissance conjoint"
                                      rules="required">
                                      <flat-pickr id="date-naissance" v-model="conjoint.date_naissance"
                                        class="form-control" :class="errors.length > 0 ? 'is-invalid' : ''"
                                        :config="configDate" placeholder="Date de naissance" data-max-date="today"
                                        @input="initPrecision(conjoint.statut, 'co_emprunteur', conjoint.date_naissance)" />
                                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                        Le champ est obligatoire
                                      </b-form-invalid-feedback>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                                <b-col md="3">
                                  <b-form-group label="Catégorie socioprofessionnelle *"
                                    label-for="categorie_socioprofessionnelle_conjoint">
                                    <validation-provider #default="{ errors }"
                                      name="catégorie socioprofessionnelle conjoint" rules="required">
                                      <b-form-select id="categorie_socioprofessionnelle_conjoint"
                                        v-model="conjoint.statut" :options="ActiviteProfessionnelle"
                                        :state="errors.length > 0 ? false : null"
                                        @change="initPrecision(conjoint.statut, 'co_emprunteur', conjoint.date_naissance)" />
                                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                        Le champ est obligatoire
                                      </b-form-invalid-feedback>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                                <b-col md="3"
                                  v-if="conjoint.statut == 'SANS_EMPLOI' || conjoint.statut == 'PROFESSION_LIBERALE' || conjoint.statut == 'FONCTIONNAIRE_ASSIMILE'">
                                  <b-form-group label="Merci de préciser"
                                    :label-for="`precision-profession-conjoint{${index}}`">
                                    <validation-provider v-slot="{ errors }" name="Merci de préciser conjoint"
                                      rules="required" vid="precision-profession-conjoint">
                                      <b-form-select v-model="objetEmprunteur.co_emprunteur.precision_profession"
                                        :options="TypePrecisionOptions[conjoint.statut]"
                                        id="precision-profession-conjoint" :state="errors.length > 0 ? false : null" />
                                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> Le champ est
                                        obligatoire </b-form-invalid-feedback>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                                <b-col md="3">
                                  <b-form-group label="Profession"
                                    :label-for="`profession_co_emprunteur_conjoint{${index}}`">
                                    <b-form-input :id="`profession_co_emprunteur_conjoint{${index}}`"
                                      v-model="objetEmprunteur.co_emprunteur.profession" class="text-capitalize"
                                      placeholder="Exemple : Boulanger" type="text" />
                                  </b-form-group>
                                </b-col>
                                <b-col md="3">
                                  <b-form-group label="Code postal *" label-for="code-postal-conjoint">
                                    <validation-provider #default="{ errors }" name="code postal conjoint"
                                      rules="required|length:5">
                                      <cleave id="code-postal-conjoint" v-model="conjoint.moyen_contact.code_postal"
                                        :class="errors.length > 0 ? 'is-invalid' : ''" :options="options.codePostal"
                                        :raw="false" class="form-control" placeholder="Code postal" type="tel" />
                                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                        Le champ est obligatoire
                                      </b-form-invalid-feedback>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                                <b-col md="3">
                                  <b-form-group label="Ville *" label-for="ville-conjoint">
                                    <validation-provider #default="{ errors }" name="ville conjoint" rules="required">
                                      <b-form-select v-model="conjoint.moyen_contact.ville_id"
                                        :state="errors.length > 0 ? false : null"
                                        :class="errors.length > 0 ? 'is-invalid' : ''">
                                        <b-form-select-option :value="null">
                                          Choisissez
                                        </b-form-select-option>
                                        <b-form-select-option v-for="ville in villeOptionsCoEmprunteur" :key="ville.id"
                                          :value="ville.id">
                                          {{ ville.ville }}
                                        </b-form-select-option>
                                      </b-form-select>
                                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                        Le champ est obligatoire
                                      </b-form-invalid-feedback>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col v-if="!conjoint.id" md="1" class="text-center" style="margin-top:30px">
                              <div class="d-inline" @click="removeConnexite(index, objetEmprunteur.lien)">
                                <feather-icon icon="TrashIcon" size="22" class="mr-50 cursor-pointer text-danger" />
                              </div>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col md="10" offset="1">
                          <b-row>




                          </b-row>
                        </b-col>
                        <b-col md="10" offset="1">
                          <b-row>
                            <!-- <b-col md="3">
                          <b-form-group>
                            <label for="situation familiale">Situation familiale </label>
                            <validation-provider v-slot="{ errors }" name="situation familiale conjoint" rules="required" vid="conjoint_situation_familiale">
                              <b-form-select v-model="conjoint.situation_familiale"  :options="SituationFamilialeOptions" id="situation_familiale" :state="errors.length > 0 ? false : null" />
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> Le champ est obligatoire </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col> -->

                          </b-row>
                        </b-col>
                      </b-row>
                    </div>
                    <div v-if="objetEmprunteur.lien == 'AMI'">
                      <b-row v-for="(ami, index) in amisArray" :key="`ami-${index}`" class="mb-1">
                        <b-col md="12">
                          <b-row>
                            <b-col md="1" class="d-inline text-center" style="margin-top:30px">
                              <b-form-group class="d-inline">
                                <b-form-radio v-model="ami.state" :value="true" name="ami" class="d-inline"
                                  @change="toggleConnexite(ami.state, index, objetEmprunteur.lien)" />
                              </b-form-group>
                            </b-col>
                            <b-col md="10">
                              <b-row>
                                <b-col md="3">
                                  <b-form-group label="Civilité *" label-for="ami_civilite">
                                    <validation-provider #default="{ errors }" :name="`civilité ami{${index}}`"
                                      :rules="`requiredIfChecked:${ami.state}`">
                                      <b-form-select id="ami_civilite" v-model="ami.civilite" :options="civilite"
                                        :state="errors.length > 0 ? false : null" />
                                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est
                                        obligatoire
                                      </b-form-invalid-feedback>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                                <b-col md="3">
                                  <b-form-group label="Date de naissance *" label-for="date-naissance">
                                    <div
                                      v-if="ami.statut_gda === 'VERT' || ami.statut_gda === 'ROUGE' || ami.statut_gda === 'ORANGE'"
                                      class="form-control" readonly disabled>
                                      {{ ami.date_naissance }}
                                    </div>

                                    <validation-provider v-else #default="{ errors }"
                                      :name="`date de naissance ami{${index}}`" :rules="`requiredIfChecked:${ami.state}`">
                                      <flat-pickr id="date-naissance" v-model="ami.date_naissance" class="form-control"
                                        :class="errors.length > 0 ? 'is-invalid' : ''" :config="configDate"
                                        placeholder="Date de naissance" data-max-date="today"
                                        @input="initPrecision(ami.statut, 'co_emprunteur', ami.date_naissance, ami.state)" />
                                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est
                                        obligatoire
                                      </b-form-invalid-feedback>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                                <b-col md="3">
                                  <b-form-group label="Catégorie socioprofessionnelle"
                                    :label-for="`activite_professionnelle_ami_{${index}}`">
                                    <validation-provider #default="{ errors }"
                                      :name="`activité professionnelle ami{${index}}`"
                                      :rules="`requiredIfChecked:${ami.state}`">
                                      <b-form-select :id="`activite_professionnelle_ami_{${index}}`" v-model="ami.statut"
                                        :options="ActiviteProfessionnelle" :state="errors.length > 0 ? false : null"
                                        @change="initPrecision(ami.statut, 'co_emprunteur', ami.date_naissance, ami.state)" />
                                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                        Le champ est obligatoire
                                      </b-form-invalid-feedback>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col v-if="!ami.id" md="1" class="text-center" style="margin-top:30px">
                              <div class="d-inline" @click="removeConnexite(index, objetEmprunteur.lien)">
                                <feather-icon icon="TrashIcon" size="22" class="mr-50 cursor-pointer text-danger" />
                              </div>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col md="10" offset="1">
                          <b-row>




                          </b-row>
                        </b-col>
                        <b-col md="10" offset="1">
                          <b-row>
                            <!-- <b-col md="3">
                          <b-form-group>
                            <label for="situation familiale">Situation familiale</label>
                            <validation-provider #default="{ errors }" :name="`situation familiale ami{${index}}`"
                              :rules="`requiredIfChecked:${ami.state}`" vid="par_situation_familiale">
                              <b-form-select v-model="ami.situation_familiale" :options="SituationFamilialeOptions" id="situation_familiale"
                                :state="errors.length > 0 ? false : null" />
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> Le champ est obligatoire
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col> -->

                            <template v-if="ami.state">
                              <b-col md="3"
                                v-if="ami.statut == 'SANS_EMPLOI' || ami.statut == 'PROFESSION_LIBERALE' || ami.statut == 'FONCTIONNAIRE_ASSIMILE'">
                                <b-form-group label="Merci de préciser" :label-for="`precision-profession-ami{${index}}`">
                                  <validation-provider v-slot="{ errors }" :name="`Merci de préciser ami{${index}}`"
                                    :rules="`requiredIfChecked:${ami.state}`" :vid="`precision-profession-ami{${index}}`">
                                    <b-form-select v-model="objetEmprunteur.co_emprunteur.precision_profession"
                                      :options="TypePrecisionOptions[ami.statut]"
                                      :id="`precision-profession-ami{${index}}`"
                                      :state="errors.length > 0 ? false : null" />
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> Le champ est
                                      obligatoire </b-form-invalid-feedback>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col md="3">
                                <b-form-group label="Profession" :label-for="`profession-co-emprunteur-ami{${index}}`">
                                  <b-form-input :id="`profession-co-emprunteur-ami{${index}}`"
                                    v-model="objetEmprunteur.co_emprunteur.profession" class="text-capitalize"
                                    placeholder="Exemple : Boulanger" type="text" />
                                </b-form-group>
                              </b-col>
                              <b-col md="3">
                                <b-form-group label="Code postal *" :label-for="`code-postal-ami{${index}}`">
                                  <validation-provider #default="{ errors }" :name="`code postal ami {${index}}`"
                                    rules="required|length:5">
                                    <cleave :id="`code-postal-ami{${index}}`" v-model="ami.moyen_contact.code_postal"
                                      :class="errors.length > 0 ? 'is-invalid' : ''" :options="options.codePostal"
                                      :raw="false" class="form-control" placeholder="Code postal" type="tel"
                                      @input="ami.state ? getVilleByCodePostal($event, index, objetEmprunteur.lien) : ''" />
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                      Le champ est obligatoire
                                    </b-form-invalid-feedback>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col md="3">
                                <b-form-group label="Ville *" :label-for="`ville-ami{${index}}`">
                                  <validation-provider #default="{ errors }" :name="`ville ami {${index}}`"
                                    rules="required">
                                    <b-form-select v-model="ami.moyen_contact.ville_id"
                                      :state="errors.length > 0 ? false : null"
                                      :class="errors.length > 0 ? 'is-invalid' : ''">
                                      <b-form-select-option :value="null">
                                        Choisissez
                                      </b-form-select-option>
                                      <b-form-select-option v-for="ville in villeOptionsCoEmprunteur" :key="ville.id"
                                        :value="ville.id">
                                        {{ ville.ville }}
                                      </b-form-select-option>
                                    </b-form-select>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                      Le champ est obligatoire
                                    </b-form-invalid-feedback>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                            </template>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row class="mt-1 mb-2">
                        <b-col md="11" sm="11" :offset="amisArray.length > 0 ? 1 : 0">
                          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="md" variant="outline-primary"
                            @click="addConnexite(objetEmprunteur.lien)">
                            <feather-icon icon="PlusIcon" />
                            <span class="align-middle">Ajouter</span>
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>
                    <b-row v-if="objetEmprunteur.lien != 'CONJOINT' && hasCoEmprunteurSelected">
                      <b-col class="col-10 my-1" offset="1">
                        <h5>Informations complémentaires du co-assuré sélectionné</h5>
                      </b-col>
                    </b-row>
                    <div v-if="hasCoEmprunteurSelected">
                      <b-row class="mb-1">
                        <b-col md="10" offset="1">
                          <b-row class="mb-1 align-items-center">
                            <b-col md="3">
                              <b-form-group class="mb-0">
                                <label for="fumeur" class="mb-1">
                                  Fumeur ?
                                  <span
                                    v-b-popover.hover.top="'Ne fumant pas ou ayant arrêté de fumer (Cigarette ou Vapotage électronique) depuis plus de 24 mois.'"
                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'" title="Non fumeur"
                                    class="ml-1 cursor-pointer" variant="outline-primary">
                                    <feather-icon icon="InfoIcon" size="20" />
                                  </span>
                                </label>
                                <validation-provider #default="{ errors }" name="fumeur co-emprunteur" rules="required">
                                  <b-form-radio-group v-model="objetEmprunteur.co_emprunteur.fumeur"
                                    name="radio-fumeur-co-emprunteur" :state="errors.length > 0 ? false : null">
                                    <b-form-radio v-for="option in BooleanValue" :key="option.value"
                                      :value="option.value">
                                      {{ option.text }}
                                    </b-form-radio>
                                  </b-form-radio-group>
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    Le choix est obligatoire
                                  </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="3">
                              <b-form-group class="mb-0">
                                <label for="contrat-temps-plein-co-emprunteur" class="mb-1">
                                  Activité professionnelle à temps plein au vu de la durée habituelle prévue au contrat de travail ?
                                </label>
                                <validation-provider #default="{ errors }" name="contrat temps plein co-emprunteur"
                                  rules="required">
                                  <b-form-radio-group v-model="objetEmprunteur.co_emprunteur.is_contrat_temps_plein"
                                    name="radio-contrat-temps-plein-co-emprunteur"
                                    :state="errors.length > 0 ? false : null"
                                    @change="objetEmprunteur.co_emprunteur.en_conge = null">
                                    <b-form-radio v-for="option in BooleanValue" :key="option.value"
                                      :value="option.value">
                                      {{ option.text }}
                                    </b-form-radio>
                                  </b-form-radio-group>
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    Le choix est obligatoire
                                  </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col v-if="!objetEmprunteur.co_emprunteur.is_contrat_temps_plein" md="3">
                              <b-form-group class="mb-0">
                                <label for="en-conge" class="mb-1">
                                  En congé parental, maternité, paternité, sabbatique ?
                                  <span
                                    v-b-popover.hover.top="'Congé parental, maternité, paternité, sabbatique ou autre congé.'"
                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'" title="En congé"
                                    class="ml-1 cursor-pointer" variant="outline-primary">
                                    <feather-icon icon="InfoIcon" size="20" />
                                  </span>
                                </label>
                                <validation-provider #default="{ errors }" name="en-conge-co-emprunteur" rules="required">
                                  <b-form-radio-group v-model="objetEmprunteur.co_emprunteur.en_conge"
                                    name="radio-en-conge-co-emprunteur" :state="errors.length > 0 ? false : null">
                                    <b-form-radio v-for="option in BooleanValue" :key="option.value"
                                      :value="option.value">
                                      {{ option.text }}
                                    </b-form-radio>
                                  </b-form-radio-group>
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    Le choix est obligatoire
                                  </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="3">
                              <b-form-group>
                                <label for="deplacement-pro-co-emprunteur" class="mb-1">
                                  Déplacement à titre professionnel ?
                                </label>
                                <span id="my_popover_2" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                  class="ml-1 cursor-pointer">
                                  <feather-icon icon="InfoIcon" size="20" />
                                </span>
                                <b-popover container="body" target="my_popover_2" placement="buttom" triggers="hover">
                                  <template #title>
                                    Kilomètres professionnel par an
                                  </template>
                                  Réalisé avec un véhicule terrestre à moteur et hors domicile/travail
                                </b-popover>
                                <validation-provider v-slot="{ errors }"
                                  name="deplacement a titre professionnel co-emprunteur" rules="required">
                                  <b-form-select id="deplacement-pro-co-emprunteur"
                                    v-model="objetEmprunteur.co_emprunteur.deplacement_pro"
                                    :options="DeplacementeProfessionnelOptions"
                                    :state="errors.length > 0 ? false : null" />
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    Le champ est obligatoire
                                  </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="3" class="my-2">
                              <b-form-group class="mb-0">
                                <label for="activite-manuel-regulier-co-emprunteur" class="mb-1">
                                  Activité avec manutention/travaux manuel régulier ?
                                </label>
                                <validation-provider #default="{ errors }" name="activite manuel regulier co-emprunteur"
                                  rules="required">
                                  <b-form-radio-group v-model="objetEmprunteur.co_emprunteur.activite_manuel_regulier"
                                    name="radio-activite-manuel-regulier-co-emprunteur"
                                    :state="errors.length > 0 ? false : null">
                                    <b-form-radio v-for="option in BooleanValue" :key="option.value"
                                      :value="option.value">
                                      {{ option.text }}
                                    </b-form-radio>
                                  </b-form-radio-group>
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    Le choix est obligatoire
                                  </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="3" class="my-2">
                              <b-form-group class="mb-0">
                                <label for="travail-hauteur-co-emprunteur" class="mb-1">
                                  Travail en hauteur ?
                                  <span
                                      v-b-popover.hover.top="'Activité professionnelle à plus de 15 mètres de hauteur'"
                                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                      title="Travail en hauteur"
                                      class="ml-1 cursor-pointer"
                                      variant="outline-primary"
                                  >
                                      <feather-icon icon="InfoIcon" size="20" />
                                  </span>
                                </label>
                                <validation-provider #default="{ errors }" name="travail en hauteur co-emprunteur"
                                  rules="required">
                                  <b-form-radio-group v-model="objetEmprunteur.co_emprunteur.travail_hauteur"
                                    name="radio-travail-hauteur-co-emprunteur" :state="errors.length > 0 ? false : null">
                                    <b-form-radio v-for="option in BooleanValue" :key="option.value"
                                      :value="option.value">
                                      {{ option.text }}
                                    </b-form-radio>
                                  </b-form-radio-group>
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    Le choix est obligatoire
                                  </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="5" class="my-2">
                              <b-form-group class="mb-0">
                                <label for="en-cours-co-emprunteur" class="mb-1">
                                  En incluant ce projet, le co-assuré a-t-il des encours de prêts immobiliers assurés
                                  (hors prêt professionnels) supérieurs à 200 000 € ?
                                </label>
                                <validation-provider #default="{ errors }" name="en cours co-emprunteur" rules="required">
                                  <b-form-radio-group v-model="objetEmprunteur.co_emprunteur.en_cours"
                                    @change="fillFranchiseList('C')" name="radio-en-cours-co-emprunteur"
                                    :state="errors.length > 0 ? false : null">
                                    <b-form-radio v-for="option in BooleanValue" :key="option.value"
                                      :value="option.value">
                                      {{ option.text }}
                                    </b-form-radio>
                                  </b-form-radio-group>
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    Le choix est obligatoire
                                  </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="12" class="my-2">

                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </app-collapse-item>
          <!-- Prêt principal -->
          <app-collapse-item ref="tab-collapse-item-2" :is-visible="true"
            title="<h4 class='title-custom-wizard'><u>Prêt principal</u></h4>">
            <b-row class="align-items-center mb-2">
              <b-col md="3">
                <b-form-group class="mb-0">
                  <label for="type-formule" class="font-weight-normal">
                    Type de formule
                    <span
                      v-b-popover.top.hover.html="'<ul><li>' +
                         '<strong> Capital Initial (CI) :</strong> Mensualité fixe.</li>' +
                           '<li><strong> Capital Restant Dû (CRD) :</strong> Mensualité variable.</li>' +
                            '</ul>'"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'" title="Type de formule emprunteur"
                      class="ml-1 cursor-pointer" variant="outline-primary">
                      <feather-icon icon="InfoIcon" size="20" />
                    </span>
                  </label>
                  <validation-provider #default="{ errors }" name="type formule" rules="required">
                    <b-form-select id="type-formule" v-model="objetEmprunteur.prets.type_formule"
                      :options="TypeFormuleOptions" :state="errors.length > 0 ? false : null" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      Le champ est obligatoire
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group class="mb-0">
                  <label class="pr-2 mb-1 font-weight-normal">Type de demande ?</label>
                  <validation-provider v-slot="{ errors }" name="type demande" rules="required" vid="type-demande">
                    <b-form-radio-group id="type-demande-option" v-model="objetEmprunteur.prets.type_demande"
                      @change="initDataByTypeDemande">
                      <b-form-radio v-for="option in TypeDemandeOptions" :key="option.value" :value="option.value">
                        {{ option.text }}
                        <span v-b-popover.hover.top="option.infoBulle.description"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'" :title="option.infoBulle.title"
                          class="ml-1 cursor-pointer" variant="outline-primary">
                          <feather-icon icon="InfoIcon" size="20" />
                        </span>
                      </b-form-radio>
                    </b-form-radio-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      Le choix est obligatoire
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group class="mb-0">
                  <label for="date-effet-pret-principal" class="font-weight-normal">
                    Date d'effet
                    <span
                      v-b-popover.top.hover.html="'<ul><li><p>Pour une demande de déliaison, il s’agit de la date d’effet du prêt.</p></li><li><p>Pour une demande de substitution, il s’agit de la date d’effet du contrat (date de substitution souhaitée).</p></li></ul>'"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'" title="Date d'effet" class="ml-1 cursor-pointer"
                      variant="outline-primary">
                      <feather-icon icon="InfoIcon" size="20" />
                    </span>
                  </label>
                  <validation-provider #default="{ errors }" ref="dateEffet" name="date d'effet du prêt principal"
                    rules="required">
                    <flat-pickr id="date-effet-pret-principal" v-model="objetEmprunteur.prets.pret_principal.date_effet"
                      class="form-control" :class="errors.length > 0 ? 'is-invalid' : ''" :config="configDateEffet"
                      placeholder="Date d'effet" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      Le champ est obligatoire
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col v-if="objetEmprunteur.prets.type_demande == 'DELIAISON_ASSURANCE'" md="3">
                <b-form-group label="Avancé du projet" label-for="avance-projet" class="mb-0">
                  <b-form-select id="avance-projet" v-model="objetEmprunteur.prets.pret_principal.avance_projet"
                    :options="AvanceProjetOptions" />
                </b-form-group>
              </b-col>
              <b-col v-if="objetEmprunteur.prets.type_demande == 'SUBSTITUTION'" md="3">
                <b-form-group class="mb-0">
                  <label for="date-signature-pret-principal" class="font-weight-normal">
                    Date de signature du prêt
                  </label>
                  <validation-provider #default="{ errors }" ref="dateSignature"
                    name="date de signature du prêt principal" rules="required">
                    <flat-pickr id="date-signature-pret-principal"
                      v-model="objetEmprunteur.prets.pret_principal.date_signature" class="form-control"
                      :class="errors.length > 0 ? 'is-invalid' : ''" :config="configDate"
                      placeholder="Date de signature" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      Le champ est obligatoire
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-alert v-show="objetEmprunteur.prets.type_demande == 'SUBSTITUTION'" variant="danger" show
                  class="mt-2 mb-0">
                  <div class="alert-body">
                    <p>
                      <strong>Attention :</strong> Prévoir une date d’effet à J+2 mois (acceptation du dossier). J+3 mois
                      si le dossier présente des particularités
                    </p>
                  </div>
                </b-alert>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3">
                <b-form-group label="Type du prêt" label-for="type-pret">
                  <validation-provider #default="{ errors }" name="type-pret" rules="required">
                    <b-form-select id="type" v-model="objetEmprunteur.prets.pret_principal.type" :options="TypesPrincipal"
                      :state="errors.length > 0 ? false : null" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      Le champ est obligatoire
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Nature" label-for="nature">
                  <validation-provider #default="{ errors }" name="nature" rules="required">
                    <b-form-select id="nature" v-model="objetEmprunteur.prets.pret_principal.nature"
                      :options="NaturesPrincipal" :state="errors.length > 0 ? false : null" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      Le champ est obligatoire
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Objet" label-for="objet">
                  <validation-provider #default="{ errors }" name="objet" rules="required">
                    <b-form-select id="objet" v-model="objetEmprunteur.prets.pret_principal.objet"
                      :options="ObjetPrincipalOptions" :state="errors.length > 0 ? false : null" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      Le champ est obligatoire
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col md="3">
                <b-form-group class="mb-0">
                  <label class="mb-1 font-weight-normal">S'agit-il d'un prêt palier ?
                    <span v-b-popover.hover.top="'Échéance variant dans le temps'"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'" title="Prêt à palier" class="ml-1 cursor-pointer"
                      variant="outline-primary">
                      <feather-icon icon="InfoIcon" size="20" />
                    </span>
                  </label>
                  <validation-provider v-slot="{ errors }" name="is pret-palier" rules="required" vid="has_pret_palier">
                    <b-form-radio-group id="is_pret_palier_option"
                      v-model="objetEmprunteur.prets.pret_principal.has_pret_palier" :options="BooleanValue"
                      @change="resetDataPretPalier($event, 'PRINCIPAL')" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      Le choix est obligatoire
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group class="mb-0" label="Taux d'intérêt" label-for="taux_interet">
                  <validation-provider #default="{ errors }" name="taux d'intérêt" rules="required|between:0.00,9.99">
                    <b-form-input v-model="objetEmprunteur.prets.pret_principal.taux_interet" v-mask="['#', '.##']"
                      :state="errors.length > 0 ? false : null" placeholder="Taux d'intérêt" type="text" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      Le champ est obligatoire
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="5">
                <b-form-group class="mb-0">
                  <label for="type-taux" class="mb-1 font-weight-normal">Type de taux</label>
                  <validation-provider #default="{ errors }" name="type-taux" rules="required">
                    <b-form-radio-group v-model="objetEmprunteur.prets.pret_principal.type_taux" name="radio-type-taux"
                      :state="errors.length > 0 ? false : null">
                      <b-form-radio v-for="option in optionsTaux" :key="option.value" :value="option.value">
                        {{ option.text }}
                      </b-form-radio>
                    </b-form-radio-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      Le choix est obligatoire
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <template v-if="objetEmprunteur.prets.type_demande == 'DELIAISON_ASSURANCE'">
                <b-col md="3">
                  <b-form-group label="Capital initial" label-for="capital-initial">
                    <validation-provider #default="{ errors }" name="capital initial" rules="required|between:1,10000000">
                      <b-form-input id="capital" v-model="objetEmprunteur.prets.pret_principal.capital"
                        placeholder="Capital initial" type="number" :state="errors.length > 0 ? false : null" />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                        La valeur doit être comprise entre 1 et 10 000 000 euros
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group class="mb-0" label="Durée (en mois)" label-for="duree">
                    <validation-provider #default="{ errors }" name="durée" rules="required|integer|between:1,600">
                      <b-form-input v-model="objetEmprunteur.prets.pret_principal.duree_pret" v-mask="'####'"
                        :state="errors.length > 0 ? false : null" placeholder="Durée" type="text" />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                        La valeur doit être comprise entre 1 et 600 mois
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </template>
              <template v-else>
                <b-col md="3">
                  <b-form-group label="Capital restant dû à la date de substitution" label-for="capital-restant">
                    <validation-provider #default="{ errors }" name="capital restant" rules="required|between:1,10000000">
                      <b-form-input id="capital-restant" v-model="objetEmprunteur.prets.pret_principal.capital"
                        placeholder="Capital restant" type="number" :state="errors.length > 0 ? false : null" />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                        La valeur doit être comprise entre 1 et 10 000 000 euros
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group class="mb-0" label="Durée restante à compter de la date de substitution (en mois)"
                    label-for="duree-restante">
                    <validation-provider #default="{ errors }" name="durée restante"
                      rules="required|integer|between:1,600">
                      <b-form-input v-model="objetEmprunteur.prets.pret_principal.duree_pret" v-mask="'####'"
                        :state="errors.length > 0 ? false : null" placeholder="Durée restante" type="text" />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                        La valeur doit être comprise entre 1 et 600 mois
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </template>
            </b-row>
            <template v-if="objetEmprunteur.prets.pret_principal.has_pret_palier && true">
              <hr class="mb-2">
              <b-row v-for="(pal, index) in objetEmprunteur.prets.pret_principal.paliersArray"
                :key="`pret-principal-${index}`" class="mb-1">
                <b-col md="12">
                  <b-row>
                    <b-col md="6">
                      <b-row>
                        <b-col md="6">
                          <b-form-group :label="`Mensualité du palier ${index + 1}`"
                            :label-for="`mensualite-palier-${index + 1}`">
                            <validation-provider #default="{ errors }" :name="`mensualite palier ${index + 1}`"
                              rules="required">
                              <b-form-input :id="`mensualite-palier-${index + 1}`" v-model="pal.mensualite_palier"
                                placeholder="Mensualité en €" type="number" :state="errors.length > 0 ? false : null" />
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                Le champ est obligatoire
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-group class="mb-0" :label="`Durée du palier ${index + 1}`"
                            :label-for="`duree-palier-${index + 1}`">
                            <validation-provider #default="{ errors }" name="durée du palier"
                              rules="required|integer|between:1,9999">
                              <b-form-input v-model="pal.duree_palier" v-mask="'####'"
                                :state="errors.length > 0 ? false : null" placeholder="Durée en mois" type="text" />
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                Le champ est obligatoire
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col v-if="!pal.id" md="1" class="text-center" style="margin-top:30px">
                      <div class="d-inline" @click="removePalier(index, 'PRINCIPAL')">
                        <feather-icon icon="TrashIcon" size="22" class="mr-50 cursor-pointer text-danger" />
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col md="12" sm="12">
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="outline-primary"
                    @click="addPalier('PRINCIPAL')" v-if="!ShowPrincipalAddPalier">
                    <feather-icon icon="PlusIcon" />
                    <span class="align-middle">AJOUTER UN PALIER</span>
                  </b-button>
                </b-col>
              </b-row>
            </template>
            <b-row class="align-items-center">
              <b-col md="4">
                <b-form-group class="mb-0 mt-3">
                  <label class="float-left pr-1 mb-2">Le prêt comporte-t-il un différé ?</label>
                  <validation-provider v-slot="{ errors }" name="pret has differe" rules="required"
                    vid="pret-has-differe">
                    <b-form-radio-group id="pret-has-differe-option"
                      v-model="objetEmprunteur.prets.pret_principal.has_differe"
                      @change="resetDataDiffere($event, 'PRINCIPAL')">
                      <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                        {{ option.text }}
                      </b-form-radio>
                    </b-form-radio-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      Le choix est obligatoire
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <template v-if="objetEmprunteur.prets.pret_principal.has_differe">
                <b-col md="3">
                  <b-form-group class="mb-0">
                    <label for="type-differe" class="font-weight-normal">Différé partiel ou total ?</label>
                    <validation-provider #default="{ errors }" name="type differe" rules="required">
                      <b-form-select id="type-differe" v-model="objetEmprunteur.prets.pret_principal.type_differe"
                        :options="ImpactDiffereOptions" :state="errors.length > 0 ? false : null" />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                        Le champ est obligatoire
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group class="mb-0" label="Durée du différé (en mois)" label-for="duree-differe">
                    <validation-provider #default="{ errors }" name="durée différé"
                      rules="required|integer|between:1,9999">
                      <b-form-input v-model="objetEmprunteur.prets.pret_principal.duree_differe" v-mask="'####'"
                        :state="errors.length > 0 ? false : null" placeholder="Durée en mois" type="text" />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                        Le choix est obligatoire
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </template>
            </b-row>
            <b-row class="mt-1 mb-2">
              <b-col md="3">
                <b-form-group class="mb-0">
                  <label for="add-pret" class="float-left pr-4">Ajouter un prêt ?</label>
                  <validation-provider #default="{ errors }" name="has prêt secondaire" rules="required">
                    <b-form-radio-group v-model="objetEmprunteur.has_pret_secondaire" name="radio-has-pret-secondaire"
                      :state="errors.length > 0 ? false : null" @change="resetDataPretSec">
                      <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                        {{ option.text }}
                      </b-form-radio>
                    </b-form-radio-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      Le choix est obligatoire
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item ref="tab-collapse-item-3" :is-visible="true"
            title="<h4 class='title-custom-wizard'><u>Prêt secondaire</u></h4>"
            v-if="objetEmprunteur.has_pret_secondaire">
            <template>
              <b-row class="my-2 align-items-center">
                <b-col md="5">
                  <b-form-group class="mb-0 mt-2">
                    <label for="has-diff-date-effet" class="float-left pr-4">La date d'effet est-elle différente de celle
                      du prêt principal ?</label>
                    <validation-provider #default="{ errors }" name="has diff date d'effet" rules="required">
                      <b-form-radio-group v-model="objetEmprunteur.prets.pret_secondaire.has_diff_date" name="radio-taux"
                        :state="errors.length > 0 ? false : null"
                        @change="$event ? objetEmprunteur.prets.pret_secondaire.date_effet = null : ''">
                        <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                          {{ option.text }}
                        </b-form-radio>
                      </b-form-radio-group>
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                        Le choix est obligatoire
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col v-if="objetEmprunteur.prets.pret_secondaire.has_diff_date" md="3">
                  <b-form-group class="mb-0">
                    <label for="date-effet-pret-secondaire" class="font-weight-normal">
                      Date d'effet

                    </label>
                    <validation-provider #default="{ errors }" ref="dateEffet" name="date d'effet du prêt"
                      rules="required">
                      <flat-pickr id="date-effet-pret-secondaire"
                        v-model="objetEmprunteur.prets.pret_secondaire.date_effet" class="form-control"
                        :class="errors.length > 0 ? 'is-invalid' : ''" :config="configDateEffet"
                        placeholder="Date d'effet" />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                        Le champ est obligatoire
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col v-if="objetEmprunteur.prets.type_demande == 'DELIAISON_ASSURANCE'" md="3">
                  <b-form-group label="Avancé du projet" label-for="avance-projet-secondaire" class="mb-0">
                    <b-form-select id="avance-projet-secondaire"
                      v-model="objetEmprunteur.prets.pret_secondaire.avance_projet" :options="AvanceProjetOptions" />
                  </b-form-group>
                </b-col>
                <b-col v-if="objetEmprunteur.prets.type_demande == 'SUBSTITUTION'" md="3">
                  <b-form-group class="mb-0">
                    <label for="date-signature-pret-secondaire" class="font-weight-normal">
                      Date de signature du prêt
                    </label>
                    <validation-provider #default="{ errors }" ref="dateSignature"
                      name="date de signature du prêt secondaire" rules="required">
                      <flat-pickr id="date-signature-pret-secondaire"
                        v-model="objetEmprunteur.prets.pret_secondaire.date_signature" class="form-control"
                        :class="errors.length > 0 ? 'is-invalid' : ''" :config="configDate"
                        placeholder="Date de signature" />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                        Le champ est obligatoire
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-alert v-show="objetEmprunteur.prets.type_demande == 'SUBSTITUTION'" variant="danger" show
                    class="mt-2 mb-0">
                    <div class="alert-body">
                      <p>
                        <strong>Attention :</strong> Prévoir une date d’effet à J+2 mois (acceptation du dossier). J+3
                        mois si le dossier présente des particularités.
                      </p>
                    </div>
                  </b-alert>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="3">
                  <b-form-group label="Type du prêt" label-for="type-pret-secondaire">
                    <validation-provider #default="{ errors }" name="type-pret-secondaire" rules="required">
                      <b-form-select id="type-secondaire" v-model="objetEmprunteur.prets.pret_secondaire.type"
                        :options="TypesPrincipal" :state="errors.length > 0 ? false : null" />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                        Le champ est obligatoire
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Nature" label-for="nature-secondaire">
                    <validation-provider #default="{ errors }" name="nature secondaire" rules="required">
                      <b-form-select id="nature-secondaire" v-model="objetEmprunteur.prets.pret_secondaire.nature"
                        :options="NaturesPrincipal" :state="errors.length > 0 ? false : null" />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                        Le champ est obligatoire
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Objet" label-for="objet-secondaire">
                    <validation-provider #default="{ errors }" name="objet secondaire" rules="required">
                      <b-form-select id="objet-secondaire" v-model="objetEmprunteur.prets.pret_secondaire.objet"
                        :options="ObjetPrincipalOptions" :state="errors.length > 0 ? false : null" />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                        Le champ est obligatoire
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col md="3">
                  <b-form-group class="mb-0">
                    <label class="mb-1 font-weight-normal">S'agit-il d'un prêt palier ?
                      <span v-b-popover.hover.top="'Échéance variant dans le temps'"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'" title="Prêt à palier" class="ml-1 cursor-pointer"
                        variant="outline-primary">
                        <feather-icon icon="InfoIcon" size="20" />
                      </span>
                    </label>
                    <validation-provider v-slot="{ errors }" name="has pret palier secondaire" rules="required"
                      vid="has_pret_palier_secondaire">
                      <b-form-radio-group id="has_pret_palier_secondaire_option"
                        v-model="objetEmprunteur.prets.pret_secondaire.has_pret_palier" :options="BooleanValue"
                        @change="resetDataPretPalier($event, 'SECONDAIRE')" />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                        Le choix est obligatoire
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group class="mb-0" label="Taux d'intérêt" label-for="taux_interet_secondaire">
                    <validation-provider #default="{ errors }" name="taux d'intérêt secondaire"
                      rules="required|between:0.00,9.99">
                      <b-form-input v-model="objetEmprunteur.prets.pret_secondaire.taux_interet" v-mask="['#', '.##']"
                        :state="errors.length > 0 ? false : null" placeholder="Taux d'intérêt" type="text" />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                        Le champ est obligatoire
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="5">
                  <b-form-group class="mb-0">
                    <label for="type-taux" class="mb-1 font-weight-normal">Type de taux</label>
                    <validation-provider #default="{ errors }" name="type-taux-secondaire" rules="required">
                      <b-form-radio-group v-model="objetEmprunteur.prets.pret_secondaire.type_taux"
                        name="radio-type-taux-secondaire" :state="errors.length > 0 ? false : null">
                        <b-form-radio v-for="option in optionsTaux" :key="option.value" :value="option.value">
                          {{ option.text }}
                        </b-form-radio>
                      </b-form-radio-group>
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                        Le choix est obligatoire
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <template v-if="objetEmprunteur.prets.type_demande == 'DELIAISON_ASSURANCE'">
                  <b-col md="3">
                    <b-form-group label="Capital initial" label-for="capital-initial-secondaire">
                      <validation-provider #default="{ errors }" name="capital initial secondaire"
                        rules="required|between:1,10000000">
                        <b-form-input id="capital-initial-secondaire"
                          v-model="objetEmprunteur.prets.pret_secondaire.capital" placeholder="Capital initial"
                          type="number" :state="errors.length > 0 ? false : null" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          La valeur doit être comprise entre 1 et 10 000 000 euros
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group class="mb-0" label="Durée (en mois)" label-for="duree-secondaire">
                      <validation-provider #default="{ errors }" name="durée secondaire"
                        rules="required|integer|between:1,600">
                        <b-form-input v-model="objetEmprunteur.prets.pret_secondaire.duree_pret" v-mask="'####'"
                          :state="errors.length > 0 ? false : null" placeholder="Durée" type="text" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          La valeur doit être comprise entre 1 et 600 mois
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </template>
                <template v-else>
                  <b-col md="3">
                    <b-form-group label="Capital restant dû à la date de substitution"
                      label-for="capital-restant-secondaire">
                      <validation-provider #default="{ errors }" name="capital restant secondaire"
                        rules="required|between:1,10000000">
                        <b-form-input id="capital-restant-secondaire"
                          v-model="objetEmprunteur.prets.pret_secondaire.capital" placeholder="Capital restant"
                          type="number" :state="errors.length > 0 ? false : null" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          La valeur doit être comprise entre 1 et 10 000 000 euros
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group class="mb-0" label="Durée restante à compter de la date de substitution (en mois)"
                      label-for="duree-restante-secondaire">
                      <validation-provider #default="{ errors }" name="durée restante secondaire"
                        rules="required|integer|between:1,600">
                        <b-form-input v-model="objetEmprunteur.prets.pret_secondaire.duree_pret" v-mask="'####'"
                          :state="errors.length > 0 ? false : null" placeholder="Durée restante" type="text" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          La valeur doit être comprise entre 1 et 600 mois
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </template>
              </b-row>
              <template v-if="objetEmprunteur.prets.pret_secondaire.has_pret_palier && true">
                <hr class="mb-2">
                <b-row v-for="(pal_sec, index) in objetEmprunteur.prets.pret_secondaire.paliersArray"
                  :key="`pret-secondaire-${index}`" class="mb-1">
                  <b-col md="12">
                    <b-row>
                      <b-col md="6">
                        <b-row>
                          <b-col md="6">
                            <b-form-group :label="`Mensualité du palier ${index + 1}`"
                              :label-for="`mensualite-palier-secondaire-${index + 1}`">
                              <validation-provider #default="{ errors }"
                                :name="`mensualite palier secondaire ${index + 1}`" rules="required">
                                <b-form-input :id="`mensualite-palier-secondaire-${index + 1}`"
                                  v-model="pal_sec.mensualite_palier" placeholder="Mensualité en €" type="number"
                                  :state="errors.length > 0 ? false : null" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                  Le champ est obligatoire
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group class="mb-0" :label="`Durée du palier ${index + 1}`"
                              :label-for="`duree-palier-secondaire-${index + 1}`">
                              <validation-provider #default="{ errors }" name="durée du palier"
                                rules="required|integer|between:1,9999">
                                <b-form-input v-model="pal_sec.duree_palier" v-mask="'####'"
                                  :state="errors.length > 0 ? false : null" placeholder="Durée en mois" type="text" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                  Le champ est obligatoire
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col v-if="!pal_sec.id" md="1" class="text-center" style="margin-top:30px">
                        <div class="d-inline" @click="removePalier(index, 'SECONDAIRE')">
                          <feather-icon icon="TrashIcon" size="22" class="mr-50 cursor-pointer text-danger" />
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="mt-1">
                  <b-col md="12" sm="12">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="outline-primary"
                      @click="addPalier('SECONDAIRE')" v-if="!ShowSecondaireAddPalier">
                      <feather-icon icon="PlusIcon" />
                      <span class="align-middle">AJOUTER UN PALIER</span>
                    </b-button>
                  </b-col>
                </b-row>
              </template>
              <b-row class="mt-1 align-items-center">
                <b-col md="4">
                  <b-form-group class="mb-0 mt-2">
                    <label class="float-left pr-1 mb-2">Le prêt comporte-t-il un différé ?</label>
                    <validation-provider v-slot="{ errors }" name="pret has differe secondaire" rules="required"
                      vid="pret-has-differe-secondaire">
                      <b-form-radio-group id="pret-has-differe-secondaire-option"
                        v-model="objetEmprunteur.prets.pret_secondaire.has_differe"
                        @change="resetDataDiffere($event, 'SECONDAIRE')">
                        <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                          {{ option.text }}
                        </b-form-radio>
                      </b-form-radio-group>
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                        Le choix est obligatoire
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <template v-if="objetEmprunteur.prets.pret_secondaire.has_differe">
                  <b-col md="3">
                    <b-form-group class="mb-0">
                      <label for="type-differe-secondaire" class="font-weight-normal">Différé partiel ou total ?</label>
                      <validation-provider #default="{ errors }" name="type differe secondaire" rules="required">
                        <b-form-select id="type-differe-secondaire"
                          v-model="objetEmprunteur.prets.pret_secondaire.type_differe" :options="ImpactDiffereOptions"
                          :state="errors.length > 0 ? false : null" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le champ est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group class="mb-0" label="Durée du différé (en mois)" label-for="duree-differe-secondaire">
                      <validation-provider #default="{ errors }" name="durée différé secondaire"
                        rules="required|integer|between:1,9999">
                        <b-form-input v-model="objetEmprunteur.prets.pret_secondaire.duree_differe" v-mask="'####'"
                          :state="errors.length > 0 ? false : null" placeholder="Durée en mois" type="text" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le choix est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </template>
              </b-row>
            </template>
          </app-collapse-item>
          <!-- Garanties -->
          <app-collapse-item ref="tab-collapse-item-4" :is-visible="true"
            title="<h4 class='title-custom-wizard'><u>Garanties</u></h4>">
            <b-row class="mb-2">
              <b-col md="12">
                <b class="d-block">Préciser par assuré et par prêt</b>
                <small>Attention à bien vérifier la cohérence avec le besoin exprimé par le client</small>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <b-alert v-show="errorsGaranties.length > 0" variant="danger" show class="mt-1">
                  <div class="alert-body">
                    <ul class="mb-0">
                      <li v-for="(error, index) in errorsGaranties" :key="`erreur-garantie-${index}`">
                        {{ error }}
                      </li>
                    </ul>
                  </div>
                </b-alert>
              </b-col>
            </b-row>
            <b-row class="my-2">
              <b-col md="6">
                <i class="title-custom-wizard d-block mb-2"><b>Prêt principal / Assuré</b></i>
                <b-row>
                  <b-col md="12">
                    <b-form-group>
                      <label class="mb-1" :class="objetEmprunteur.has_pret_secondaire ? 'mt-3' : ''">Quel est le besoin
                        exprimé par l’assuré ?</label>
                      <validation-provider #default="{ errors }" name="besoin client" rules="required">
                        <b-form-radio-group
                          v-model="objetEmprunteur.prets.pret_principal.garanties.emprunteur.besoin_client"
                          name="radio-besoin-client" :state="errors.length > 0 ? false : null" class="demo-inline-spacing"
                          :disabled="disBc" @change="resetValueBesoinClient($event, 'pret_principal', 'emprunteur')">
                          <b-form-radio v-for="option in BesoinClientOptions" :key="option.value" :value="option.value"
                            class="mt-0">
                            <small>{{ option.text }}</small>
                            <span v-if="option.infoBull" :id="option.value" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              class="ml-1 cursor-pointer" variant="outline-primary">

                              <feather-icon icon="InfoIcon" size="20" />
                            </span>

                          </b-form-radio>
                        </b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le choix est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12" class="mt-1">
                    <b-row class="align-items-center">
                      <b-col md="4">
                        <b-form-group>
                          <label for="ptia" class="font-weight-normal">
                            Quotité Décès/PTIA (%)
                            <span
                              v-b-popover.top.hover.html="'La quotité assurée sert à calculer le montant de la cotisation et à déterminer le taux d’indemnisation.'"
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'" :title="'Quotité'" class="ml-1 cursor-pointer"
                              variant="outline-primary">
                              <feather-icon icon="InfoIcon" size="20" />
                            </span>
                          </label>
                          <validation-provider #default="{ errors }" name="ptia" rules="required|between:1,100">
                            <b-form-input id="ptia"
                              v-model="objetEmprunteur.prets.pret_principal.garanties.emprunteur.ptia"
                              placeholder="Quotité Décès/PTIA (%)" type="number" :state="errors.length > 0 ? false : null" />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                              La valeur doit être comprisee entre 1 et 100
                            </b-form-invalid-feedback>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="8">
                        <b-form-group>
                          <label for="supplement-deces" class="float-left pr-4 font-weight-normal">Supplément décès
                            accidentel</label>
                          <validation-provider #default="{ errors }" name="supplément décès" rules="required">
                            <b-form-radio-group
                              v-model="objetEmprunteur.prets.pret_principal.garanties.emprunteur.suppl_deces"
                              name="radio-supplement-deces" :state="errors.length > 0 ? false : null">
                              <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                {{ option.text }}
                              </b-form-radio>
                            </b-form-radio-group>
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                              Le choix est obligatoire
                            </b-form-invalid-feedback>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <template
                        v-if="objetEmprunteur.prets.pret_principal.garanties.emprunteur.besoin_client != 'MINIMUM'">
                        <b-col md="4">
                          <b-form-group>
                            <label for="incapacite" class="font-weight-normal">
                              Quotité Incapacité/Invalidité (%)
                              <span
                                v-if="objetEmprunteur.prets.pret_principal.garanties.emprunteur.besoin_client == 'COMPLETE' || objetEmprunteur.prets.pret_principal.garanties.emprunteur.besoin_client == 'OPTIMUM'"
                                v-b-popover.top.hover.html="objetEmprunteur.prets.pret_principal.garanties.emprunteur.besoin_client == 'COMPLETE' ? 'Quotité pour les garanties d\'Invalidité Permanente Totale (IPT) et d\'Incapacité Temporaire de Travail (ITT).' : 'Quotité pour les garanties d\'Invalidité Permanente Totale (IPT),d\'Incapacité Temporaire de Travail (ITT),d\'Invalidité Permanente Partielle (IPP) et de Maladie non objectivable sans conditions'"
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'
                                  " :title="'Quotité Incapacité/Invalidité (%)'" class="ml-1 cursor-pointer"
                                variant="outline-primary">
                                <feather-icon icon="InfoIcon" size="20" />
                              </span>
                            </label>
                            <validation-provider #default="{ errors }" name="incapacite" rules="required|between:1,100">
                              <b-form-input id="incapacite"
                                v-model="objetEmprunteur.prets.pret_principal.garanties.emprunteur.incapacite"
                                placeholder="Quotité Incapacité/Invalidité (%)" type="number"
                                :state="errors.length > 0 ? false : null" />
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                La valeur doit être comprisee entre 1 et 100
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <!-- <pre>{{  objetEmprunteur.prets.pret_principal.garanties.emprunteur.franchise  }}</pre> -->
                          <b-form-group>
                            <label for="franchise" class="font-weight-normal">Franchise</label>
                            <validation-provider #default="{ errors }" name="franchise" rules="required">
                              <b-form-select id="franchise"
                                v-model="objetEmprunteur.prets.pret_principal.garanties.emprunteur.franchise"
                                :options="filterFranchise(true, true)" :state="errors.length > 0 ? false : null" />
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                Le choix est obligatoire
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <b-form-group v-if="showPerlEmplois.showPrincipal">
                            <label for="perte-emploi" class="font-weight-normal">Perte d'emploi</label>
                            <validation-provider #default="{ errors }" name="perte-emploi" rules="required">
                              <b-form-radio-group v-model="objetEmprunteur.prets.pret_principal.garanties.emprunteur.pei"
                                name="perte-emploi" :state="errors.length > 0 ? false : null">
                                <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                  {{ option.text }}
                                </b-form-radio>
                              </b-form-radio-group>

                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                Le choix est obligatoire
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="8" class="pt-2 pb-1">
                          <b-form-group>
                            <label for="mno-dospsy" class="float-left pr-3 font-weight-normal">
                              Option Dos/Psy ?
                              <span
                                v-b-popover.top.hover.html="`<p>La couverture des Maladies non objectivables sans condition (<strong>MNO</strong>) permet le rachat des exclusions sur les maladies dorso-vertèbrales et psychiques.</p>`"
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="ml-1 cursor-pointer"
                                variant="outline-primary">
                                <feather-icon icon="InfoIcon" size="20" />
                              </span>
                            </label>
                            <validation-provider #default="{ errors }" name="mno" rules="required">
                              <b-form-radio-group
                                v-model="objetEmprunteur.prets.pret_principal.garanties.emprunteur.mno_dospsy"
                                name="radio-mno-dospsy" :state="errors.length > 0 ? false : null">
                                <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                  {{ option.text }}
                                </b-form-radio>
                              </b-form-radio-group>
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                Le choix est obligatoire
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="8">
                          <b-form-group>
                            <label for="exo" class="float-left pr-3 font-weight-normal">
                              Exonération des cotisations ?
                              <span
                                v-b-popover.top.hover.html="`<p>Cette option de garantie permet, sous conditions, le remboursement du paiement des cotisations en cas d’arrêt de travail (ITT ou IPT).</p>`"
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="ml-1 cursor-pointer"
                                variant="outline-primary">
                                <feather-icon icon="InfoIcon" size="20" />
                              </span>
                            </label>
                            <validation-provider #default="{ errors }" name="exo" rules="required">
                              <b-form-radio-group v-model="objetEmprunteur.prets.pret_principal.garanties.emprunteur.exo"
                                name="radio-exo-epp" :state="errors.length > 0 ? false : null">
                                <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                  {{ option.text }}
                                </b-form-radio>
                              </b-form-radio-group>
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                Le choix est obligatoire
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </template>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
              <b-col v-if="objetEmprunteur.has_pret_secondaire" md="6">
                <i class="title-custom-wizard d-block mb-2"><b>Prêt secondaire/ Assuré</b></i>
                <b-row>
                  <b-col md="12">
                    <b-form-group class="mb-1">
                      <label for="garanties-differentes" class="float-left pr-4">Les garanties souhaitées sont-elles
                        identiques au prêt principal ?</label>
                      <validation-provider #default="{ errors }" name="garanties différentes" rules="required">
                        <b-form-radio-group
                          v-model="objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.has_same_garanties"
                          name="radio-garanties-differentes" :state="errors.length > 0 ? false : null"
                          @change="resetDataSecondaire($event, 'EMPRUNTEUR')">
                          <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                            {{ option.text }}
                          </b-form-radio>
                        </b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le choix est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <template v-if="!objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.has_same_garanties">
                    <b-col md="12">
                      <b-form-group>
                        <label class="mb-1">Quel est le besoin exprimé par l’assuré ?</label>
                        <validation-provider #default="{ errors }" name="besoin client secondaire" rules="required">
                          <b-form-radio-group
                            v-model="objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.besoin_client"
                            name="radio-besoin-client-secondaire" :state="errors.length > 0 ? false : null"
                            class="demo-inline-spacing" :disabled="disBc"
                            @change="resetValueBesoinClient($event,'pret_secondaire', 'emprunteur')">
                            <b-form-radio v-for="option in BesoinClientOptions" :key="option.value" :value="option.value"
                              class="mt-0">
                              <small>{{ option.text }}</small>
                              <span v-if="option.infoBull" :id="option.value + '1'"
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="ml-1 cursor-pointer"
                                variant="outline-primary">

                                <feather-icon icon="InfoIcon" size="20" />
                              </span>

                            </b-form-radio>
                          </b-form-radio-group>
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                            Le choix est obligatoire
                          </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="12" class="mt-1">
                      <b-row class="align-items-center">
                        <b-col md="4">
                          <b-form-group>
                            <label for="ptia-secondaire" class="font-weight-normal">
                              Quotité Décès/PTIA (%)
                              <span
                                v-b-popover.top.hover.html="'La quotité assurée sert à calculer le montant de la cotisation et à déterminer le taux d’indemnisation.'"
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'" :title="'Quotité'" class="ml-1 cursor-pointer"
                                variant="outline-primary">
                                <feather-icon icon="InfoIcon" size="20" />
                              </span>
                            </label>
                            <validation-provider #default="{ errors }" name="ptia-secondaire"
                              rules="required|between:1,100">
                              <b-form-input id="ptia-secondaire"
                                v-model="objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.ptia"
                                placeholder="Quotité Décès/PTIA (%)" type="number" :state="errors.length > 0 ? false : null" />
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                La valeur doit être comprisee entre 1 et 100
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="8">
                          <b-form-group>
                            <label for="supplement-deces-secondaire" class="float-left pr-4 font-weight-normal">Supplément
                              décès accidentel</label>
                            <validation-provider #default="{ errors }" name="supplément décès secondaire"
                              rules="required">
                              <b-form-radio-group
                                v-model="objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.suppl_deces"
                                name="radio-supplement-deces-secondaire" :state="errors.length > 0 ? false : null">
                                <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                  {{ option.text }}
                                </b-form-radio>
                              </b-form-radio-group>
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                Le choix est obligatoire
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <template
                          v-if="objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.besoin_client != 'MINIMUM'">
                          <b-col md="4">
                            <b-form-group>
                              <label for="incapacite-secondaire" class="font-weight-normal">
                                Quotité Incapacité/Invalidité (%)
                                <span
                                  v-if="objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.besoin_client == 'COMPLETE' || objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.besoin_client == 'OPTIMUM'"
                                  v-b-popover.top.hover.html="objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.besoin_client == 'COMPLETE' ? 'Quotité pour les garanties d\'Invalidité Permanente Totale (IPT) et d\'Incapacité Temporaire de Travail (ITT).' : 'Quotité pour les garanties d\'Invalidité Permanente Totale (IPT),d\'Incapacité Temporaire de Travail (ITT),d\'Invalidité Permanente Partielle (IPP) et de Maladie non objectivable sans conditions'"
                                  v-ripple.400="'rgba(113, 102, 240, 0.15)'
                                    " :title="'Quotité Incapacité/Invalidité (%)'" class="ml-1 cursor-pointer"
                                  variant="outline-primary">
                                  <feather-icon icon="InfoIcon" size="20" />
                                </span>
                              </label>
                              <validation-provider #default="{ errors }" name="incapacite-secondaire"
                                rules="required|between:1,100">
                                <b-form-input id="incapacite-secondaire"
                                  v-model="objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.incapacite"
                                  placeholder="Quotité Incapacité/Invalidité (%)" type="number"
                                  :state="errors.length > 0 ? false : null" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                  La valeur doit être comprisee entre 1 et 100
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col md="4">
                            <b-form-group>
                              <label for="franchise-secondaire" class="font-weight-normal">Franchise</label>
                              <validation-provider #default="{ errors }" name="franchise-secondaire" rules="required">
                                <b-form-select id="franchise-secondaire"
                                  v-model="objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.franchise"
                                  :options="filterFranchise(true, false)" :state="errors.length > 0 ? false : null" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                  Le choix est obligatoire
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col md="4">
                            <b-form-group v-if="showPerlEmplois.showPrincipal">
                              <label for="perte-emploi-secondaire" class="font-weight-normal">Perte d'emploi</label>
                              <validation-provider #default="{ errors }" name="perte-emploi-secondaire" rules="required">
                                <b-form-radio-group
                                  v-model="objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.pei"
                                  name="perte-emploi-secondaire" :state="errors.length > 0 ? false : null">
                                  <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                    {{ option.text }}
                                  </b-form-radio>
                                </b-form-radio-group>

                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                  Le choix est obligatoire
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col md="8" class="pt-2 pb-1">
                            <b-form-group>
                              <label for="mno-dospsy-secondaire" class="float-left pr-3 font-weight-normal">
                                Option Dos/Psy ?
                                <span
                                  v-b-popover.top.hover.html="`<p>La couverture des Maladies non objectivables sans condition (<strong>MNO</strong>) permet le rachat des exclusions sur les maladies dorso-vertèbrales et psychiques.</p>`"
                                  v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="ml-1 cursor-pointer"
                                  variant="outline-primary">
                                  <feather-icon icon="InfoIcon" size="20" />
                                </span>
                              </label>
                              <validation-provider #default="{ errors }" name="mno-secondaire" rules="required">
                                <b-form-radio-group
                                  v-model="objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.mno_dospsy"
                                  name="radio-mno-dospsy-secondaire" :state="errors.length > 0 ? false : null">
                                  <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                    {{ option.text }}
                                  </b-form-radio>
                                </b-form-radio-group>
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                  Le choix est obligatoire
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col md="8">
                            <b-form-group>
                              <label for="exo-secondaire" class="float-left pr-3 font-weight-normal">
                                Exonération des cotisations ?
                                <span
                                  v-b-popover.top.hover.html="`<p>Cette option de garantie permet, sous conditions, le remboursement du paiement des cotisations en cas d’arrêt de travail (ITT ou IPT).</p>`"
                                  v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="ml-1 cursor-pointer"
                                  variant="outline-primary">
                                  <feather-icon icon="InfoIcon" size="20" />
                                </span>
                              </label>
                              <validation-provider #default="{ errors }" name="exo-secondaire" rules="required">
                                <b-form-radio-group
                                  v-model="objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.exo"
                                  name="radio-exo-eps" :state="errors.length > 0 ? false : null">
                                  <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                    {{ option.text }}
                                  </b-form-radio>
                                </b-form-radio-group>
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                  Le choix est obligatoire
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </template>
                      </b-row>
                    </b-col>
                  </template>
                </b-row>
              </b-col>
            </b-row>
            <hr v-if="objetEmprunteur.emprunteur.has_co_emprunteur">
            <b-row class="my-2">
              <b-col v-if="objetEmprunteur.emprunteur.has_co_emprunteur" md="6">
                <i class="title-custom-wizard d-block mb-2"><b>Prêt principal / Co-assuré</b></i>
                <b-row>
                  <b-col md="12">
                    <b-form-group>
                      <label class="mb-1"
                        :class="objetEmprunteur.emprunteur.has_co_emprunteur && objetEmprunteur.has_pret_secondaire ? 'mt-3' : ''">Quel
                        est le besoin exprimé par le co-assuré ?</label>
                      <validation-provider #default="{ errors }" name="besoin client co-emprunteur" rules="required">
                        <b-form-radio-group
                          v-model="objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.besoin_client"
                          name="radio-besoin-client-co-emprunteur" :state="errors.length > 0 ? false : null"
                          class="demo-inline-spacing" :disabled="disBcCe"
                          @change="resetValueBesoinClient($event, 'pret_principal', 'co_emprunteur')">
                          <b-form-radio v-for="option in BesoinClientOptions" :key="option.value" :value="option.value"
                            class="mt-0">
                            <small>{{ option.text }}</small>
                            <span v-if="option.infoBull" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              :id="option.value + '2'" class="ml-1 cursor-pointer" variant="outline-primary">
                              <feather-icon icon="InfoIcon" size="20" />
                            </span>

                          </b-form-radio>
                        </b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le choix est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12" class="mt-2">
                    <b-row class="align-items-center">
                      <b-col md="4">
                        <b-form-group>
                          <label for="ptia-co-emprunteur" class="font-weight-normal">
                            Quotité Décès/PTIA (%)
                            <span
                              v-b-popover.top.hover.html="'La quotité assurée sert à calculer le montant de la cotisation et à déterminer le taux d’indemnisation.'"
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'" :title="'Quotité'" class="ml-1 cursor-pointer"
                              variant="outline-primary">
                              <feather-icon icon="InfoIcon" size="20" />
                            </span>
                          </label>
                          <validation-provider #default="{ errors }" name="ptia-co-emprunteur"
                            rules="required|between:1,100">
                            <b-form-input id="ptia-co-emprunteur"
                              v-model="objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.ptia"
                              placeholder="Quotité Décès/PTIA (%)" type="number" :state="errors.length > 0 ? false : null" />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                              La valeur doit être comprisee entre 1 et 100
                            </b-form-invalid-feedback>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="8">
                        <b-form-group>
                          <label for="supplement-deces-co-assure" class="float-left pr-4 font-weight-normal">Supplément
                            décès accidentel</label>
                          <validation-provider #default="{ errors }" name="supplément décès co-assure" rules="required">
                            <b-form-radio-group
                              v-model="objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.suppl_deces"
                              name="radio-supplement-deces-co-assure" :state="errors.length > 0 ? false : null">
                              <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                {{ option.text }}
                              </b-form-radio>
                            </b-form-radio-group>
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                              Le choix est obligatoire
                            </b-form-invalid-feedback>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <template
                        v-if="objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.besoin_client != 'MINIMUM'">
                        <b-col md="4">
                          <b-form-group>
                            <label for="incapacite-co-emprunteur" class="font-weight-normal">
                              Quotité Incapacité/Invalidité (%)
                              <span
                                v-if="objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.besoin_client == 'COMPLETE' || objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.besoin_client == 'OPTIMUM'"
                                v-b-popover.top.hover.html="objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.besoin_client == 'COMPLETE' ? 'Quotité pour les garanties d\'Invalidité Permanente Totale (IPT) et d\'Incapacité Temporaire de Travail (ITT).' : 'Quotité pour les garanties d\'Invalidité Permanente Totale (IPT),d\'Incapacité Temporaire de Travail (ITT),d\'Invalidité Permanente Partielle (IPP) et de Maladie non objectivable sans conditions'"
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'
                                  " :title="'Quotité Incapacité/Invalidité (%)'" class="ml-1 cursor-pointer"
                                variant="outline-primary">
                                <feather-icon icon="InfoIcon" size="20" />
                              </span>
                            </label>
                            <validation-provider #default="{ errors }" name="incapacite-co-emprunteur"
                              rules="required|between:1,100">
                              <b-form-input id="incapacite-co-emprunteur"
                                v-model="objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.incapacite"
                                placeholder="Quotité Incapacité/Invalidité (%)" type="number"
                                :state="errors.length > 0 ? false : null" />
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                La valeur doit être comprisee entre 1 et 100
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <b-form-group>
                            <label for="franchise-co-emprunteur" class="font-weight-normal">Franchise</label>
                            <validation-provider #default="{ errors }" name="franchise-co-emprunteur" rules="required">
                              <b-form-select id="franchise-co-emprunteur"
                                v-model="objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.franchise"
                                :options="filterFranchise(false, true)" :state="errors.length > 0 ? false : null" />
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                Le choix est obligatoire
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <b-form-group v-if="showPerlEmplois.showSecondaire">
                            <label for="perte-emploi-co-emprunteur" class="font-weight-normal">Perte d'emploi</label>
                            <validation-provider #default="{ errors }" name="perte-emploi-co-emprunteur" rules="required">
                              <b-form-radio-group
                                v-model="objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.pei"
                                name="perte-emploi-co-emprunteur" :state="errors.length > 0 ? false : null">
                                <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                  {{ option.text }}
                                </b-form-radio>
                              </b-form-radio-group>

                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                Le choix est obligatoire
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="8" class="pt-2 pb-1">
                          <b-form-group>
                            <label for="mno-dospsy-co-emprunteur" class="float-left pr-3 font-weight-normal">
                              Option Dos/Psy ?
                              <span
                                v-b-popover.top.hover.html="`<p>La couverture des Maladies non objectivables sans condition (<strong>MNO</strong>) permet le rachat des exclusions sur les maladies dorso-vertèbrales et psychiques.</p>`"
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="ml-1 cursor-pointer"
                                variant="outline-primary">
                                <feather-icon icon="InfoIcon" size="20" />
                              </span>
                            </label>
                            <validation-provider #default="{ errors }" name="mno-co-emprunteur" rules="required">
                              <b-form-radio-group
                                v-model="objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.mno_dospsy"
                                name="radio-mno-dospsy-co-emprunteur" :state="errors.length > 0 ? false : null">
                                <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                  {{ option.text }}
                                </b-form-radio>
                              </b-form-radio-group>
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                Le choix est obligatoire
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="8">
                          <b-form-group>
                            <label for="exo-co-emprunteur" class="float-left pr-3 font-weight-normal">
                              Exonération des cotisations ?
                              <span
                                v-b-popover.top.hover.html="`<p>Cette option de garantie permet, sous conditions, le remboursement du paiement des cotisations en cas d’arrêt de travail (ITT ou IPT).</p>`"
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="ml-1 cursor-pointer"
                                variant="outline-primary">
                                <feather-icon icon="InfoIcon" size="20" />
                              </span>
                            </label>
                            <validation-provider #default="{ errors }" name="exo-co-emprunteur" rules="required">
                              <b-form-radio-group
                                v-model="objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.exo"
                                name="radio-cepp" :state="errors.length > 0 ? false : null">
                                <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                  {{ option.text }}
                                </b-form-radio>
                              </b-form-radio-group>
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                Le choix est obligatoire
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </template>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
              <b-col v-if="objetEmprunteur.emprunteur.has_co_emprunteur && objetEmprunteur.has_pret_secondaire" md="6">
                <i class="title-custom-wizard d-block mb-2"><b>Prêt secondaire / Co-assuré</b></i>
                <b-row>
                  <b-col md="12">
                    <b-form-group class="mb-1">
                      <label for="garanties-differentes-co-emprunteur" class="float-left pr-4">Les garanties souhaitées
                        sont-elles identiques au prêt principal ?</label>
                      <validation-provider #default="{ errors }" name="garanties différentes co-emprunteur"
                        rules="required">
                        <b-form-radio-group
                          v-model="objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.has_same_garanties"
                          name="radio-garanties-differentes-co-emprunteur" :state="errors.length > 0 ? false : null"
                          @change="resetDataSecondaire($event, 'CO-EMPRUNTEUR')">
                          <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                            {{ option.text }}
                          </b-form-radio>
                        </b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le choix est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <template v-if="!objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.has_same_garanties">
                    <b-col md="12">
                      <b-form-group>
                        <label class="mb-1">Quel est le besoin exprimé par le co-assuré ?</label>
                        <validation-provider #default="{ errors }" name="besoin client secondaire co-emprunteur"
                          rules="required">
                          <b-form-radio-group
                            v-model="objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.besoin_client"
                            name="radio-besoin-client-secondaire-co-emprunteur" :state="errors.length > 0 ? false : null"
                            class="demo-inline-spacing" :disabled="disBcCe"
                            @change="resetValueBesoinClient($event,'pret_secondaire', 'co_emprunteur')">
                            <b-form-radio v-for="option in BesoinClientOptions" :key="option.value" :value="option.value"
                              class="mt-0">
                              <small>{{ option.text }}</small>
                              <span v-if="option.infoBull" :id="option.value + '3'"
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="ml-1 cursor-pointer"
                                variant="outline-primary">
                                <feather-icon icon="InfoIcon" size="20" />
                              </span>

                            </b-form-radio>
                          </b-form-radio-group>
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                            Le choix est obligatoire
                          </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="12" class="mt-1">
                      <b-row class="align-items-center">
                        <b-col md="4">
                          <b-form-group>
                            <label for="ptia-secondaire-co-emprunteur" class="font-weight-normal">
                              Quotité Décès/PTIA (%)
                              <span
                                v-b-popover.top.hover.html="'La quotité assurée sert à calculer le montant de la cotisation et à déterminer le taux d’indemnisation.'"
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'" :title="'Quotité'" class="ml-1 cursor-pointer"
                                variant="outline-primary">
                                <feather-icon icon="InfoIcon" size="20" />
                              </span>
                            </label>
                            <validation-provider #default="{ errors }" name="ptia-secondaire-co-emprunteur"
                              rules="required|between:1,100">
                              <b-form-input id="ptia-secondaire-co-emprunteur"
                                v-model="objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.ptia"
                                placeholder="Quotité Décès/PTIA (%)" type="number" :state="errors.length > 0 ? false : null" />
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                La valeur doit être comprisee entre 1 et 100
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="8">
                          <b-form-group>
                            <label for="supplement-deces-co-assure-secondaire"
                              class="float-left pr-4 font-weight-normal">Supplément décès accidentel</label>
                            <validation-provider #default="{ errors }" name="supplément décès co-assure secondaire"
                              rules="required">
                              <b-form-radio-group
                                v-model="objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.suppl_deces"
                                name="radio-supplement-deces-co-assure-secondaire"
                                :state="errors.length > 0 ? false : null">
                                <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                  {{ option.text }}
                                </b-form-radio>
                              </b-form-radio-group>
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                Le choix est obligatoire
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <template
                          v-if="objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.besoin_client != 'MINIMUM'">
                          <b-col md="4">
                            <b-form-group>
                              <label for="incapacite-secondaire-co-emprunteur" class="font-weight-normal">
                                Quotité Incapacité/Invalidité (%)
                                <span
                                  v-if="objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.besoin_client == 'COMPLETE' || objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.besoin_client == 'OPTIMUM'"
                                  v-b-popover.top.hover.html="objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.besoin_client == 'COMPLETE' ? 'Quotité pour les garanties d\'Invalidité Permanente Totale (IPT) et d\'Incapacité Temporaire de Travail (ITT).' : 'Quotité pour les garanties d\'Invalidité Permanente Totale (IPT),d\'Incapacité Temporaire de Travail (ITT),d\'Invalidité Permanente Partielle (IPP) et de Maladie non objectivable sans conditions'"
                                  v-ripple.400="'rgba(113, 102, 240, 0.15)'
                                    " :title="'Quotité Incapacité/Invalidité (%)'" class="ml-1 cursor-pointer"
                                  variant="outline-primary">
                                  <feather-icon icon="InfoIcon" size="20" />
                                </span>
                              </label>
                              <validation-provider #default="{ errors }" name="incapacite-secondaire-co-emprunteur"
                                rules="required|between:1,100">
                                <b-form-input id="incapacite-secondaire-co-emprunteur"
                                  v-model="objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.incapacite"
                                  placeholder="Quotité Incapacité/Invalidité (%)" type="number"
                                  :state="errors.length > 0 ? false : null" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                  La valeur doit être comprisee entre 1 et 100
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col md="4">
                            <b-form-group>
                              <label for="franchise-secondaire-co-emprunteur" class="font-weight-normal">Franchise</label>
                              <validation-provider #default="{ errors }" name="franchise-secondaire-co-emprunteur"
                                rules="required">
                                <b-form-select id="franchise-secondaire-co-emprunteur"
                                  v-model="objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.franchise"
                                  :options="filterFranchise(false, false)" :state="errors.length > 0 ? false : null" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                  Le choix est obligatoire
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col md="4">
                            <b-form-group v-if="showPerlEmplois.showSecondaire">
                              <label for="perte-emploi-co-emprunteur-secondaire" class="font-weight-normal">Perte
                                d'emploi</label>
                              <validation-provider #default="{ errors }" name="perte-emploi-co-emprunteur-secondaire"
                                rules="required">
                                <b-form-radio-group
                                  v-model="objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.pei"
                                  name="perte-emploi-co-emprunteur-secondaire" :state="errors.length > 0 ? false : null">
                                  <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                    {{ option.text }}
                                  </b-form-radio>
                                </b-form-radio-group>

                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                  Le choix est obligatoire
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col md="8" class="pt-2 pb-1">
                            <b-form-group>
                              <label for="mno-dospsy-co-emprunteur-secondaire" class="float-left pr-3 font-weight-normal">
                                Option Dos/Psy ?
                                <span
                                  v-b-popover.top.hover.html="`<p>La couverture des Maladies non objectivables sans condition (<strong>MNO</strong>) permet le rachat des exclusions sur les maladies dorso-vertèbrales et psychiques.</p>`"
                                  v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="ml-1 cursor-pointer"
                                  variant="outline-primary">
                                  <feather-icon icon="InfoIcon" size="20" />
                                </span>
                              </label>
                              <validation-provider #default="{ errors }" name="mno-co-emprunteur-secondaire"
                                rules="required">
                                <b-form-radio-group
                                  v-model="objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.mno_dospsy"
                                  name="radio-mno-dospsy-co-emprunteur-secondaire"
                                  :state="errors.length > 0 ? false : null">
                                  <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                    {{ option.text }}
                                  </b-form-radio>
                                </b-form-radio-group>
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                  Le choix est obligatoire
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col md="8">
                            <b-form-group>
                              <label for="exo-co-emprunteur-secondaire" class="float-left pr-3 font-weight-normal">
                                Exonération des cotisations ?
                                <span
                                  v-b-popover.top.hover.html="`<p>Cette option de garantie permet, sous conditions, le remboursement du paiement des cotisations en cas d’arrêt de travail (ITT ou IPT).</p>`"
                                  v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="ml-1 cursor-pointer"
                                  variant="outline-primary">
                                  <feather-icon icon="InfoIcon" size="20" />
                                </span>
                              </label>
                              <validation-provider #default="{ errors }" name="exo-co-emprunteur-secondaire"
                                rules="required">
                                <b-form-radio-group
                                  v-model="objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.exo"
                                  name="radio-ceps" :state="errors.length > 0 ? false : null">
                                  <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                    {{ option.text }}
                                  </b-form-radio>
                                </b-form-radio-group>
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                  Le choix est obligatoire
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </template>
                      </b-row>
                    </b-col>
                  </template>
                </b-row>
              </b-col>
            </b-row>
          </app-collapse-item>
          <!--  -->
          <app-collapse-item ref="tab-collapse-item-5" :is-visible="false"
            title="<h4 class='title-custom-wizard'><u>Informations complémentaires du courtier</u></h4>">
            <b-row>
              <b-col md="12">
                <b-alert v-show="true" variant="primary" show>
                  <div class="alert-body">
                    <p>
                      Merci de préciser le taux de commission souhaité pour Harmonie mutuelle.
                    </p>
                  </div>
                </b-alert>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label for="taux-commission" class="font-weight-normal">Taux de commission (en %) *</label>
                  <validation-provider #default="{ errors }" name="taux de commission" rules="required">
                    <b-form-select id="taux-commission" v-model="objetEmprunteur.taux_commission" :options="TauxeOptions"
                      :state="errors.length > 0 ? false : null" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      Le choix est obligatoire
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-collapse-item>
        </app-collapse>
      </b-form>
    </validation-observer>
    <b-row class="mt-4">
      <b-col lg="12">
        <b-button variant="primary" class="float-left" size="lg" @click="backStep">
          Précédent
        </b-button>

        <b-button variant="primary" class="float-right" size="lg" @click="getTarification">
          Suivant
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { heightTransition } from '@core/mixins/ui/transition'
import vSelect from "vue-select"
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import TheFormSpinbuttonWithInput from '@core/components/input-number/TheFormSpinbuttonWithInput.vue'
import { BFormSpinbutton } from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { required, requiredIf, minLength, maxLength, between, email } from "vuelidate/lib/validators";
import moment from 'moment'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'
import { rules } from '@core/libs/validations'
import { mapGetters } from 'vuex'

import {
  BOverlay,
  BForm,
  BFormCheckbox,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BFormRadio,
  BTabs,
  BTab,
  BFormSelect,
  BTable,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BButton,
  BCard,
  BCardText,
  BListGroup,
  BListGroupItem,
  BFormTextarea,
  BFormValidFeedback,
  BAlert,
  VBTooltip,
  VBPopover,
  BFormSelectOption,
  BPopover
} from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    ToastificationContent,
    BFormRadioGroup,
    BFormRadio,
    BTabs,
    BTab,
    flatPickr,
    BFormSelect,
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BButton,
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
    BFormTextarea,
    BFormValidFeedback,
    BOverlay,
    Cleave,
    BAlert,
    VueSlider,
    BFormSpinbutton,
    BFormCheckboxGroup,
    AppCollapse,
    AppCollapseItem,
    TheFormSpinbuttonWithInput,
    BFormSelectOption,
    BPopover
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
    Ripple,
  },
  mixins: [heightTransition],
  computed: {
    ...mapGetters(['getQWCheckedRisque']),
    hasCoEmprunteurSelected: function () {
      let coEmprunteur = null
      let parentSelected = this.parentsArray.find(p => p.state == true)
      let enfantSelected = this.enfantsArray.find(p => p.state == true)
      let amiSelected = this.amisArray.find(p => p.state == true)
      switch (this.objetEmprunteur.lien) {
        case 'CONJOINT':
          return coEmprunteur = this.conjoint;
        case 'PARENT':
          return parentSelected != undefined ? coEmprunteur = parentSelected : coEmprunteur;
        case 'ENFANT':
          return enfantSelected != undefined ? coEmprunteur = enfantSelected : coEmprunteur;
        case 'AMI':
          return amiSelected != undefined ? coEmprunteur = amiSelected : coEmprunteur;
        default:
          return coEmprunteur;
      }
    },
    ShowPrincipalAddPalier() {
      return this.objetEmprunteur.prets.pret_principal.paliersArray.length >= 4;
    },
    ShowSecondaireAddPalier() {
      return this.objetEmprunteur.prets.pret_secondaire.paliersArray.length >= 4;
    },
    enfantsHasValidDateNaissance: function () {
      let enfantNovalidDate = _.filter(this.enfantsArray, enf => enf.state && (Math.floor(moment().diff(enf.date_naissance.split("/").reverse().join("-"), 'years', true)) < 18 || Math.floor(moment().diff(enf.date_naissance.split("/").reverse().join("-"), 'years', true)) > 79));
      return this.enfantsArray.length == 0 || enfantNovalidDate.length == 0 ? true : false
    },
    parentHasValidDateNaissance: function () {
      let parentNovalidDate = _.filter(this.parentsArray, parent => parent.state && (Math.floor(moment().diff(parent.date_naissance.split("/").reverse().join("-"), 'years', true)) < 18 || Math.floor(moment().diff(parent.date_naissance.split("/").reverse().join("-"), 'years', true)) > 79));
      return this.parentsArray.length == 0 || parentNovalidDate.length == 0 ? true : false
    },
    amiHasValidDateNaissance: function () {
      let amisNovalidDate = _.filter(this.amisArray, ami => ami.state && (Math.floor(moment().diff(ami.date_naissance.split("/").reverse().join("-"), 'years', true)) < 18 || Math.floor(moment().diff(ami.date_naissance.split("/").reverse().join("-"), 'years', true)) > 79));
      return this.amisArray.length == 0 || amisNovalidDate.length == 0 ? true : false
    },
  },
  watch: {
    'assure.moyen_contact.code_postal': {
      immediate: true,
      handler(val) {
        if (val && val.length === 5) {
          this.$http
            .get(`/helpers/${val}/villes`)
            .then(res => {
              if (res.data?.length > 0) {
                this.villeOptions = [...res.data]
                let obj = this.villeOptions.find(o => o.id === this.assure.moyen_contact.ville_id);
                if (typeof obj === 'undefined' || obj === null) {
                  this.assure.moyen_contact.ville_id = this.initialState().assure.moyen_contact.ville_id
                }
              }
            })
            .catch(err => {
              console.log(err)
            })
        } else {
          this.villeOptions = []
        }
      }
    },
    'conjoint.moyen_contact.code_postal': {
      immediate: true,
      handler(val) {
        this.getVilleByCodePostal(val, -1, 'CONJOINT')
      }
    },
    'objetEmprunteur.emprunteur.profession': {
      handler(val) {
        this.checkProfession(val, 'EMPRUNTEUR')
      }
    },
    'objetEmprunteur.co_emprunteur.profession': {
      handler(val) {
        this.checkProfession(val, 'CO-EMPRUNTEUR')
      }
    },
    getQWCheckedRisque: {
      immediate: true,
      deep: true,
      handler(val) {
        console.log('getQWCheckedRisque 1')
        if (val) {
          console.log('getQWCheckedRisque 2')

          this.getProduits(val)
        }
      },
    },
  },
  data() {
    return this.initialState();
  },
  mounted() {
    setTimeout(() => {
      this.$refs.AssureRules.reset()
    }, 1000);
  },
  methods: {
    initialState() {
      return {
        produit: {
          produit_id: '',
          risque_id: '',
          produit_nom: '',
          nom_assureur: '',
          nom_com_assureur: ''
        },
        showPerlEmplois: {
          showPrincipal: false,
          showSecondaire: false,
        },
        lienPrincipale: null,
        disBc: false,
        disBcCe: false,
        produit: [],
        errorsGaranties: [],
        errorsTarif: [],
        enfantsArray: [],
        parentsArray: [],
        amisArray: [],
        enfantsLocal: [],
        parentsLocal: [],
        enfantStore: [],
        parentStore: [],
        amiStore: [],
        villeOptions: [],
        villeOptionsCoEmprunteur: [],
        pays: [],
        options: {
          codePostal: {
            blocks: [5]
          },
          number: {
            numeral: true
          },
        },
        errorDateNaissanceProspect: null,
        configDateEffet: {
          dateFormat: 'd/m/Y',
          disable: [function (date) {
            let localyDayHours = new Date().setHours(0, 0, 0, 0)
            let localDay = new Date(localyDayHours);
            return date < localDay
          }, function (date) {
            let localyDayHours = new Date().setHours(0, 0, 0, 0)
            let localDayThreeMonths = new Date(new Date(localyDayHours).setMonth(new Date(localyDayHours).getMonth() + 6));
            return date > localDayThreeMonths
          }],
          allowInput: true,
          onKeyDown: (selectedDates, dateStr, instance, event) => {
            if (event.keyCode != 8) {
              var ele = document.activeElement;
              var val = ele.value;

              if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                val += "/";
              }
              ele.value = val;
              ele.dispatchEvent(new Event("input"));
            }
          },
        },
        configDate: {
          dateFormat: 'd/m/Y',
          allowInput: true,
          onKeyDown: (selectedDates, dateStr, instance, event) => {
            if (event.keyCode != 8) {
              var ele = document.activeElement
              var val = ele.value

              if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                val += '/'
              }
              ele.value = val
              ele.dispatchEvent(new Event('input'))
            }
          }
        },
        enfant: {
          id: null,
          civilite: null,
          situation_familiale: null,
          statut: null,
          pays_naissance: null,
          date_naissance: null,
          state: false,
          type_lien_fam: 'ENFANT',
          statut_gda: null,
          isPPE: null,
          moyen_contact: {
            code_postal: null,
            ville_id: null
          }
        },
        parent: {
          id: null,
          civilite: null,
          situation_familiale: null,
          pays_naissance: null,
          statut: null,
          date_naissance: null,
          state: false,
          type_lien_fam: 'PARENT',
          statut_gda: null,
          isPPE: null,
          moyen_contact: {
            code_postal: null,
            ville_id: null
          }
        },
        ami: {
          id: null,
          civilite: null,
          situation_familiale: null,
          pays_naissance: null,
          statut: null,
          date_naissance: null,
          state: false,
          type_lien_fam: 'AMI',
          statut_gda: null,
          isPPE: null,
          moyen_contact: {
            code_postal: null,
            ville_id: null
          }
        },
        conjoint: {
          id: null,
          date_naissance: null,
          statut: null,
          pays_naissance: null,
          civilite: null,
          num_secu: null,
          numeroOrganisme: null,
          type_lien_fam: 'CONJOINT',
          situation_familiale: null,
          statut_gda: null,
          isPPE: null,
          moyen_contact: {
            code_postal: null,
            ville_id: null
          }
        },
        palier: {
          id: null,
          mensualite_palier: null,
          duree_palier: null
        },
        garanties: [],
        exigences: [],
        objetEmprunteur: {
          lien: null,
          has_pret_secondaire: 0,
          emprunteur: {
            type_assure: 'EMPRUNTEUR',
            en_conge: null,
            fumeur: 0,
            is_contrat_temps_plein: 1,
            deplacement_pro: 'MOINS_200K',
            activite_manuel_regulier: 0,
            travail_hauteur: 0,
            en_cours: 0,
            profession: null,
            has_co_emprunteur: 0,
            precision_profession: null,
          },
          co_emprunteur: {
            type_assure: 'CO_EMPRUNTEUR',
            en_conge: null,
            fumeur: 0,
            is_contrat_temps_plein: 1,
            deplacement_pro: 'MOINS_200K',
            activite_manuel_regulier: 0,
            travail_hauteur: 0,
            en_cours: 0,
            profession: null,
            precision_profession: null,
            isPPE: null, // traitement back
          },
          prets: {
            type_demande: null,
            type_formule: null,
            pret_principal: {
              date_effet: null,
              avance_projet: null,
              date_signature: null,
              type: null,
              nature: null,
              objet: null,
              has_pret_palier: null,
              taux_interet: null,
              type_taux: null,
              capital: null,
              duree_pret: null,
              has_differe: null,
              duree_differe: null,
              paliersArray: [],
              garanties: {
                emprunteur: {
                  besoin_client: null,
                  ptia: null,
                  incapacite: null,
                  franchise: null,
                  suppl_deces: null,
                  pei: null,
                  mno_dospsy: null,
                  exo: null
                },
                co_emprunteur: {
                  besoin_client: null,
                  ptia: null,
                  incapacite: null,
                  franchise: null,
                  suppl_deces: null,
                  pei: null,
                  mno_dospsy: null,
                  exo: null
                }
              }
            },
            pret_secondaire: {
              has_diff_date: null,
              date_effet: null,
              avance_projet: null,
              date_signature: null,
              type: null,
              nature: null,
              objet: null,
              has_pret_palier: null,
              taux_interet: null,
              type_taux: null,
              capital: null,
              duree_pret: null,
              has_differe: null,
              duree_differe: null,
              paliersArray: [],
              garanties: {
                emprunteur: {
                  has_same_garanties: 0,
                  besoin_client: null,
                  ptia: null,
                  incapacite: null,
                  franchise: null,
                  suppl_deces: null,
                  pei: null,
                  mno_dospsy: null,
                  exo: null
                },
                co_emprunteur: {
                  has_same_garanties: 0,
                  besoin_client: null,
                  ptia: null,
                  incapacite: null,
                  franchise: null,
                  suppl_deces: null,
                  pei: null,
                  mno_dospsy: null,
                  exo: null
                }
              }
            },
          }
        },
        assure: {
          moyen_contact: {
            code_postal: null,
            ville_id: null
          }
        },
        civilite: [
          {
            value: null,
            text: 'Choisissez'
          },
          {
            value: 'MR',
            text: 'M.'
          },
          {
            value: 'MME',
            text: 'Mme'
          }
        ],
        LienOptions: [
          {
            value: null,
            text: 'Choisissez',

          },
          {
            value: 'CONJOINT',
            text: 'Conjoint'
          },
          {
            value: 'ENFANT',
            text: 'Enfant'
          },
          {
            value: 'PARENT',
            text: 'Parent'
          },
          {
            value: 'AMI',
            text: 'Ami'
          }
        ],
        SituationFamilialeOptions: [
          {
            value: null,
            text: 'Choisissez'
          },
          {
            text: 'Concubin(e)',
            value: 'CONCUBIN'
          },
          {
            text: 'Célibataire',
            value: 'CELIBATAIRE'
          },
          {
            text: 'Divorcé(e)',
            value: 'DIVORCE'
          },
          {
            text: 'Marié(e)',
            value: 'MARIE'
          },
          {
            text: 'Séparé(e)',
            value: 'SEPARE'
          },
          {
            text: 'Veuf(ve)',
            value: 'VEUF'
          },
          {
            text: 'Pacsé(e)',
            value: 'PACSE'
          }
        ],
        ImpactDiffereOptions: [{
          value: null,
          text: 'Choisissez',
        },
        {
          value: 'CAPITAL',
          text: 'Capital',
        },
        {
          value: 'CAPITAL_INTERET',
          text: 'Capital + intérêt',
        }
        ],
        optionsVenteDistance: [
          { text: 'Oui', value: 1 },
          { text: 'Non', value: 0 }
        ],
        optionsTypeVente: [
          {
            text: 'Vente à distance en 1 temps',
            value: 'optin',
            infoBulle: 'Prospect ayant préalablement consenti à l\'appel ou client.'
          },
          {
            text: 'Vente à distance en 2 temps',
            value: 'optout',
            infoBulle: 'Prospect n\'ayant pas consenti à l\'appel. Un délai de 24h s\'appliquera avant la mise en place de la signature électronique.'
          }
        ],
        optionsTaux: [
          { text: 'Fixe', value: 'FIXE' },
          { text: 'Variable', value: 'VARIABLE' }
        ],
        BooleanValue: [
          {
            value: 1,
            text: "Oui"
          },
          {
            value: 0,
            text: "Non"
          },
        ],
        ActiviteProfessionnelle: [
          {
            value: null,
            text: 'Choisissez'
          },
          {
            value: "AGRICULTEUR",
            text: "Agriculteur"
          },
          {
            value: "ARTISAN",
            text: "Artisan"
          },
          {
            value: "CHEF_ENTREPRISE",
            text: "Chef d'entreprise"
          },
          {
            value: "COMMERCANT",
            text: "Commerçant"
          },
          {
            value: "ETUDIANT",
            text: "Etudiant"
          },
          {
            value: "FONCTIONNAIRE_ASSIMILE",
            text: "Fonctionnaire ou assimilé"
          },
          {
            value: "PROFESSION_LIBERALE",
            text: "Profession libérale"
          },
          {
            value: "RETRAITE",
            text: "Retraité"
          },
          {
            value: "SALARIE_NON_CADRE",
            text: "Salarié non cadre"
          },
          {
            value: "SALARIE_CADRE",
            text: "Salarié cadre"
          },
          {
            value: "SANS_EMPLOI",
            text: "Sans emploi"
          },
        ],
        TypePrecisionOptions: {
          SANS_EMPLOI: [{
            value: null,
            text: 'Choisissez'
          }, {
            value: "CHOMEURS_INDEMNISES",
            text: "Chômeurs indemnisés"
          },
          {
            value: "CHOMEURS_NON_INDEMNISES",
            text: "Chômeurs non indemnisés"
          }],
          PROFESSION_LIBERALE: [{
            value: null,
            text: 'Choisissez'
          }, {
            value: "PROFESSION_LIBERALE_MEDICAL",
            text: "Profession libérale médical/paramed"
          },
          {
            value: "PROFESSION_LIBERALE_HORS_MED",
            text: "Profession libérale hors med/paramed"
          }],
          FONCTIONNAIRE_ASSIMILE: [{
            value: null,
            text: 'Choisissez'
          }, {
            value: "CATEGORIE_A",
            text: "Catégorie A"
          }, {
            value: "CATEGORIE_B_OU_C",
            text: "Catégorie B ou C"
          }],
        },
        TypeAssure: [
          {
            value: "EMPRUNTEUR",
            text: "Emprunteur"
          },
          {
            value: "CO_EMPRUNTEUR",
            text: "Co-emprunteur"
          },
          {
            value: "CAUTION",
            text: "Caution"
          }
        ],
        TypesPrincipal: [
          {
            value: null,
            text: 'Choisissez'
          },
          {
            value: "AMORTISSABLE",
            text: "Amortissable"
          },
          {
            value: "IN_FINE",
            text: "In Fine"
          },
          {
            value: "RELAIS",
            text: "Relais"
          }
        ],
        NaturesPrincipal: [
          {
            value: null,
            text: 'Choisissez'
          },
          {
            value: "PRET_IMMOBILIER",
            text: "Prêt Immobilier"
          },
          {
            value: "PRET_PERSONNEL",
            text: "Prêt Personnel"
          },
          {
            value: "PRET_PROFESSIONNEL",
            text: "Prêt Professionnel"
          }
        ],
        ObjetPrincipalOptions: [
          {
            value: null,
            text: 'Choisissez'
          },
          {
            value: "RESIDENCE_PRINCIPALE",
            text: "Résidence Principale"
          },
          {
            value: "RESIDENCE_SECONDAIRE",
            text: "Résidence Secondaire"
          },
          {
            value: "IVESTISSEMENT_LOCATIF",
            text: "Ivestissement Locatif"
          },
          {
            value: "TRAVAUX",
            text: "Travaux"
          },
          {
            value: "CONSOMMATION",
            text: "Consommation"
          },
          {
            value: "PRET_HYPOTHECAIRE",
            text: "Prêt hypothécaire"
          },
        ],
        AvanceProjetOptions: [
          {
            value: null,
            text: 'Choisissez'
          },
          {
            value: "COMROMIS_SIGNE",
            text: "Compromis signé"
          },
          {
            value: "NEGOCIATION_EN_COURS",
            text: "Négociation en cours"
          },
          {
            value: "DEMANDE_INFORMATION",
            text: "Demande d'information"
          }
        ],
        TauxeOptions: [{
          value: null,
          text: 'Choisissez',
        },
        {
          value: '10',
          text: '10',
        },
        {
          value: '20',
          text: '20',
        },
        {
          value: '30',
          text: '30',
        }],
        TypeFormuleOptions: [
          {
            value: null,
            text: 'Choisissez'
          },
          {
            value: "CI",
            text: "Capital Initial (CI)"
          },
          {
            value: "CRD",
            text: "Capital Restant Dû (CRD)"
          }
        ],
        TypeDemandeOptions: [
          {
            value: "DELIAISON_ASSURANCE",
            text: "Déliaison",
            infoBulle: {
              title: "Déliaison pour un prêt à venir",
              description: "L'assuré est en train de faire un prêt, et  ne souhaite pas souscrire le contrat d'assurance proposé par le prêteur."
            }
          },
          {
            value: "SUBSTITUTION",
            text: "Substitution",
            infoBulle: {
              title: "Substitution pour un prêt déjà signé",
              description: "L'assuré a déjà signé une offre de prêt et  souhaite changer d'assurance."
            }
          }
        ],
        DeplacementeProfessionnelOptions: [
          {
            value: null,
            text: 'Choisissez'
          },
          {
            value: "MOINS_200K",
            text: "Moins de 20 000 km"
          },
          {
            value: "PLUS_200K",
            text: "20 000 km ou plus"
          }],
        FranchiseOptions: [
          {
            value: null,
            text: 'Choisissez'
          },
          {
            value: "0",
            text: "0"
          },
          {
            value: "30",
            text: "30"
          },
          {
            value: "60",
            text: "60"
          },
          {
            value: "90",
            text: "90"
          },
          {
            value: "120",
            text: "120"
          },
          {
            value: "180",
            text: "180"
          }],
        BesoinClientOptions: [
          {
            value: 'MINIMUM',
            text: 'Minimum (Décès/PTIA)',
            infoBull: {
              title: 'Minimum (Décès/PTIA)',
              description: "<ul><li><p>Minimum (Décès/PTIA) :</p><ul><li><p>La garantie décès est la garantie minimale de tout contrat d'assurance emprunteur. En cas de décès de l'assuré, l'assureur remboursera à l'organisme prêteur le capital restant dû à hauteur de la quotité assurée.</p></li><li><p>La perte totale et irréversible d’autonomie (<strong>PTIA</strong>) assure le remboursement lorsque l’assuré est dans l’incapacité définitive d’exercer toute activité rémunératrice et dans l’obligation d'être assisté par une tierce personne pour l’accomplissement de tous les actes de la vie quotidienne.</p></li></ul></li></ul>",
            },
          },
          {
            value: 'COMPLETE',
            text: 'Complète (Minimum + IPT/ITT)',
            infoBull: {
              title: 'Complète (Minimum + IPT/ITT)',
              description: "<ul><li><p>Complète (Minimum + IPT/ITT) : Outre les garanties minimum décès et PTIA :</p><ul><li><p>La garantie d'Invalidité Permanente Totale (<strong>IPT</strong>) assure le remboursement du capital restant dû lorsqu'un taux d'invalidité supérieur à 66 % a été reconnu.</p></li><li><p>La garantie d'Incapacité Temporaire de Travail (<strong>ITT</strong>) fonctionne en cas d'arrêt de travail prolongé, dépassant généralement une période selon la franchise (90 jours généralement).</p></li></ul></li></ul>",
            },
          }, {
            value: 'OPTIMUM',
            text: 'Optimum (Complète + IPP)',
            infoBull: {
              title: 'Optimum (Complète + IPP)',
              description: "<ul><li><p>Optimum (Complète + IPP) : Outre les garanties décès/PTIA et IPT/ITT :</p><ul><li><p>La garantie d'Invalidité Permanente Partielle (<strong>IPP</strong>) assure le remboursement d'une partie des échéances du prêt, dès lors qu'un taux d'invalidité supérieur à 33 % et inférieur à 66% a été reconnu.</p></li></ul></li></ul>",
            },
          }],
        showLoading: false,
        devis: null,
      }

    },
    filterFranchise(isAssure, isPretPrincipale) {

      // Determine which data to use based on the isAssure flag
      const assureData = isAssure ? this.assure : this.hasCoEmprunteurSelected ? this.hasCoEmprunteurSelected : null
      const emprunteurData = isAssure ? this.objetEmprunteur.emprunteur : this.objetEmprunteur.co_emprunteur

      // Determine which pret data to use based on the isPretPrincipale flag
      const pretData = isPretPrincipale ? this.objetEmprunteur.prets.pret_principal : this.objetEmprunteur.prets.pret_secondaire
      if (!assureData || !pretData) {
        return [{ value: null, text: 'Choisissez' }, { value: '30', text: '30' }, { value: '60', text: '60' }, { value: '90', text: '90' }, { value: '120', text: '120' }, { value: '180', text: '180' }]
      }
      // Calculate the "date fin de prêt" based on the type of demand
      const dateFinPret = this.calculateDateFinPret(this.objetEmprunteur.prets.type_demande, pretData, isPretPrincipale)

      // Calculate the difference between "date fin de prêt" and "date de naissance"
      const startDate = moment(dateFinPret, "DD/MM/YYYY").startOf("day")
      const dateDeNaissance = moment(assureData.date_naissance, "DD/MM/YYYY").startOf("day")
      const differenceInYears = moment.duration(startDate.diff(dateDeNaissance)).asYears()

      if (!dateFinPret) {
        return [{ value: null, text: 'Choisissez' }, { value: '30', text: '30' }, { value: '60', text: '60' }, { value: '90', text: '90' }, { value: '120', text: '120' }, { value: '180', text: '180' }]
      }

      if (!emprunteurData.en_cours && differenceInYears < 60) {
        return [{ value: null, text: 'Choisissez' }, { value: '90', text: '90' }, { value: '120', text: '120' }, { value: '180', text: '180' }]
      }

      return [{ value: null, text: 'Choisissez' }, { value: '30', text: '30' }, { value: '60', text: '60' }, { value: '90', text: '90' }, { value: '120', text: '120' }, { value: '180', text: '180' }]
    },
    calculateDateFinPret(typeDemande, pretData, isPretPrincipale) {
      let endDate;
      let startDate;
      const dureePret = pretData.duree_pret;
      if (typeDemande === "DELIAISON_ASSURANCE") {
        if (isPretPrincipale || (!isPretPrincipale && pretData.has_diff_date)) {
          startDate = moment(pretData.date_effet, "DD/MM/YYYY");
        }
        startDate = moment(this.objetEmprunteur.prets.pret_principal.date_effet, "DD/MM/YYYY");

      } else if (typeDemande === "SUBSTITUTION") {
        startDate = moment(pretData.date_signature, "DD/MM/YYYY");
      }
      endDate = startDate ? startDate.add(dureePret, 'months') : null;
      return endDate ? endDate.format("DD/MM/YYYY") : null;
    },

    clearFranchise() {
      this.objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.franchise = null
      this.objetEmprunteur.prets.pret_principal.garanties.emprunteur.franchise = null
      this.objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.franchise = null
      this.objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.franchise = null
    },
    showPei(val, TypeAssure, date_naissance) {
      console.log(val)
      if (TypeAssure == "co_emprunteur") {
        console.log(Math.floor(moment().diff(date_naissance.split("/").reverse().join("-"), 'years', true)))
        if ( (val == "SALARIE_NON_CADRE" || val == "SALARIE_CADRE" ) && Math.floor(moment().diff(date_naissance.split("/").reverse().join("-"), 'years', true)) < 60) {
          console.log(val)
          this.showPerlEmplois.showSecondaire = true
          console.log(this.showPerlEmplois.showSecondaire)
        }
        else {
          this.showPerlEmplois.showSecondaire = false
        }

      }
      else if (TypeAssure == "emprunteur") {
        if ((val == "SALARIE_NON_CADRE" || val == "SALARIE_CADRE" ) &&  Math.floor(moment().diff(date_naissance.split("/").reverse().join("-"), 'years', true)) < 60) {
          this.showPerlEmplois.showPrincipal = true
        }
        else {
          this.showPerlEmplois.showPrincipal = false
        }
      }

    },
    initPrecision(val, TypeAssure, date_naissance, checkChange = true) {
      if (val != "SANS_EMPLOI" && val != "PROFESSION_LIBERALE" && val != "FONCTIONNAIRE_ASSIMILE")
        this.objetEmprunteur[TypeAssure].precision_profession = val
      else this.objetEmprunteur[TypeAssure].precision_profession = null

      if (checkChange)
        this.showPei(val, TypeAssure, date_naissance)


      // console.log('emprunteur: ',this.objetEmprunteur.emprunteur.precision_profession);
      // console.log('co_emprunteur: ',this.objetEmprunteur.co_emprunteur.precision_profession);
    },
    async getTarification() {
      try {
        this.errorsTarif = []
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })

        if ((this.objetEmprunteur.prets.pret_principal.has_pret_palier && this.objetEmprunteur.prets.pret_principal.paliersArray.length == 0) || (this.objetEmprunteur.prets.pret_secondaire.has_pret_palier && this.objetEmprunteur.prets.pret_secondaire.paliersArray.length == 0)) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Vous devez insérer au moins un palier',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          this.showLoading = false
          return 0
        }

        if (!this.amiHasValidDateNaissance && this.objetEmprunteur.lien == 'AMI') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'L\'âge de amis doit être comprise entre 18 et 79 ans',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          this.showLoading = false
          return 0
        }

        if (!this.parentHasValidDateNaissance && this.objetEmprunteur.lien == 'PARENT') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'L\'âge de parents doit être comprise entre 18 et 79 ans',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          this.showLoading = false
          return 0
        }

        if (!this.enfantsHasValidDateNaissance && this.objetEmprunteur.lien == 'ENFANT') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'L\'âge d\'enfants doit être comprise entre 18 et 79 ans',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          this.showLoading = false
          return 0
        }


        if (this.conjoint.date_naissance && this.objetEmprunteur.lien == 'CONJOINT' && (Math.floor(moment().diff(moment(this.conjoint.date_naissance, 'DD-MM-YYYY'), 'years', true)) < 18 || Math.floor(moment().diff(moment(this.conjoint.date_naissance, 'DD-MM-YYYY'), 'years', true)) > 79)) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'L\'âge de conjoint doit être comprise entre 18 et 79 ans',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          return 0
        }

        if (this.assure.date_naissance && (Math.floor(moment().diff(moment(this.assure.date_naissance, 'DD-MM-YYYY'), 'years', true)) < 18 || Math.floor(moment().diff(moment(this.assure.date_naissance, 'DD-MM-YYYY'), 'years', true)) > 79)) {
          this.errorDateNaissanceProspect = 'L\'âge de l’assuré principal doit être comprise entre 18 et 79 ans'
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'L\'âge de l’assuré principal doit être comprise entre 18 et 79 ans',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          return 0
        }

        if (this.objetEmprunteur.emprunteur.has_co_emprunteur && this.objetEmprunteur.lien) {
          if (!this.hasCoEmprunteurSelected) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Co-assuré est obligatoire',
                icon: 'XIcon',
                variant: 'danger',
              },
            })
            return 0
          }
        }

        const isValid = await this.$refs.AssureRules.validate()
        if (!isValid || !this.checkValidateGaranties()) {
          this.checkCollapseHasErrors()
          this.showLoading = false
          return 0
        }
        this.showLoading = true
        let lienPrincipale = null;
        if (this.objetEmprunteur.lien == 'CONJOINT') {
          this.lienPrincipale = {
            lien: this.objetEmprunteur.lien,
            civilite: this.conjoint.civilite,
            date_naissance: this.conjoint.date_naissance,
            moyen_contact: {
              code_postal: this.conjoint.moyen_contact.code_postal,
              ville_id: this.conjoint.moyen_contact.ville_id,
            },
            statut: this.conjoint.statut
          }
        }
        else if (this.objetEmprunteur.lien == 'ENFANT') {
          let checkedChild = this.enfantsArray.filter((child) => child.state == true);
          this.lienPrincipale = {
            lien: this.objetEmprunteur.lien,
            civilite: checkedChild[0].civilite,
            date_naissance: checkedChild[0].date_naissance,
            moyen_contact: {
              code_postal: checkedChild[0].moyen_contact.code_postal,
              ville_id: checkedChild[0].moyen_contact.ville_id,
            },
            statut: checkedChild[0].statut
          }
        } else if (this.objetEmprunteur.lien == 'AMI') {
          let checkedFriend = this.amisArray.filter((friend) => friend.state == true);
          console.log("lienPrincipale : ", lienPrincipale)

          this.lienPrincipale = {
            lien: this.objetEmprunteur.lien,
            civilite: checkedFriend[0].civilite,
            date_naissance: checkedFriend[0].date_naissance,
            moyen_contact: {
              code_postal: checkedFriend[0].moyen_contact.code_postal,
              ville_id: checkedFriend[0].moyen_contact.ville_id,
            },
            statut: checkedFriend[0].statut
          }
        } else if (this.objetEmprunteur.lien == 'PARENT') {
          let checkedParent = this.parentsArray.filter((parent) => parent.state == true);

          this.lienPrincipale = {
            lien: this.objetEmprunteur.lien,
            civilite: checkedParent[0].civilite,
            date_naissance: checkedParent[0].date_naissance,
            moyen_contact: {

              code_postal: checkedParent[0].moyen_contact.code_postal,
              ville_id: checkedParent[0].moyen_contact.ville_id,
            },
            statut: checkedParent[0].statut
          }
        }
        let data = {
          objetEmprunteur: this.objetEmprunteur,
          assure: this.assure,
          lienPrincipale: this.lienPrincipale
        }
        console.log("data : ", data)

        await this.$http.post('quickwins/calculTarifEmprunteur', data).then(response => {
          this.errorsTarif = []
          let hasErrorsProduit = false
          let errors = []
          if (Array.isArray(response.data)) {
            response.data.every(pr => {
              if (pr.hasOwnProperty('errors')) {
                errors = errors.concat(pr.errors)
                return hasErrorsProduit = true
              } else {
                errors = []
                return hasErrorsProduit = false
              }
            })
          }
          if (hasErrorsProduit) {



            this.errorsTarif = this.filterErrorsTarif(errors)
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            })
          }

          if (this.errorsTarif.length <= 0) {
            this.$store.commit('setQWObjet', { objet: this.objetEmprunteur })
            this.$store.commit('setQWProspect', { prospect: this.assure })
            this.$store.commit("setQWConjoint", { conjoint: this.conjoint });
            this.$store.commit("setQWEnfants", { enfants: this.enfantsArray });
            this.$store.commit('setQWAmis', { amis: this.amisArray })
            this.$store.commit('setQWParents', { parents: this.parentsArray })
            this.$store.commit('setQWItemsRisque', { itemsRisque: response.data })
            this.$store.commit('setQWStepTarificateur', { step: { indexStep: 2, active: 'comparatif' } })
          }
          this.showLoading = false
        })
          .catch(() => {
            this.showLoading = false
          })
        this.showLoading = false
      } catch (e) {
        this.showLoading = false
        console.error('error : ' + e.message)
        this.errors = e.response.data.errors
      }
    },
    getVilleByCodePostal(codePostal, idConnexite = null, typeConnexite = null) {
      if (codePostal && codePostal.length === 5) {
        this.$http
          .get(`/helpers/${codePostal}/villes`)
          .then(res => {
            if (res.data?.length > 0) {
              this.villeOptionsCoEmprunteur = [...res.data]
              if (idConnexite && typeConnexite) {
                let obj = this.villeOptionsCoEmprunteur.find((o) => {
                  if (typeConnexite == 'CONJOINT')
                    return o.id === this.conjoint.moyen_contact.ville_id
                  else if (typeConnexite == 'ENFANT')
                    return o.id === this.enfantsArray[idConnexite].moyen_contact.ville_id
                  else if (typeConnexite == 'PARENT')
                    return o.id === this.parentsArray[idConnexite].moyen_contact.ville_id
                  else if (typeConnexite == 'AMI')
                    return o.id === this.amisArray[idConnexite].moyen_contact.ville_id
                });
                if (typeof obj === 'undefined' || obj === null) {
                  if (typeConnexite == 'CONJOINT') {
                    this.conjoint.moyen_contact.ville_id = null
                  } else if (typeConnexite == 'ENFANT') {
                    this.enfantsArray[idConnexite].moyen_contact.ville_id = null
                  } else if (typeConnexite == 'PARENT') {
                    this.parentsArray[idConnexite].moyen_contact.ville_id = null
                  } else if (typeConnexite == 'AMI') {
                    this.amisArray[idConnexite].moyen_contact.ville_id = null
                  }
                }
              }
            }
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        this.villeOptionsCoEmprunteur = []
      }
    },
    resetDataPretPalier(val, type_pret) {
      if (!val) {
        if (type_pret == 'PRINCIPAL') {
          this.objetEmprunteur.prets.pret_principal.paliersArray = []
        } else if (type_pret == 'SECONDAIRE') {
          this.objetEmprunteur.prets.pret_secondaire.paliersArray = []
        }
      }
    },
    resetDataDiffere(val, type_pret) {
      if (!val) {
        if (type_pret == 'PRINCIPAL') {
          this.objetEmprunteur.prets.pret_principal.duree_differe = null
        } else if (type_pret == 'SECONDAIRE') {
          this.objetEmprunteur.prets.pret_secondaire.duree_differe = null
        }
      }
    },
    resetDataPretSec(val) {
      if (!val) {
        this.objetEmprunteur.prets.pret_secondaire = this.initialState().objetEmprunteur.prets.pret_secondaire
      }
    },
    resetDataSecondaire(val, typeEmp) {
      let resetData = null
      switch (typeEmp) {
        case 'EMPRUNTEUR':
          resetData = this.initialState().objetEmprunteur.prets.pret_secondaire.garanties.emprunteur
          resetData.has_same_garanties = val
          this.objetEmprunteur.prets.pret_secondaire.garanties.emprunteur = resetData
          break;
        case 'CO-EMPRUNTEUR':
          resetData = this.initialState().objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur
          resetData.has_same_garanties = val
          this.objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur = resetData
          break;
        default:
          break
      }
    },
    resetValueBesoinClient(value, typePret, TypeAssure) {
      if (value == 'MINIMUM' && typePret && TypeAssure) {
            this.objetEmprunteur.prets[typePret].garanties[TypeAssure].incapacite = null
            this.objetEmprunteur.prets[typePret].garanties[TypeAssure].franchise = null
            this.objetEmprunteur.prets[typePret].garanties[TypeAssure].pei = null
            this.objetEmprunteur.prets[typePret].garanties[TypeAssure].mno_dospsy = null
            this.objetEmprunteur.prets[typePret].garanties[TypeAssure].exo = null
        // switch (typePret) {
        //   case 'EMPRUNTEUR-PP':
        //     this.objetEmprunteur.prets[typePret].garanties[TypeAssure].incapacite = null
        //     this.objetEmprunteur.prets[typePret].garanties[TypeAssure].franchise = null
        //     this.objetEmprunteur.prets[typePret].garanties[TypeAssure].pei = null
        //     this.objetEmprunteur.prets[typePret].garanties[TypeAssure].mno_dospsy = null
        //     this.objetEmprunteur.prets[typePret].garanties[TypeAssure].exo = null
        //     break
        //   case 'EMPRUNTEUR-PS':
        //     this.objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.incapacite = null
        //     this.objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.franchise = null
        //     this.objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.pei = null
        //     this.objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.mno_dospsy = null
        //     this.objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.exo = null
        //     break
        //   case 'CO-EMPRUNTEUR-PP':
        //     this.objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.incapacite = null
        //     this.objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.franchise = null
        //     this.objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.pei = null
        //     this.objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.mno_dospsy = null
        //     this.objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.exo = null
        //     break
        //   case 'CO-EMPRUNTEUR-PS':
        //     this.objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.incapacite = null
        //     this.objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.franchise = null
        //     this.objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.pei = null
        //     this.objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.mno_dospsy = null
        //     this.objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.exo = null
        //     break
        //   default:
        //     break
        // }
      }
      },
    checkProfession(profession, typeEmp) {
      let prof = profession ? profession.toLowerCase() : ''
      if (prof == 'retraité' || prof == 'retraite') {
        switch (typeEmp) {
          case 'EMPRUNTEUR':
            this.objetEmprunteur.prets.pret_principal.garanties.emprunteur.besoin_client = "MINIMUM"
            this.objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.besoin_client = "MINIMUM"
            this.disBc = true
            break;
          case 'CO-EMPRUNTEUR':
            this.objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.besoin_client = "MINIMUM"
            this.objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.besoin_client = "MINIMUM"
            this.disBcCe = true
            break;
        }
      } else {
        this.disBc = typeEmp == 'EMPRUNTEUR' ? false : this.disBc
        this.disBcCe = typeEmp == 'CO-EMPRUNTEUR' ? false : this.disBcCe
      }
    },
    initDataByTypeDemande(type_demande) {
      if (type_demande == 'DELIAISON_ASSURANCE') {
        this.objetEmprunteur.prets.pret_principal.date_signature = null
        this.objetEmprunteur.prets.pret_principal.capital = null
        this.objetEmprunteur.prets.pret_principal.duree_pret = null
        this.objetEmprunteur.prets.pret_secondaire.date_signature = null
        this.objetEmprunteur.prets.pret_secondaire.capital = null
        this.objetEmprunteur.prets.pret_secondaire.duree_pret = null
      } else if (type_demande == 'SUBSTITUTION') {
        this.objetEmprunteur.prets.pret_principal.avance_projet = null
        this.objetEmprunteur.prets.pret_principal.capital = null
        this.objetEmprunteur.prets.pret_principal.duree_pret = null
        this.objetEmprunteur.prets.pret_secondaire.avance_projet = null
        this.objetEmprunteur.prets.pret_secondaire.capital = null
        this.objetEmprunteur.prets.pret_secondaire.duree_pret = null
      }
    },

    initConfigDateEffet() {
      if (this.objetEmprunteur.hamon) {
        if (this.devis.type_vente && this.devis.type_vente == 'optout') {
          this.changeDisabledDate(34)
        } else {
          this.changeDisabledDate(33)
        }
      } else {
        this.changeDisabledDate(0)
      }
    },
    changeDisabledDate(day) {
      this.configDateEffet.disable = [
        function (date) {
          const today = new Date()
          today.setDate(today.getDate() + day)
          today.setHours(0, 0, 0, 0)
          return date < today
        },
        function (date) {
          const dateMaxSL = new Date()
          const dateMaxECA = new Date()
          dateMaxSL.setMonth(dateMaxSL.getMonth() + 9)
          dateMaxECA.setFullYear(dateMaxECA.getFullYear() + 1, 0, 1);
          dateMaxSL.setHours(0, 0, 0, 0)
          dateMaxECA.setHours(0, 0, 0, 0)
          const datePlusMonth = dateMaxSL <= dateMaxECA ? dateMaxSL : dateMaxECA
          return datePlusMonth < date
        }
      ]
    },
    clearError(error) {
      if (error)
        error = error.replace(/{[0-9]*}/i, '');

      return error
    },
    toggleIsSouscipteur(val) {
      if (val) {
        this.objetEmprunteur.lien = null
      }
    },
    toggleConnexite(statut, indexConnexite, type) {
      if (statut) {
        if (type == 'ENFANT') {
          this.enfantsArray.forEach((enf, index) => {
            if (index !== indexConnexite) {
              enf.state = false
            } else {
              this.objetEmprunteur.co_emprunteur.isPPE = enf.isPPE
              this.getVilleByCodePostal(enf.moyen_contact.code_postal, indexConnexite, type)
            }
          })
        } else if (type == 'PARENT') {
          this.parentsArray.forEach((par, index) => {
            if (index !== indexConnexite) {
              par.state = false
            } else {
              this.objetEmprunteur.co_emprunteur.isPPE = par.isPPE
              this.getVilleByCodePostal(par.moyen_contact.code_postal, indexConnexite, type)
            }
          })
        } else if (type == 'AMI') {
          this.amisArray.forEach((ami, index) => {
            if (index !== indexConnexite) {
              ami.state = false
            } else {
              this.objetEmprunteur.co_emprunteur.isPPE = ami.isPPE
              this.getVilleByCodePostal(ami.moyen_contact.code_postal, indexConnexite, type)
            }
          })
        }
      }
    },


    MonthFormatter(value, maxValue) {
      return (value == maxValue) ? value + ' mois et plus' : value + ' mois'
    },
    backStep() {
      this.$store.commit('setQWStepTarificateur', { step: { indexStep: 0, active: 'choix' } })
      this.$store.commit('setQWCheckedRisque', null)
      this.$store.commit('setQWHasProduitAutoriser', { hasProduitAutoriser: false })
      this.$store.commit('setProduitTarificateur', { produit: {} })
    },
    checkCollapseHasErrors() {
      for (let index = 0; index < 7; index++) {
      console.log('index : ',index)
        let hasErrors = this.$refs[`tab-collapse-item-${index}`].$el.querySelector('div.d-block.invalid-feedback, input.is-invalid')
      console.log('hasErrors : ',hasErrors)

        if (hasErrors) {
          this.$refs[`tab-collapse-item-${index}`].visible = true;
        } else this.$refs[`tab-collapse-item-${index}`].visible = false;
      }
    },
    getProduits(label) {
      this.$http
        .get(`ecaglobal/getProduitsByRisque/${label}`)
        .then(response => {
          let risque = response.data
          if (Object.keys(risque).length != 0 && risque.produits.length > 0) {
            var produit = risque.produits[0]
            this.initProduit(produit)
            this.$store.commit('setProduitTarificateur', { produit: this.produit })
          } else {
            console.log('no produit')
            this.$store.commit('setProduitTarificateur', { produit: this.initState().produit })
          }
        })
        .catch(err => { })
    },
    initProduit(produit) {
      this.produit.produit_id = produit.id
      this.produit.risque_id = produit.risque_id
      this.produit.produit_nom = produit.produit_nom
      this.produit.nom_assureur = produit.assureur.nom_assureur
      this.produit.nom_com_assureur = produit.assureur.nom_com_assureur
    },
    filterErrorsTarif(arrayErrors) {
      arrayErrors = arrayErrors.filter((value, index, self) =>
        index === self.findIndex((e) => (
          e.attribut === value.attribut && e.message === value.message
        ))
      )
      return arrayErrors
    },
    fillFranchiseList(type_assr) {
      console.log('changed!!')
      if (type_assr == 'E') {
        // if(this.objetEmprunteur.emprunteur.en_cours && ())
      }

    },
    getDateFinDePret(type_assr) {
      if (type_assr == 'P') {
        if (this.objetEmprunteur.prets.type_demande == 'DELIAISON_ASSURANCE') {
          return moment(this.objetEmprunteur.prets.pret_principal.date_effet).add(this.objetEmprunteur.prets.pret_principal.duree_pret, 'months').format('YYYY/MM/DD')
        } else {
          return moment(this.objetEmprunteur.prets.pret_principal.date_signature).add(this.objetEmprunteur.prets.pret_principal.duree_pret, 'months').format('YYYY/MM/DD')
        }
      }
    },
    addConnexite(typeConnexite) {
      if (typeConnexite == 'ENFANT')
        this.enfantsArray.push(this.initialState().enfant)
      else if (typeConnexite == 'PARENT')
        this.parentsArray.push(this.initialState().parent)
      else if (typeConnexite == 'AMI')
        this.amisArray.push(this.initialState().ami)
    },
    removeConnexite(index, typeConnexite) {
      if (typeConnexite == 'ENFANT')
        this.enfantsArray.splice(index, 1)
      else if (typeConnexite == 'PARENT')
        this.parentsArray.splice(index, 1)
      else if (typeConnexite == 'AMI')
        this.amisArray.splice(index, 1)
    },
    addPalier(type_palier) {
      switch (type_palier) {
        case "PRINCIPAL":
          if (this.objetEmprunteur.prets.pret_principal.paliersArray.length < 4)
            this.objetEmprunteur.prets.pret_principal.paliersArray.push(this.initialState().palier)
          break;
        case "SECONDAIRE":
          if (this.objetEmprunteur.prets.pret_secondaire.paliersArray.length < 4)
            this.objetEmprunteur.prets.pret_secondaire.paliersArray.push(this.initialState().palier)
          break;
        default:
          break;
      }
    },
    removePalier(index, type_palier) {
      switch (type_palier) {
        case "PRINCIPAL":
          this.objetEmprunteur.prets.pret_principal.paliersArray.splice(index, 1)
          break;
        case "SECONDAIRE":
          this.objetEmprunteur.prets.pret_secondaire.paliersArray.splice(index, 1)
          break;
        default:
          break;
      }
    },

    checkValidateGaranties() {
      this.errorsGaranties = []
      let emprunteurBcPr = this.objetEmprunteur.prets.pret_principal.garanties.emprunteur.besoin_client
      // Principal 
      let emprunteurPtiaPr = parseFloat(this.objetEmprunteur.prets.pret_principal.garanties.emprunteur.ptia ?? 0)
      let emprunteurIncapacitePr = parseFloat(this.objetEmprunteur.prets.pret_principal.garanties.emprunteur.incapacite ?? 0)
      let coEmprunteurBcPr = this.objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.besoin_client
      let coEmprunteurPtiaPr = parseFloat(this.objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.ptia ?? 0)
      let coEmprunteurIncapacitePr = parseFloat(this.objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.incapacite ?? 0)

      // Secondaire
      let emprunteurBcSe = this.objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.besoin_client
      let emprunteurPtiaSe = parseFloat(this.objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.ptia ?? 0)
      let emprunteurIncapaciteSe = parseFloat(this.objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.incapacite ?? 0)
      let coEmprunteurBcSe = this.objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.besoin_client
      let coEmprunteurPtiaSe = parseFloat(this.objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.ptia ?? 0)
      let coEmprunteurIncapaciteSe = parseFloat(this.objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.incapacite ?? 0)

      if (this.objetEmprunteur.emprunteur.has_co_emprunteur && coEmprunteurPtiaPr <= 0) {
        this.errorsGaranties.push("PTIA de prêt principal(co-emprunteur) doivent être supérieur à zéro")
      }

      // if (emprunteurPtiaPr + coEmprunteurPtiaPr != 100) {
      //   this.errorsGaranties.push("le total PTIA de prêt principal doivent être 100%")
      // }

      if (emprunteurBcPr && emprunteurBcPr != 'MINIMUM') {
        if (emprunteurIncapacitePr > emprunteurPtiaPr) {
          this.errorsGaranties.push("Incapacité/Invalidité de prét principal (empruteur) doivent être inférieur ou égale PTIA")
        }
      }

      if (coEmprunteurBcPr && coEmprunteurBcPr != 'MINIMUM') {
        if (coEmprunteurIncapacitePr > coEmprunteurPtiaPr) {
          this.errorsGaranties.push("Incapacité/Invalidité de prét principal (co-empruteur) doivent être inférieur ou égale PTIA")
        }
      }

      if (this.objetEmprunteur.has_pret_secondaire) {
        if (this.objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.has_same_garanties) {
          emprunteurBcSe = emprunteurBcPr
          emprunteurPtiaSe = emprunteurPtiaPr
          emprunteurIncapaciteSe = emprunteurIncapacitePr
        }
        if (this.objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.has_same_garanties) {
          coEmprunteurBcSe = coEmprunteurBcPr
          coEmprunteurPtiaSe = coEmprunteurPtiaPr
          coEmprunteurIncapaciteSe = coEmprunteurIncapacitePr
        }
        if (emprunteurPtiaSe <= 0 || (this.objetEmprunteur.emprunteur.has_co_emprunteur && coEmprunteurPtiaSe <= 0)) {
          this.errorsGaranties.push('Décès/PTIA de prêt secondaire (assuré, co-assuré) doivent être supérieur à zéro')
        }
        // if (emprunteurPtiaSe + coEmprunteurPtiaSe != 100) {
        //   this.errorsGaranties.push("le total PTIA de prêt secondaire doivent être 100%")
        // }

        if (emprunteurBcSe && emprunteurBcSe != 'MINIMUM') {
          if (emprunteurIncapaciteSe > emprunteurPtiaSe) {
            this.errorsGaranties.push('Incapacité/Invalidité de prét secondiare (assuré) doivent être inférieur ou égale PTIA')
          }
        }

        if (coEmprunteurBcSe && coEmprunteurBcSe != 'MINIMUM') {
          if (coEmprunteurIncapaciteSe > coEmprunteurPtiaSe) {
            this.errorsGaranties.push('Incapacité/Invalidité de prét secondiare (co-assuré) doivent être inférieur ou égale PTIA')
          }
        }

      }

      return this.errorsGaranties.length > 0 ? false : true
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-slider.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import '@core/scss/vue/pages/page-pricing.scss';

.block-cylindre .custom-checkbox .custom-control-label::before,
.block-cylindre .custom-checkbox .custom-control-label::after {
  top: 0.7rem;
}

.label-cylindre {
  margin-top: 0.7rem;
  min-width: 125px;
}

.title-custom-wizard {
  color: #4d25bc;
  margin-bottom: 0px;
}

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}

.min-w-150 {
  min-width: 150px
}

#collapse-besoin .card:has(div.d-block.invalid-feedback, input.is-invalid) span h4 {
  color: #ea5455;
}

.w-80px {
  width: 80px !important;
}

.w-100px {
  width: 100px !important;
}
</style>

<style lang="scss" scoped>
label {
  font-weight: 900;
}

.w-125 {
  width: 125px;
}

.w-190 {
  width: 190px !important;
}

.h-40p {
  height: 40px;
}

@media only screen and (min-width: 700px) {
  #lg-popover .popover {
    width: 600px;
    max-width: none; // Required for popovers wider than 276px (Bootstrap's max-width for popovers)
  }
}
</style>
