<template>
  <div style="margin-top: 30px">
    <b-overlay :show="showLoading" no-wrap />
    <validation-observer ref="AssureRules">
      <b-form>
        <app-collapse id="collapse-besoin" type="margin">
          <b-row>
            <b-col md="12">
              <b-alert variant="danger" show v-show="errorsTarif.length > 0" class="mt-1">
                <div class="alert-body">
                  <ul>
                    <li v-for="(error, index) in errorsTarif" :key="index">
                      <strong v-if="error.attribut != 'ERREUR_CRM'">{{ error.attribut }} :</strong>
                      {{ error.message }}
                    </li>
                  </ul>
                </div>
              </b-alert>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <b-form-group label="Date d'effet " label-for="date-effet">
                <validation-provider #default="{ errors }" name="date d'effet" rules="required" ref="dateEffet">
                  <flat-pickr class="form-control" :class="errors.length > 0 ? 'is-invalid' : ''" v-model="conducteur.date_effet" id="date-effet" :config="configDateEffet" placeholder="Date d'effet" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Le véhicule -->
          <app-collapse-item ref="tab-collapse-item-0" :is-visible="true" title="<h4 class='title-custom-wizard'><u>Le véhicule</u></h4>">
            <b-row>
              <b-col md="12">
                <b-row>
                  <b-col md="4">
                    <validation-provider #default="{ errors }" name="modèle" :rules="`requiredModele:${JSON.stringify(initialState().conducteur.vehicule.modele)}`">
                      <b-form-group label="Modèle " label-for="modele_vehicule" :class="errors.length > 0 ? 'is-invalid' : ''">
                        <v-select v-model="conducteur.vehicule.modele" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :state="errors.length > 0 ? false : null" :filterable="false" :options="modelesOptions" autocomplete @search="autocompleteModeles" label="modele" :reduce="modele => mapModele(modele)" placeholder="Saisissez votre véhicule (Marque, cylindrée, modèle)">
                          <template #option="{ recherche }">
                            <span class="ml-50">{{ recherche }}</span>
                          </template>

                          <template #selected-option="{ marque, modele, cylindree }">
                            <span class="ml-50" v-if="marque && modele"> {{ marque + ' ' + cylindree + ' ' + modele }}</span>
                            <span class="ml-50" v-else> </span>
                          </template>
                          <div slot="no-options">Aucun modèle disponible.</div>
                        </v-select>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Date de 1ère mise en circulation " label-for="date-premier-circulation">
                      <validation-provider #default="{ errors }" name="date de 1ère mise en circulation" rules="required">
                        <flat-pickr id="date-premier-circulation" :class="errors.length > 0 ? 'is-invalid' : ''" :config="configDate" v-model="conducteur.vehicule.date_premier_circulation" class="form-control" placeholder="Date de 1ère mise en circulation" rules="required" autocomplete="off" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }} </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Date d’acquisition / livraison du véhicule " label-for="date-acquisition">
                      <validation-provider #default="{ errors }" name="date d'acquisition/livraison" rules="required">
                        <flat-pickr id="date-acquisition" :class="errors.length > 0 ? 'is-invalid' : ''" :config="configDate" v-model="conducteur.vehicule.date_acquisition" class="form-control" placeholder="Date d’acquisition / livraison du véhicule" rules="required" autocomplete="off" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }} </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="12" class="pt-1">
                <b-row>
                  <b-col md="8">
                    <b-form-group>
                      <label class="mb-1 mr-1 float-left">Mode d’acquisition </label>
                      <validation-provider #default="{ errors }" name="mode d'acquisition" rules="required">
                        <b-form-radio-group v-model="conducteur.vehicule.mode_acquisition" name="radio-mode-acquisition" :state="errors.length > 0 ? false : null" class="demo-inline-spacing">
                          <b-form-radio :value="option.value" v-for="option in optionsModeAcquisition" :key="option.value" class="mt-0">
                            {{ option.text }}
                            <span v-b-popover.hover.top="option.infoBool" v-ripple.400="'rgba(113, 102, 240, 0.15)'" :title="option.text" class="ml-1 cursor-pointer" variant="outline-primary">
                              <feather-icon icon="InfoIcon" size="20" />
                            </span>
                          </b-form-radio>
                        </b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="12" class="pt-1">
                <b-row>
                  <b-col md="12">
                    <b-form-group>
                      <label class="mb-1 mr-1 float-left">Usage du véhicule </label>
                      <validation-provider #default="{ errors }" name="usage du véhicule" rules="required">
                        <b-form-radio-group name="radio-usage-vehicule" v-model="conducteur.vehicule.usage_vehicule" :state="errors.length > 0 ? false : null" class="demo-inline-spacing">
                          <b-form-radio :value="option.value" v-for="option in optionsUsageVehicule" :key="option.value" class="mt-0">
                            {{ option.text }}
                            <span v-b-popover.top.hover.html="option.infoBool" v-ripple.400="'rgba(113, 102, 240, 0.15)'" :title="option.text" class="ml-1 cursor-pointer" variant="outline-primary">
                              <feather-icon icon="InfoIcon" size="20" />
                            </span>
                          </b-form-radio>
                        </b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="12" class="pt-1">
                <b-row>
                  <b-col md="7">
                    <b-form-group>
                      <label class="mb-1 mr-1 float-left"
                        >Le titulaire de la carte grise est <b class="text-lowercase">{{ getTextOfTitulaire(conducteur.vehicule.titulaire_carte_grise) }}</b></label
                      >
                    </b-form-group>
                    <div v-show="false">
                      <b-form-select v-model="conducteur.vehicule.titulaire_carte_grise" :options="TitulaireCarteGriseOptions" disabled class="d-inline-block w-50" />
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="12" class="py-1" v-if="checkVehiculeUsageParticulier">
                <b-row>
                  <b-col md="12">
                    <b-form-group>
                      <validation-provider #default="{ errors }" name="isUsage" rules="required">
                        <b-form-checkbox id="is_usage_pp" v-model="conducteur.vehicule.is_usage_pp" :value="1" :unchecked-value="null"> En cochant cette case, vous reconnaissez que <strong>l'usage "privé"</strong> que vous avez sélectionné est autorisé uniquement pour un véhicule appartenant à une personne physique. Si le véhicule appartient à une société (titulaire et/ou co-titulaire de la carte grise), vous devez impérativement choisir <strong>l'usage "Professionnel (avec véhicule de société)"</strong>. </b-form-checkbox>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">La case à cocher est obligatoire</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="12" class="pt-1">
                <b-row>
                  <b-col md="8">
                    <b-form-group>
                      <label class="mb-1 float-left mr-1">Type de garage </label>
                      <validation-provider #default="{ errors }" name="type de garage" rules="required">
                        <b-form-radio-group v-model="conducteur.vehicule.type_garage" name="radio-type-garage" :state="errors.length > 0 ? false : null" class="demo-inline-spacing">
                          <b-form-radio :value="option.value" v-for="option in optionsTypeGarage" :key="option.value" class="mt-0">
                            {{ option.text }}
                            <span v-if="option.infoBool" v-b-popover.hover.top="option.infoBool" v-ripple.400="'rgba(113, 102, 240, 0.15)'" :title="option.text" class="ml-1 cursor-pointer" variant="outline-primary">
                              <feather-icon icon="InfoIcon" size="20" />
                            </span>
                          </b-form-radio>
                        </b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="12" class="pt-1">
                <b-row>
                  <b-col md="3">
                    <b-form-group label="Code postal de stationnement " label-for="code-postal-stationnement">
                      <validation-provider #default="{ errors }" name="code postal de stationnement" rules="required|length:5">
                        <cleave id="code-postal-stationnement" v-model="conducteur.vehicule.code_postal_stationnement" :class="errors.length > 0 ? 'is-invalid' : ''" :options="options.codePostal" :raw="false" class="form-control" placeholder="Code postal de stationnement" type="number" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }} </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Ville de stationnement " label-for="ville-stationnement">
                      <validation-provider #default="{ errors }" name="ville de stationnement" rules="required">
                        <b-form-select v-model="conducteur.vehicule.ville_stationnement" :state="errors.length > 0 ? false : null" :class="errors.length > 0 ? 'is-invalid' : ''">
                          <b-form-select-option :value="null">Choisissez</b-form-select-option>
                          <b-form-select-option v-for="ville in villeOptions" :value="ville.ville" :key="ville.id">{{ ville.ville }}</b-form-select-option>
                        </b-form-select>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }} </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="12">
                <b-row>
                  <b-col md="9" v-if="hasDateAcquisitionToday">
                    <b-row class="align-items-center">
                      <b-col md="5" class="py-3">
                        <b-form-group class="mb-0">
                          <label class="float-left pr-4">Le véhicule est-il assuré actuellement ?</label>
                          <validation-provider v-slot="{ errors }" name="véhicule à assurer" rules="required" vid="is_vehicule_a_assurer">
                            <b-form-radio-group v-model="conducteur.vehicule.is_assure" id="vehicule_assurer_option" :options="BooleanValue" label="Véhicule à assurer"></b-form-radio-group>
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="4" v-if="conducteur.vehicule.is_assure != null && !conducteur.vehicule.is_assure">
                        <b-form-group label="Depuis quand n’est-il pas assuré ?" label-for="date-non-assurer">
                          <validation-provider #default="{ errors }" name="date depuis quand" rules="required">
                            <flat-pickr id="date-non-assurer" @on-change="checkDateQAssure" :class="errors.length > 0 || errorDateQAssure ? 'is-invalid' : ''" :config="configdateqAssure" v-model="conducteur.vehicule.duree_non_assure" class="form-control" placeholder="Date" rules="required" autocomplete="off" />
                            <b-form-invalid-feedback :state="errors.length > 0 || errorDateQAssure ? false : null"> {{ errorDateQAssure ? errorDateQAssure : 'Le champ est obligatoire' }} </b-form-invalid-feedback>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="12" v-if="checkDateExpirationAssure && !conducteur.vehicule.is_assure">
                <b-row class="align-items-center">
                  <b-col md="5">
                    <b-form-group>
                      <label for="vehicule-expertise" class="mb-1 float-left mr-1">Le véhicule a-t-il été expertisé ?</label>
                      <validation-provider #default="{ errors }" name="vehicule-expertise" rules="required">
                        <b-form-radio-group v-model="conducteur.vehicule.is_expertise" name="radio-vehicule-expertise" :state="errors.length > 0 ? false : null">
                          <b-form-radio :value="option.value" v-for="option in BooleanValue" :key="option.value">
                            {{ option.text }}
                          </b-form-radio>
                        </b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </app-collapse-item>
          <!-- Le conducteur -->
          <app-collapse-item ref="tab-collapse-item-2" :is-visible="true" title="<h4 class='title-custom-wizard'><u>Le conducteur</u></h4>">
            <b-row>
              <b-col md="12">
                <b-row>
                  <b-col md="12">
                    <b-row>
                      <b-col md="3">
                        <b-form-group label="Civilité " label-for="assure_civilite">
                          <validation-provider #default="{ errors }" name="civilité" rules="required">
                            <b-form-select v-model="souscripteur.civilite" :options="civilite" id="assure_civilite" :state="errors.length > 0 ? false : null" />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group label="Date de naissance " label-for="date-naissance">
                          <validation-provider #default="{ errors }" name="date de naissance" rules="required">
                            <flat-pickr id="date-naissance" v-model="souscripteur.date_naissance" :class="errors.length > 0 ? 'is-invalid' : ''" :config="configDate" class="form-control" placeholder="Date de naissance" autocomplete="off" />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }} </b-form-invalid-feedback>
                            <b-form-invalid-feedback :state="errorDateNaissanceProspect ? false : null"> {{ errorDateNaissanceProspect }} </b-form-invalid-feedback>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col md="3">
                        <b-form-group label="Situation familiale " label-for="situation_familiale">
                          <validation-provider v-slot="{ errors }" name="situation familiale" rules="required" vid="situation_familiale">
                            <b-form-select v-model="souscripteur.situation_familiale" :options="SituationFamilialeOptions" id="situation_familiale" :state="errors.length > 0 ? false : null" />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }} </b-form-invalid-feedback>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col md="3">
                        <b-form-group label="Activité professionnelle " label-for="activite_professionnelle">
                          <validation-provider v-slot="{ errors }" name="activité professionnelle du souscripteur" rules="required">
                            <b-form-select v-model="souscripteur.csp" :options="ActiviteProfessionnelle" id="activite_professionnelle" :state="errors.length > 0 ? false : null" />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>

                 <b-row>
                    <b-col md="12" class="pt-1">
                      <b-form-group class="mb-0">
                        <label class="float-left pr-4 mb-2">Le souscripteur est-il le conducteur ?</label>
                        <validation-provider v-slot="{ errors }" name="is conducteur" rules="required" vid="is_conducteur">
                          <b-form-radio-group id="is_conducteur_option" v-model="conducteur.s_is_conducteur" :options="BooleanValue" @change="toggleIsSouscipteur"></b-form-radio-group>
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>

                <b-row>
                  <b-col md="12" class="pt-1">
                    <label class="mb-1">Le conducteur est-il ?</label>
                  </b-col>
                  <b-col md="12" class="pl-4 pt-1">
                    <b-form-group class="mb-0">
                      <label class="float-left pr-2 mb-2 w-125">Coursier, livreur</label>
                      <validation-provider v-slot="{ errors }" name="coursier, livreur" rules="required" vid="coursier_livreur">
                        <b-form-radio-group id="coursier_livreur" :options="BooleanValue" v-model="conducteur.is_coursier" @change="toggleConducteurType('coursier', conducteur.is_coursier)"></b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> Le choix est obligatoire</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12" class="pl-4 pt-1">
                    <b-form-group class="mb-0">
                      <label class="float-left pr-2 mb-2 w-125">Taxi</label>
                      <validation-provider v-slot="{ errors }" name="taxi" rules="required" vid="taxi">
                        <b-form-radio-group id="taxi" :options="BooleanValue" v-model="conducteur.is_taxi" @change="toggleConducteurType('taxi', conducteur.is_taxi)"></b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> Le choix est obligatoire </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12" class="pl-4 pt-1">
                    <b-form-group class="mb-0">
                      <label class="float-left pr-2 mb-2 w-125">VRP</label>
                      <validation-provider v-slot="{ errors }" name="vrp" rules="required" vid="vrp">
                        <b-form-radio-group id="vrp" :options="BooleanValue" v-model="conducteur.is_vrp" @change="toggleConducteurType('vrp', conducteur.is_vrp)"></b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col class="pt-1" md="12">
                <b-row>
                  <b-col md="6">
                    <b-form-group>
                      <label for="type-permis">Quels permis (en cours de validité obtenu en France ou en UE) possède le conducteur ?</label>
                      <b-form-checkbox-group name="checkbox-type-permis" :checked="permisSelected()">
                        <validation-provider v-slot="{ errors }" :rules="`requiredPermis:${JSON.stringify(conducteur.permis)}`" class="demo-inline-spacing align-items-start" ref="PermisSelected">
                          <div v-for="(option, index) in optionsTypesPermis" :key="option.value" class="mt-1">
                            <b-form-checkbox @change="updateSelectedPermis(option.value)" :value="option.value">
                              {{ option.text }}
                              <span v-b-popover.hover.html.top="option.infoBool" v-ripple.400="'rgba(113, 102, 240, 0.15)'" :title="option.text" class="ml-1 cursor-pointer" variant="outline-primary">
                                <feather-icon icon="InfoIcon" size="20" />
                              </span>
                            </b-form-checkbox>
                            <b-form-group class="mt-1 btn-date-permis" v-if="conducteur.permis[index].checked">
                              <validation-provider #default="{ errors }" :name="`date ${option.text}`" rules="required">
                                <flat-pickr :id="`date-permis-${index}`" :config="configDate" v-model="conducteur.permis[index].date" :class="errors.length > 0 ? 'is-invalid' : ''" class="form-control w-125" placeholder="Date" rules="required" autocomplete="off" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire</b-form-invalid-feedback>
                              </validation-provider>
                            </b-form-group>
                          </div>
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null" class="mt-1">{{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-checkbox-group>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="12">
                <b-row class="mt-2 align-items-end">
                  <b-col md="5">
                    <b-form-group class="mb-0">
                      <label>Quel est le bonus moto actuel du conducteur ? (de 0,5 à 3,50)</label>
                      <validation-provider #default="{ errors }" name="bonus moto" rules="required|between:0.5,3.5">
                        <b-form-input v-model="conducteur.bonus_moto" :state="errors.length > 0 ? false : null" placeholder="Bonus" type="text" class="mx-1 w-100px text-center d-inline-block" v-mask="['#', '.##']" />
                        <span class="d-inline-block text-center mt-0 align-middle w-80px">
                          <b class="text-success d-block" v-if="conducteur.bonus_moto >= 0.5 && conducteur.bonus_moto <= 1.0">Bonus</b>
                          <b class="text-warning d-block" v-else>Malus</b>
                          {{ calcPourcentageBonus(conducteur.bonus_moto) }}
                        </span>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="7" v-if="conducteur.bonus_moto == 0.5">
                    <b-form-group>
                      <label class="float-left mr-1">Depuis quand le conducteur a-t-il un bonus 50 en moto ?</label>
                      <validation-provider #default="{ errors }" name="bonus-moto-years" rules="required">
                        <b-form-radio-group v-model="conducteur.bonus_moto_years" name="radio-bonus-moto-years" :state="errors.length > 0 ? false : null" class="demo-inline-spacing">
                          <b-form-radio :value="option.value" v-for="option in bonusYearsOptions" :key="option.value" class="mt-0">
                            {{ option.text }}
                          </b-form-radio>
                        </b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="12">
                <b-row class="mt-2 align-items-end" v-if="permisBSelected">
                  <b-col md="5">
                    <b-form-group class="mb-0">
                      <label>Quel est le bonus auto actuel du conducteur ? (de 0,5 à 3,50)</label>
                      <validation-provider #default="{ errors }" name="bonus auto" rules="required|between:0.5,3.5">
                        <b-form-input v-model="conducteur.bonus_auto" :state="errors.length > 0 ? false : null" placeholder="Bonus" type="text" class="mx-1 w-100px text-center d-inline-block" v-mask="['#', '.##']" />
                        <span class="d-inline-block text-center mt-0 align-middle w-80px">
                          <b class="text-success d-block" v-if="conducteur.bonus_auto >= 0.5 && conducteur.bonus_auto <= 1.0">Bonus</b>
                          <b class="text-warning d-block" v-else>Malus</b>
                          {{ calcPourcentageBonus(conducteur.bonus_auto) }}
                        </span>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="7" v-if="conducteur.bonus_auto == 0.5">
                    <b-form-group>
                      <label class="float-left mr-1">Depuis quand le conducteur a-t-il un bonus 50 en auto ?</label>
                      <validation-provider #default="{ errors }" name="bonus-auto-years" rules="required">
                        <b-form-radio-group v-model="conducteur.bonus_auto_years" name="radio-bonus-auto-years" :state="errors.length > 0 ? false : null" class="demo-inline-spacing">
                          <b-form-radio :value="option.value" v-for="option in bonusYearsOptions" :key="option.value" class="mt-0">
                            {{ option.text }}
                          </b-form-radio>
                        </b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="permisBOnlySelected">
                  <b-col md="12" class="justify-content-center flex-column d-flex mr-0 mt-2">
                    <b-form-group class="mb-0">
                      <label class="float-left pr-4 mb-2">Le conducteur a-t-il effectué le stage obligatoire pour la conduite d’un 2 ou 3 roues ?</label>
                      <validation-provider v-slot="{ errors }" name="stage" rules="required" vid="stage">
                        <b-form-radio-group id="stage" v-model="conducteur.stage" :options="BooleanValue"></b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12" class="justify-content-center flex-column d-flex mr-0 mt-1">
                    <b-form-group class="mb-0">
                      <label class="float-left pr-4 mb-2">Peut-il justifier d'une expérience et d'antécédents d'assurance sur un véhicule de la même catégorie entre le 01/01/2006 et le 31/12/2010 ?</label>
                      <validation-provider v-slot="{ errors }" name="exp-in-vehicule" rules="required" vid="exp-in-vehicule">
                        <b-form-radio-group id="exp-in-vehicule" v-model="conducteur.exp_in_vehicule" :options="BooleanValue"></b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </app-collapse-item>
          <!-- Expérience du conducteur -->
          <app-collapse-item ref="tab-collapse-item-3" :is-visible="true" title="<h4 class='title-custom-wizard'><u>Expérience du conducteur</u></h4>">
            <b-row>
              <b-col md="12">
                <b-row>
                  <b-col md="12" class="justify-content-center flex-column d-flex mr-0">
                    <b-form-group class="mb-0">
                      <label class="float-left pr-4 mb-2">Le conducteur a-t-il été assuré en 2 roues au cours des 3 dernières années ?</label>
                      <validation-provider v-slot="{ errors }" name="conducteur à assurer" rules="required" vid="is_conducteur_assure">
                        <b-form-radio-group id="conducteur_assurer_experience_option" v-model="conducteur.experience.is_assure" :options="BooleanValue"></b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="conducteur.experience.is_assure">
                  <b-col md="12">
                    <b-form-group class="block-cylindre">
                      <label class="mb-2">Sur quelle cylindrée ?</label>
                      <span v-b-popover.hover.top="`Plusieurs expériences peuvent être renseignées si le conducteur a été assuré pour plusieurs motos de différentes cylindrées`" v-ripple.400="'rgba(113, 102, 240, 0.15)'" :title="`Sur quelle cylindrée ?`" class="ml-1 cursor-pointer" variant="outline-primary">
                        <feather-icon icon="InfoIcon" size="20" />
                      </span>
                      <b-form-checkbox-group name="checkbox-type-cylindre" :checked="cylindresSelected()">
                        <validation-provider v-slot="{ errors }" :rules="`requiredCylindres:${JSON.stringify(conducteur.experience.cylindres)}`" ref="cylindresSelected">
                          <b-form-checkbox @change="updateSelectedCylindre(option.value)" @click.native="preventForAttribute" :value="option.value" v-for="(option, index) in optionsTypesCylindre" :key="option.value" class="h-40p mb-2 d-block">
                            <span class="d-inline-block label-cylindre">{{ option.text }}</span>
                            <validation-provider #default="{ errors }" :name="`nombre de mois{${index}}`" rules="required|between:1,36" v-if="conducteur.experience.cylindres[index].checked">
                              <b-form-input v-model="conducteur.experience.cylindres[index].mois" :state="errors.length > 0 ? false : null" placeholder="Mois" type="text" class="mx-1 w-100px text-center d-inline-block" v-mask="'##'" />
                              <span>mois</span>
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ clearError(errors[0]) }} </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-checkbox>
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-checkbox-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="permisBSelected">
                  <b-col md="6" class="justify-content-center flex-column d-flex mr-0 mt-1">
                    <b-form-group class="mb-0">
                      <label class="float-left pr-4 mb-2">Le conducteur a-t-il été assuré en auto au cours des 3 dernières années ?</label>
                      <validation-provider v-slot="{ errors }" name="conducteur à assurer auto" rules="required" vid="is_conducteur_assure_auto">
                        <b-form-radio-group id="conducteur_assurer_auto_experience_option" v-model="conducteur.experience.is_assure_auto" :options="BooleanValue"></b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12" class="mb-1" v-if="conducteur.experience.is_assure_auto">
                    <b-form-group class="mb-0">
                      <label>Pendant combien de temps ?</label>
                      <validation-provider #default="{ errors }" name="conducteur à assurer auto pendant" rules="required|integer|between:1,36">
                        <b-form-input v-model="conducteur.experience.p_assure_auto" :state="errors.length > 0 ? false : null" placeholder="Mois" type="text" class="mx-1 w-100px text-center d-inline-block" v-mask="'##'" />
                        <span>mois</span>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="pt-1">
                  <b-col md="12" class="justify-content-center flex-column d-flex mr-0">
                    <b-form-group class="mb-0">
                      <label class="float-left pr-4 mb-2">Le conducteur a-il-fait l'objet d'une résiliation de contrat moto et/ou auto par un précédent assureur ?</label>
                      <validation-provider v-slot="{ errors }" name="contrat précédent assureur" rules="required" vid="is_precedent_assure">
                        <b-form-radio-group id="contrat_precedent_assureur_option" v-model="conducteur.experience.precedent_assureur" :options="BooleanValue"></b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3" v-if="conducteur.experience.precedent_assureur">
                    <b-form-group label="Pour quel motif ? " label-for="motif">
                      <validation-provider #default="{ errors }" name="motif" rules="required" vid="motif">
                        <b-form-select :options="MotifOptions" v-model="conducteur.experience.motif" :state="errors.length > 0 ? false : null" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }} </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="conducteur.experience.motif == 'NON_PAIEMENT'">
                  <b-col md="6" class="justify-content-center flex-column d-flex mr-0">
                    <b-form-group class="mb-0">
                      <label class="float-left pr-4 mb-2">Le contentieux est-il soldé ?</label>
                      <validation-provider v-slot="{ errors }" name="contentieux soldé" rules="required" vid="contentieux_solde">
                        <b-form-radio-group id="contentieux_solde_option" v-model="conducteur.experience.contentieux_solde" :options="BooleanValue"></b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </app-collapse-item>
          <!-- Antécédents de sinistres du conducteur -->
          <app-collapse-item ref="tab-collapse-item-4" :is-visible="true" title="<h4 class='title-custom-wizard'><u>Antécédents de sinistres du conducteur</u></h4>">
            <b-row>
              <b-col md="12" class="justify-content-center flex-column d-flex mr-0">
                <b-form-group class="mb-0">
                  <label class="float-left pr-4 mb-2">Le conducteur a-t-il eu des sinistres auto ou moto au cours des 3 dernières années ?</label>
                  <validation-provider v-slot="{ errors }" name="sinistre" rules="required" vid="sinistre">
                    <b-form-radio-group id="sinistre_option" v-model="conducteur.antecedents_sinistres.has_sinistre" :options="BooleanValue"></b-form-radio-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12" v-if="conducteur.antecedents_sinistres.has_sinistre">
                <b-row class="repeater-form" :style="{ height: trHeight }" ref="cylindresRef">
                  <b-col md="12" class="flex-column d-flex mb-2">
                    <b-form-group class="mb-0">
                      <label>Nombre total de sinistres (hors Bris des Glaces) ?</label>
                      <validation-provider #default="{ errors }" name="bonus-actuel" rules="required">
                        <b-form-spinbutton id="sb-inline" class="mx-2" v-model="conducteur.antecedents_sinistres.nbr_sinistre" step="1" min="1" max="6" inline @change="updateSinistres" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12" v-for="option in conducteur.antecedents_sinistres.nbr_sinistre" :key="option" class="mb-1" ref="cylindreItemRef">
                    <b-row>
                      <b-col md="12" class="justify-content-center flex-column d-flex mr-0">
                        <b-form-group class="mb-0">
                          <h3 class="mb-1">{{ `Sinistre ${option}` }}</h3>
                          <label class="float-left pr-4 mb-2">Type de véhicule ?</label>
                          <validation-provider v-slot="{ errors }" :name="'type vehicule de sinistre ' + option" rules="required" :vid="'type_vehicule' + option">
                            <b-form-radio-group :id="'type_vehicule-option_' + option" v-model="conducteur.antecedents_sinistres.sinistres[option - 1].type_vehicule" :options="TypesVehicule"></b-form-radio-group>
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-row>
                          <b-col md="3">
                            <b-form-group label="Date de survenance " :label-for="`date_survenance_${option}`">
                              <validation-provider v-slot="{ errors }" :name="`date de survenance sinistre ${option}`" rules="required">
                                <flat-pickr class="form-control" :id="`date-survenance-${option}`" :config="configDate" placeholder="Date de survenance" :class="errors.length > 0 ? 'is-invalid' : ''" v-model="conducteur.antecedents_sinistres.sinistres[option - 1].date_survenance" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> Le champ est obligatoire </b-form-invalid-feedback>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col md="3">
                            <b-form-group label="Nature du sinistre " :label-for="`nature_sinistre_${option}`">
                              <validation-provider #default="{ errors }" :name="`nature sinistre ${option}`" rules="required">
                                <b-form-select :options="NaturesSinistreOptions" v-model="conducteur.antecedents_sinistres.sinistres[option - 1].nature_sinistre" :state="errors.length > 0 ? false : null" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> Le champ est obligatoire </b-form-invalid-feedback>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col md="12" class="justify-content-center flex-column d-flex mr-0 mt-1" v-if="checkNatureSinistre(conducteur.antecedents_sinistres.sinistres[option - 1].nature_sinistre)">
                        <b-form-group class="mb-0">
                          <label class="float-left pr-4 mb-2">Responsabilité</label>
                          <validation-provider v-slot="{ errors }" :name="'responsabilité ' + option" rules="required" :vid="'responsabilite-' + option">
                            <b-form-radio-group :id="'responsabilite-option_' + option" v-model="conducteur.antecedents_sinistres.sinistres[option - 1].is_responsable" :options="ResponsableOptions"></b-form-radio-group>
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </app-collapse-item>
          <!-- Antécédents d'aggravation du conducteur -->
          <app-collapse-item ref="tab-collapse-item-5" :is-visible="true" title="<h4 class='title-custom-wizard'><u>Antécédents d'aggravation du conducteur</u></h4>">
            <b-row>
              <b-col md="12" class="justify-content-center flex-column d-flex mb-2">
                <b-form-group class="mb-0">
                  <label class="pr-4 mb-2">Le conducteur fait-il l'objet, sur les 60 derniers mois, d'un retrait (suspension ou annulation) de permis de conduire pour une infraction au Code de la Route ?</label>
                  <validation-provider v-slot="{ errors }" name="retrait permis" rules="required" vid="retrait-permis">
                    <b-form-radio-group id="retrait-permis-option" v-model="conducteur.antecedents_aggravation.retrait_permis" @change="initDataAntecedents()">
                      <b-form-radio :value="option.value" v-for="option in RetraitOptions" :key="option.value">
                        {{ option.text }}
                        <span v-b-popover.hover.top="option.infoBool" v-ripple.400="'rgba(113, 102, 240, 0.15)'" :title="option.text" class="ml-1 cursor-pointer" variant="outline-primary">
                          <feather-icon icon="InfoIcon" size="20" />
                        </span>
                      </b-form-radio>
                    </b-form-radio-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-row v-if="conducteur.antecedents_aggravation.retrait_permis == 'SUSPENSION' || conducteur.antecedents_aggravation.retrait_permis == 'ANNULATION'">
                  <b-col md="12" class="justify-content-center flex-column d-flex mb-1">
                    <b-form-group class="mb-0">
                      <label class="float-left pr-2 mb-2">pour contrôle positif d'alcoolémie ?</label>
                      <validation-provider v-slot="{ errors }" name="positif d'alcoolémie" rules="required" vid="alcoolemie">
                        <b-form-radio-group id="alcoolemie_option" :options="BooleanValue" v-model="conducteur.antecedents_aggravation.alcoolemie"></b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12" class="justify-content-center flex-column d-flex mb-1">
                    <b-form-group class="mb-0">
                      <label class="float-left pr-2 mb-2">pour usage de stupéfiants ou de drogue ?</label>
                      <validation-provider v-slot="{ errors }" name="drogue" rules="required" vid="drogue">
                        <b-form-radio-group id="drogue_option" :options="BooleanValue" v-model="conducteur.antecedents_aggravation.drogue"></b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12" class="justify-content-center flex-column d-flex mb-1">
                    <b-form-group class="mb-0">
                      <label class="float-left pr-2 mb-2">pour refus de se soumettre à un contrôle de dépistage d'alcoolémie ou stupéfiants ?</label>
                      <validation-provider v-slot="{ errors }" name="contrôle dépistage d'alcoolémie" rules="required" vid="controle-depistage">
                        <b-form-radio-group id="controle-depistage_option" :options="BooleanValue" v-model="conducteur.antecedents_aggravation.refus_controle"></b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12" class="justify-content-center flex-column d-flex mb-1">
                    <b-form-group class="mb-0">
                      <label class="float-left pr-2 mb-2">pour refus d'obtempérer ?</label>
                      <validation-provider v-slot="{ errors }" name="refus d'obtempérer" rules="required" vid="obtemperer">
                        <b-form-radio-group id="obtemperer_option" :options="BooleanValue" v-model="conducteur.antecedents_aggravation.refus_obtemperer"></b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12" class="justify-content-center flex-column d-flex mb-1">
                    <b-form-group class="mb-0">
                      <label class="float-left pr-2 mb-2">pour délit de fuite ?</label>
                      <validation-provider v-slot="{ errors }" name="délit de fuite" rules="required" vid="delit-fuite">
                        <b-form-radio-group id="delit_fuite_option" :options="BooleanValue" v-model="conducteur.antecedents_aggravation.delit_fuite"></b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12" class="justify-content-center flex-column d-flex mb-1">
                    <b-form-group class="mb-0">
                      <label class="float-left pr-2 mb-2">pour défaut d'assurance ?</label>
                      <validation-provider v-slot="{ errors }" name="défaut d'assurance" rules="required" vid="defaut-assurance">
                        <b-form-radio-group id="defaut_assurance_option" :options="BooleanValue" v-model="conducteur.antecedents_aggravation.defaut_assurance"></b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12" class="justify-content-center flex-column d-flex mb-1">
                    <b-form-group class="mb-0">
                      <label class="float-left pr-2 mb-2"
                        >pour autre infraction au Code de la Route ?
                        <span v-b-popover.hover.top="'Excès de vitesse, feu grillé, sens interdit, refus de priorité, dépassement dangereux.'" v-ripple.400="'rgba(113, 102, 240, 0.15)'" title="Info" class="cursor-pointer" variant="outline-primary">
                          <feather-icon icon="InfoIcon" size="20" />
                        </span>
                      </label>
                      <validation-provider v-slot="{ errors }" name="infraction au code de la route" rules="required" vid="infraction">
                        <b-form-radio-group id="infraction_option" :options="BooleanValue" v-model="conducteur.antecedents_aggravation.autre_infraction"></b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="conducteur.antecedents_aggravation.retrait_permis == 'ANNULATION'">
                  <b-col md="12" class="justify-content-center flex-column d-flex">
                    <b-form-group class="mb-0">
                      <label class="float-left pr-2 mb-2">pour perte totale de points ?</label>
                      <validation-provider v-slot="{ errors }" name="totale de points" rules="required" vid="totale-points">
                        <b-form-radio-group id="totale_points_option" :options="BooleanValue" v-model="conducteur.antecedents_aggravation.perte_totale_points"></b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12" class="mb-1" v-if="conducteur.antecedents_aggravation.perte_totale_points">
                    <b-row class="align-items-center">
                      <b-col md="3">
                        <b-form-group label="Date d'effet du retrait ? " label-for="date-effet-retrait">
                          <validation-provider #default="{ errors }" name="date d'effet de retrait" rules="required">
                            <flat-pickr id="date-effet-retrait" :class="errors.length > 0 ? 'is-invalid' : ''" :config="configDate" v-model="conducteur.antecedents_aggravation.date_retrait" class="form-control" placeholder="Date d'effet de retrait" rules="required" autocomplete="off" />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }} </b-form-invalid-feedback>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group class="mb-0 d-flex flex-column">
                          <label>Durée du retrait ?</label>
                          <validation-provider #default="{ errors }" name="durée du retrait" rules="required|integer|between:1,24">
                            <b-form-input v-model="conducteur.antecedents_aggravation.duree_retrait" :state="errors.length > 0 ? false : null" placeholder="Durée" type="text" class="mx-1 w-100px text-center d-inline-block" v-mask="'##'" />
                            <span>mois</span>
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12" class="justify-content-center flex-column d-flex mb-1">
                    <b-form-group class="mb-0">
                      <label class="float-left pr-2 mb-2">Le conducteur a-t-il fait l'objet, sur les 60 derniers mois, d'une sanction pour conduite en état alcoolique et/ou usage de stupéfiants ?</label>
                      <validation-provider v-slot="{ errors }" name="sanction pour conduite en état" rules="required" vid="sanction">
                        <b-form-radio-group id="sanction_option" :options="BooleanValue" v-model="conducteur.antecedents_aggravation.sanction_conduite"></b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </app-collapse-item>
        </app-collapse>
      </b-form>
    </validation-observer>
    <b-row class="mt-4">
      <b-col lg="12">
        <b-button variant="primary" class="float-left" size="lg" @click="backStep"> Précédent </b-button>

        <b-button variant="primary" class="float-right" size="lg" @click="checkValidateField()"> Suivant </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { heightTransition } from '@core/mixins/ui/transition'
import vSelect from 'vue-select'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import TheFormSpinbuttonWithInput from '@core/components/input-number/TheFormSpinbuttonWithInput.vue'
import { BFormSpinbutton } from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { mapGetters } from 'vuex'
import { required, requiredIf, minLength, maxLength, between, email } from 'vuelidate/lib/validators'
import moment from 'moment'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'
import { rules } from '@core/libs/validations'
import { BOverlay, BForm, BFormCheckbox, BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback, BFormRadioGroup, BFormCheckboxGroup, BFormRadio, BTabs, BTab, BFormSelect, BTable, BTableSimple, BThead, BTr, BTh, BTd, BTbody, BButton, BCard, BCardText, BListGroup, BListGroupItem, BFormTextarea, BFormValidFeedback, BAlert, VBTooltip, VBPopover, BFormSelectOption } from 'bootstrap-vue'

//import { codeIcon } from './code'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormRadioGroup,
    BFormRadio,
    BTabs,
    BTab,
    flatPickr,
    BFormSelect,
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BButton,
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
    BFormTextarea,
    BFormValidFeedback,
    BOverlay,
    Cleave,
    BAlert,
    VueSlider,
    BFormSpinbutton,
    BFormCheckboxGroup,
    AppCollapse,
    AppCollapseItem,
    TheFormSpinbuttonWithInput,
    BFormSelectOption,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
    Ripple,
  },
  mixins: [heightTransition],
  computed: {
    ...mapGetters(['getQWCheckedRisque']),
    hasDateAcquisitionToday() {
      const dateDuJour = moment().format('DD/MM/YYYY')
      if (this.conducteur.vehicule.date_acquisition) {
        return moment(this.conducteur.vehicule.date_acquisition, 'DD/MM/YYYY').isBefore(moment(dateDuJour, 'DD/MM/YYYY'))
      }
      return false
    },
    checkDateExpirationAssure() {
      const dateCheckExpertiser = moment().subtract(3, 'months')
      let dateNoAssure = this.conducteur.vehicule.duree_non_assure
      if (dateNoAssure) {
        return moment(dateNoAssure, 'DD/MM/YYYY').startOf('day').diff(moment(dateCheckExpertiser, 'DD/MM/YYYY').startOf('day'), 'days') < 0
      }
      return false
    },
    checkVehiculeUsageParticulier() {
      if ((this.conducteur.vehicule.mode_acquisition == 'LLD' || this.conducteur.vehicule.mode_acquisition == 'LOA') && (this.conducteur.vehicule.usage_vehicule == 'PRIVE_TRAVAIL' || this.conducteur.vehicule.usage_vehicule == 'PRIVE')) {
        return true
      }
      this.conducteur.vehicule.is_usage_pp = this.initialState().conducteur.vehicule.is_usage_pp
      return false
    },
    checkSinistresHasTwoVol() {
      if (this.conducteur.antecedents_sinistres.has_sinistre && this.conducteur.antecedents_sinistres.sinistres.length > 0) {
        const dateSinistre = moment().subtract(12, 'months').startOf('day')
        const count = 0
        const countfiltered = this.conducteur.antecedents_sinistres.sinistres.filter(sinistre => {
          let t = false
          t = sinistre.nature_sinistre == 'VOL' && sinistre.type_vehicule == 'MOTO'
          if (t && moment(dateSinistre, 'DD/MM/YYYY').diff(moment(sinistre.date_survenance, 'DD/MM/YYYY').startOf('day'), 'days') <= 0) {
            count++
          }
          return t
        }).length

        return !!(countfiltered > 1 && count > 1)
      }
      return false
    },
  },
  watch: {
    getQWCheckedRisque: {
      immediate: true,
      deep: true,
      handler(val) {
        console.log('getQWCheckedRisque 1')
        if (val) {
        console.log('getQWCheckedRisque 2')

          this.getProduits(val)
        }
      },
    },
    'conducteur.vehicule.is_assure': {
      handler(val) {
        if (val) {
          this.errorDateQAssure = null
        }
      },
    },
    'conducteur.experience.cylindres': {
      immediate: true,
      deep: true,
      handler(val) {
        if (this.$refs.cylindresSelected != undefined) {
          setTimeout(() => {
            this.$refs.cylindresSelected.validate()
          }, 500)
        }
      },
    },
    'conducteur.permis': {
      immediate: true,
      deep: true,
      handler(val) {
        const permisSelected = val
          .filter(permis => permis.checked == true)
          .map(permis => {
            return permis.type
          })

        if (permisSelected.find(per => per == 'B') != undefined && permisSelected.length == 1) {
          this.permisBSelected = true
          this.permisBOnlySelected = true
        } else if (permisSelected.find(per => per == 'B') != undefined) {
          this.permisBSelected = true
          this.permisBOnlySelected = false
        } else {
          this.permisBSelected = false
          this.permisBOnlySelected = false
        }

        if(!this.permisBOnlySelected) {
          this.conducteur.exp_in_vehicule = null
          this.conducteur.stage = null
        }

        if (this.$refs.PermisSelected != undefined) {
          setTimeout(() => {
            this.$refs.PermisSelected.validate()
          }, 500)
        }
      },
    },
    'conducteur.experience.is_assure_auto': {
      handler(val) {
        if (val) {
          const per = this.conducteur.experience.p_assure_auto
          if (!this.devis.date_effet || per == 0) {
            this.conducteur.experience.p_assure_auto = 1
          }
        } else {
          this.conducteur.experience.p_assure_auto = this.initialState().conducteur.experience.p_assure_auto
        }
      },
    },
    'conducteur.experience.is_assure': {
      handler(val) {
        if (!val) this.conducteur.experience.cylindres = this.initialState().conducteur.experience.cylindres
      },
    },
    'conducteur.experience.precedent_assureur': {
      handler(val) {
        if (!val) this.conducteur.experience.motif = this.initialState().conducteur.experience.motif
      },
    },
    'conducteur.antecedents_sinistres.has_sinistre': {
      handler(val) {
        if (!val) {
          this.conducteur.antecedents_sinistres.sinistres = this.initialState().conducteur.antecedents_sinistres.sinistres
          this.conducteur.antecedents_sinistres.nbr_sinistre = this.initialState().conducteur.antecedents_sinistres.nbr_sinistre
          this.initTrHeight()
        } else if (val && this.conducteur.antecedents_sinistres.sinistres.length <= 0) {
          this.conducteur.antecedents_sinistres.sinistres = this.initialState().conducteur.antecedents_sinistres.sinistres
          this.conducteur.antecedents_sinistres.nbr_sinistre = this.initialState().conducteur.antecedents_sinistres.nbr_sinistre
        }
      },
    },
    'conducteur.antecedents_sinistres.sinistres': {
      handler(val) {
        this.nbrSinitreKeep = val.length
      },
    },
    'conducteur.vehicule.code_postal_stationnement': {
      immediate: true,
      handler(val) {
        if (val && val.length === 5) {
          this.$http
            .get(`/helpers/${val}/villes`)
            .then(res => {
              if (res.data?.length > 0) {
                this.villeOptions = [...res.data]
                let obj = this.villeOptions.find(o => o.ville === this.conducteur.vehicule.ville_stationnement)
                if (typeof obj === 'undefined' || obj === null) {
                  this.conducteur.vehicule.ville_stationnement = this.initialState().conducteur.vehicule.ville_stationnement
                }
              }
            })
            .catch(err => {
              console.log(err)
            })
        } else {
          this.villeOptions = []
        }
      },
    },
  },
  data() {
    return this.initialState()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
    this.getPays()
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  mounted() {
    setTimeout(() => {
      this.$refs.AssureRules.reset()
    }, 1000)
    this.initTrHeight()
  },
  beforeMount() {
    this.initOriginalData()
  },
  methods: {
    initProduit(produit) {
      this.produit.produit_id       = produit.id
      this.produit.risque_id        = produit.risque_id
      this.produit.produit_nom      = produit.produit_nom
      this.produit.nom_assureur     = produit.assureur.nom_assureur
      this.produit.nom_com_assureur = produit.assureur.nom_com_assureur
    },
    getProduits(label) {
      this.$http
        .get(`ecaglobal/getProduitsByRisque/${label}`)
        .then(response => {
          let risque = response.data
          if (Object.keys(risque).length != 0 && risque.produits.length > 0) {
            var produit = risque.produits[0]
            this.initProduit(produit)
            this.$store.commit('setProduitTarificateur', { produit: this.produit })
          } else {
          console.log('no produit')
            this.$store.commit('setProduitTarificateur', { produit: this.initState().produit })
          }
        })
        .catch(err => {})
    },
    initialState() {
      return {
        produit: {
          produit_id: '',
          risque_id: '',
          produit_nom: '',
          nom_assureur: '',
          nom_com_assureur: ''
        },
        permisBSelected: false,
        permisBOnlySelected: false,
        originalData: {
          souscripteur: {
            civilite: null,
            situation_familiale: null,
            date_naissance: null,
            csp: null,
          },
        },
        hasSelectedGarantie: false,
        modelesOptions: [],
        errorsTarif: [],
        villeOptions: [],
        pays: [],
        options: {
          codePostal: {
            blocks: [5],
          },
          number: {
            numeral: true,
          },
        },
        heightSinistreWrap: 240,
        errorDateQAssure: null,
        errorDateNaissanceProspect: null,
        configDate: {
          dateFormat: 'd/m/Y',
          allowInput: true,
          onKeyDown: (selectedDates, dateStr, instance, event) => {
            if (event.keyCode != 8) {
              var ele = document.activeElement
              var val = ele.value

              if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                val += '/'
              }
              ele.value = val
              ele.dispatchEvent(new Event('input'))
            }
          },
        },
        configDateEffet: {
          dateFormat: 'd/m/Y',
          disable: [
            function (date) {
              let localyDayHours = new Date().setHours(0, 0, 0, 0)
              let localDay = new Date(localyDayHours)
              return date < localDay
            },
            function (date) {
              let localyDayHours = new Date().setHours(0, 0, 0, 0)
              let localDayThreeMonths = new Date(new Date(localyDayHours).setMonth(new Date(localyDayHours).getMonth() + 3))
              return date > localDayThreeMonths
            },
          ],
          allowInput: true,
          onKeyDown: (selectedDates, dateStr, instance, event) => {
            if (event.keyCode != 8) {
              var ele = document.activeElement
              var val = ele.value

              if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                val += '/'
              }
              ele.value = val
              ele.dispatchEvent(new Event('input'))
            }
          },
        },
        configdateqAssure: {
          dateFormat: 'd/m/Y',
          allowInput: true,
          onKeyDown: (selectedDates, dateStr, instance, event) => {
            if (event.keyCode != 8) {
              var ele = document.activeElement
              var val = ele.value

              if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                val += '/'
              }
              ele.value = val
              ele.dispatchEvent(new Event('input'))
            }
          },
        },
        garanties: [],
        exigences: [],
        conducteur: {
          date_effet: null,
          hamon: 0,
          s_is_conducteur: 1,
          stage: null,
          exp_in_vehicule: null,
          lien: null,
          vehicule: {
            modele: {
              modele: null,
              marque: null,
              cylindree: null,
            },
            usage_particulier: null,
            date_premier_circulation: null,
            date_acquisition: null,
            mode_acquisition: null,
            type_garage: null,
            code_postal_stationnement: null,
            ville_stationnement: null,
            usage_vehicule: null,
            is_usage_pp: null,
            titulaire_carte_grise: 'SOUSCRIPTEUR_CONJOINT_PARENT',
            is_assure: null,
            duree_non_assure: null,
            is_expertise: null,
          },
          permis: [
            {
              type: 'A',
              label: 'Permis A',
              date: null,
              checked: false,
            },
            {
              type: 'AL',
              label: 'Permis A1, AL',
              date: null,
              checked: false,
            },
            {
              type: 'A2',
              label: 'Permis A2',
              date: null,
              checked: false,
            },
            {
              type: 'B',
              label: 'Permis B',
              date: null,
              checked: false,
            },
          ],
          is_coursier: null,
          is_taxi: null,
          is_vrp: null,
          bonus_moto: '1.00',
          bonus_moto_years: null,
          bonus_auto: '1.00',
          bonus_auto_years: null,
          experience: {
            is_assure: null,
            is_assure_auto: null,
            p_assure_auto: 0,
            cylindres: [
              {
                type: '125',
                label: '80 à 125 cm3',
                mois: 1,
                checked: false,
              },
              {
                type: '399',
                label: '126 à 399 cm3',
                mois: 1,
                checked: false,
              },
              {
                type: '599',
                label: '400 à 599 cm3',
                mois: 1,
                checked: false,
              },
              {
                type: '9999',
                label: '600 et +',
                mois: 1,
                checked: false,
              },
            ],
            precedent_assureur: null,
            motif: null,
            contentieux_solde: null,
          },
          antecedents_sinistres: {
            has_sinistre: null,
            nbr_sinistre: 1,
            sinistres: [
              {
                type_vehicule: null,
                date_survenance: null,
                nature_sinistre: null,
                is_responsable: null,
              },
            ],
          },
          antecedents_aggravation: {
            retrait_permis: null,
            alcoolemie: null,
            drogue: null,
            refus_controle: null,
            refus_obtemperer: null,
            delit_fuite: null,
            defaut_assurance: null,
            autre_infraction: null,
            perte_totale_points: null,
            sanction_conduite: null,
            date_retrait: null,
            duree_retrait: 1,
          },
        },
        souscripteur: {},
        nbrSinitreKeep: 1,
        BonusActuel: ['0.50', '1.00', '1.50', '2.00', '2.50', '3.00', '3.50'],
        civilite: [
          {
            value: null,
            text: 'Choisissez',
          },
          {
            value: 'MR',
            text: 'M.',
          },
          {
            value: 'MME',
            text: 'Mme',
          },
        ],
        TitulaireCarteGriseOptions: [
          {
            value: null,
            text: '',
          },
          {
            value: 'SOUSCRIPTEUR_CONJOINT_PARENT',
            text: 'Le souscripteur, le conjoint, un parent',
          },
          {
            value: 'SOCIETE_CREDIT',
            text: 'Une société de crédit-bail ou de leasing',
          },
          {
            value: 'SOCIETE_VEHICULE',
            text: 'Une société (véhicule de société)',
          },
        ],
        MotifOptions: [
          {
            value: null,
            text: 'Choisissez',
          },
          {
            value: 'SURVEILLANCE',
            text: 'Sinistre',
          },
          {
            value: 'NON_PAIEMENT',
            text: 'Non paiement de prime',
          },
          {
            value: 'DEFAUT_PIECES',
            text: 'Défaut de pièces',
          },
          {
            value: 'FAUSSE_DECLA',
            text: 'Fausse déclaration',
          },
          {
            value: 'AGGRAVATION',
            text: 'Aggravation de risque',
          },
          {
            value: 'NULLITE',
            text: 'Nullité de contrat',
          },
        ],
        NaturesSinistreOptions: [
          {
            value: null,
            text: 'Choisissez',
          },
          {
            value: 'MATERIEL',
            text: 'Matériel',
          },
          {
            value: 'CORPOREL',
            text: 'Corporel',
          },
          {
            value: 'VOL',
            text: 'Vol ou tentative de vol',
          },
          {
            value: 'INCENDIE',
            text: 'Incendie',
          },
          {
            value: 'AUTRE',
            text: 'Autre (catastrophes naturelles, tempête, etc.)',
          },
        ],
        optionsModeAcquisition: [
          {
            text: 'Comptant / Crédit',
            value: 'COMPTANT',
            infoBool: 'Vous avez acheté votre véhicule comptant ou avec crédit personnel.',
          },
          {
            text: 'L.L.D',
            value: 'LLD',
            infoBool: 'Vous avez fait l’acquisition de votre véhicule en Location Longue Durée.',
          },
          {
            text: 'L.O.A',
            value: 'LOA',
            infoBool: "Vous avez fait l’acquisition de votre véhicule en Location avec Option d'Achat.",
          },
        ],
        optionsTypesPermis: [
          {
            text: 'Permis A',
            value: 'A',
            infoBool: 'Accessible à partir de 20 ans, à une double condition : <br><ul><li>être titulaire du permis A2 depuis 2 ans minimum et avoir suivi une formation de 7 heures</li><li>il permet de conduire toutes les motos, avec ou sans side-car et tous les tricycles, quelle que soit leur puissance</li></ul>',
          },
          {
            text: 'Permis A1, AL',
            value: 'AL',
            infoBool: 'Accessible à partir de 16 ans, il permet de conduire : <br><ul><li>les motos légères : motocyclette avec ou sans side-car SAUF genre TM (tricycles), d’une cylindrée maximale de 125 cm3 et d’une puissance de 11 kw maximum</li><li>les tricycles d’une puissance n’excédant 15 kw, quelle que soit leur cylindrée</li></ul>',
          },
          {
            text: 'Permis A2',
            value: 'A2',
            infoBool: 'Accessible à partir de 18 ans, il permet de conduire : <br> <ul><li>les motos n’excédant pas 35 kw</li><li>les tricycles n’excédant pas 15 kw</li></ul>',
          },
          {
            text: 'Permis B',
            value: 'B',
            infoBool: 'Accessible à partir de 18 ans. Il permet de conduire : <br><ul><li>les motos légères : motocyclette avec ou sans side-car, d’une cylindrée maximale de 125 cm3 et d’une puissance de 11 kw maximum</li><li>les tricycles d’une puissance n’excédant pas 15 kw à la double condition : <ul><li>être titulaire du permis B depuis 2 ans minimum</li><li>et avoir suivi une formation de 7 heures</li></ul> </li></ul>La formation de 7 heure n’est pas exigée si le permis a été obtenu avant le 1er mars 1980 ou si le conducteur peut justifier d’une expérience sur une moto ou un tricycle entre le 01/01/2006 et le 31/12/2010',
          },
        ],
        optionsTypesCylindre: [
          {
            text: '80 à 125 cm3',
            value: '125',
          },
          {
            text: '126 à 399 cm3',
            value: '399',
          },
          {
            text: '400 à 599 cm3',
            value: '599',
          },
          {
            text: '600 et +',
            value: '9999',
          },
        ],
        optionsTypeGarage: [
          {
            text: 'Garage clos et couvert',
            value: 'GARAGE',
            infoBool: 'Votre véhicule est stationné la nuit dans un garage ou un box, individuel ou collectif, avec un accès protégé par une clé, un badge magnétique ou un code, dont vous pouvez justifier être le propriétaire, copropriétaire ou locataire.',
          },
          {
            text: 'Terrain privé clos',
            value: 'TERRAIN',
            infoBool: null,
          },
          {
            text: 'Voie publique',
            value: 'VOIE',
            infoBool: 'Votre véhicule est stationné la nuit sur la voie publique.',
          },
        ],
        bonusYearsOptions: [
          {
            text: 'Moins d’1 an',
            value: -1,
          },
          {
            text: '1 an',
            value: 1,
          },
          {
            text: '2 ans',
            value: 2,
          },
          {
            text: '3 ans ou plus',
            value: 3,
          },
        ],
        optionsUsageVehicule: [
          {
            text: 'Vie privée et/ou trajet travail',
            value: 'PRIVE_TRAVAIL',
            infoBool: 'Vous utilisez votre véhicule pour les déplacements privés ou pour les trajets domicile - lieu de travail <strong>à l’exclusion de tout autre, même occasionnel. Le transport rémunéré de marchandises ou de personnes est exclu</strong>.',
          },
          {
            text: 'Professionnel (avec véhicule privé)',
            value: 'PRIVE',
            infoBool: 'Le véhicule est utilisée pour tout type de déplacements privés et professionnels. Il ne sert en aucun cas, même occasionnellement, au transport à titre onéreux de marchandises ou de voyageurs.',
          },
          // {
          //   text: 'Professionnel (avec véhicule de société)',
          //   value: 'SOCIETE',
          //   infoBool: 'Le véhicule est utilisé pour tout type de déplacements privés et professionnels. Il ne sert en aucun cas, même occasionnellement, au transport à titre onéreux de marchandises ou de voyageurs.',
          // },
        ],
        SituationFamilialeOptions: [
          {
            value: null,
            text: 'Choisissez',
          },
          {
            text: 'Concubin(e)',
            value: 'CONCUBIN',
          },
          {
            text: 'Célibataire',
            value: 'CELIBATAIRE',
          },
          {
            text: 'Divorcé(e)',
            value: 'DIVORCE',
          },
          {
            text: 'Marié(e)',
            value: 'MARIE',
          },
          {
            text: 'Séparé(e)',
            value: 'SEPARE',
          },
          {
            text: 'Veuf(ve)',
            value: 'VEUF',
          },
          {
            text: 'Pacsé(e)',
            value: 'PACSE',
          },
        ],
        optionsVenteDistance: [
          { text: 'Oui', value: 1 },
          { text: 'Non', value: 0 },
        ],
        optionsTypeVente: [
          { text: 'Vente à distance en 1 temps', value: 'optin', infoBool: 'Prospect ayant préalablement consenti à l’appel ou client.' },
          { text: 'Vente à distance en 2 temps', value: 'optout', infoBool: 'Prospect n’ayant pas consenti à l’appel. Un délai de 24h s’appliquera avant la mise en place de la signature électronique.' },
        ],
        BooleanValue: [
          {
            value: 1,
            text: 'Oui',
          },
          {
            value: 0,
            text: 'Non',
          },
        ],
        TypesVehicule: [
          {
            value: 'AUTO',
            text: '4 roues (Auto ou vsp)',
          },
          {
            value: 'MOTO',
            text: '2 roues (Moto ou cyclo)',
          },
        ],
        ResponsableOptions: [
          {
            value: 0,
            text: 'Non responsable',
          },
          {
            value: 1,
            text: 'Responsable',
          },
        ],
        RetraitOptions: [
          {
            value: 'AUCUN',
            text: 'Aucun retrait de permis',
            infoBool: "Le conducteur n'a eu aucun retrait de permis de conduire suite à une infraction au Code de la Route.",
          },
          {
            value: 'SUSPENSION',
            text: 'Suspension',
            infoBool: 'Le conducteur a eu un retrait temporaire de permis de conduire suite à une infraction au Code de la Route ; une suspension administrative prononcée par le préfet, ou une suspension judiciaire, décidée par un juge.',
          },
          {
            value: 'ANNULATION',
            text: 'Annulation',
            infoBool: "Le conducteur a eu un retrait définitif de permis de conduire suite à une infraction au Code de la Route ou pour perte totale de points. La date indiquée pour l'obtention du permis de conduire doit être celle du permis repassé.",
          },
        ],
        ActiviteProfessionnelle: [
          {
            value: null,
            text: 'Choisissez',
          },
          {
            value: 'AGRICULTEUR',
            text: 'Agriculteur',
          },
          {
            value: 'ARTISAN',
            text: 'Artisan',
          },
          {
            value: 'CHEF_ENTREPRISE',
            text: 'Chef d’entreprise',
          },
          {
            value: 'COMMERCANT',
            text: 'Commerçant',
          },
          {
            value: 'ETUDIANT',
            text: 'Etudiant',
          },
          {
            value: 'FONCTIONNAIRE_ASSIMILE',
            text: 'Fonctionnaire ou assimilé',
          },
          {
            value: 'PROFESSION_LIBERALE',
            text: 'Profession libérale',
          },
          {
            value: 'RETRAITE',
            text: 'Retraité',
          },
          {
            value: 'SALARIE_NON_CADRE',
            text: 'Salarié non cadre',
          },
          {
            value: 'SALARIE_CADRE',
            text: 'Salarié cadre',
          },
          {
            value: 'SANS_EMPLOI',
            text: 'Sans emploi',
          },
        ],
        showLoading: false,
        devis: null,
      }
    },
    initDataAntecedents() {
      let initData = null
      initData = this.initialState().conducteur.antecedents_aggravation
      initData.retrait_permis = this.conducteur.antecedents_aggravation.retrait_permis
      initData.sanction_conduite = this.conducteur.antecedents_aggravation.sanction_conduite
      this.conducteur.antecedents_aggravation = initData
    },
    initOriginalData() {
      this.originalData.souscripteur.civilite = ''
      this.originalData.souscripteur.situation_familiale = ''
      this.originalData.souscripteur.csp = ''
      this.originalData.souscripteur.date_naissance = ''
    },
    changeDisabledDate(day) {
      this.configDateEffet.disable = [
        function (date) {
          let localyDayHours = new Date().setHours(0, 0, 0, 0)
          let localDay = new Date(localyDayHours)
          return date < localDay.setDate(localDay.getDate() + day)
        },
        this.initialState().configDateEffet.disable[1],
      ]
    },
    clearError(error) {
      if (error) error = error.replace(/{[0-9]*}/i, '')

      return error
    },
    getTextOfTitulaire(value) {
      return this.TitulaireCarteGriseOptions.find(titulaire => titulaire.value == value).text
    },
    checkNatureSinistre(nature) {
      return nature == 'MATERIEL' || nature == 'CORPOREL' ? true : false
    },
    mapModele(modele) {
      let modeleObjet = {
        cylindree: modele.cylindree,
        marque: modele.marque,
        modele: modele.modele,
      }
      return modeleObjet
    },
    autocompleteModeles(filtre, loading) {
      if (filtre && filtre.length > 2) {
        loading(true)
        this.search(loading, filtre, this)
      }
    },
    search: _.debounce((loading, filtre, vm) => {
      vm.$http
        .post('/portefeuilles/getModeles', {
          filtre,
        })
        .then(res => {
          vm.modelesOptions = res.data.resultat
          loading(false)
        })
        .catch(() => {
          vm.modelesOptions = vm.initialState().modelesOptions
          loading(false)
        })
    }, 350),
    updateSinistres(nbrSinistre) {
      let listSinistres = this.conducteur.antecedents_sinistres.sinistres
      let nbrSinistreUpdated = this.conducteur.antecedents_sinistres.nbr_sinistre
      if (nbrSinistreUpdated >= 1 && nbrSinistreUpdated <= 6) {
        if (nbrSinistre > this.nbrSinitreKeep && listSinistres.length < 6) {
          this.repeateAgain()
          this.nbrSinitreKeep = nbrSinistre
        } else if (nbrSinistre < this.nbrSinitreKeep && listSinistres.length > 1) {
          this.removeItem()
          this.nbrSinitreKeep = nbrSinistre
        }
      }
    },
    toggleConducteurType(type, value) {
      if (value) {
        switch (type) {
          case 'coursier': {
            this.conducteur.is_taxi = 0
            this.conducteur.is_vrp = 0
            break
          }
          case 'taxi': {
            this.conducteur.is_coursier = 0
            this.conducteur.is_vrp = 0
            break
          }
          case 'vrp': {
            this.conducteur.is_coursier = 0
            this.conducteur.is_taxi = 0
            break
          }
          default: {
            this.conducteur.is_coursier = 0
            this.conducteur.is_taxi = 0
            this.conducteur.is_vrp = 0
            break
          }
        }
      }
    },
    permisSelected() {
      let permisSelected = this.conducteur.permis
        .filter(permis => permis.checked == true)
        .map(function (permis) {
          return permis.type
        })

      return permisSelected
    },
    cylindresSelected() {
      let cylindresSelected = this.conducteur.experience.cylindres
        .filter(cylindre => cylindre.checked == true)
        .map(function (cylindre) {
          return cylindre.type
        })

      return cylindresSelected
    },
    getPays() {
      this.$http
        .get('tiers/getPays')
        .then(r => {
          this.pays = r.data
        })
        .catch(e => {
          console.log(e)
        })
    },
    calcPourcentageBonus(bonus) {
      let pourcent = 0
      let bonusActuel = +bonus
      let int_part = Math.trunc(bonusActuel)
      let float_part = Number((bonusActuel - int_part).toFixed(2))
      if (bonusActuel < 1.0 && bonusActuel != 0.5) {
        bonusActuel = -(float_part - 1)
      } else if (bonusActuel > 1.99) {
        bonusActuel = float_part + (int_part - 1)
      } else bonusActuel = float_part

      pourcent = (bonusActuel * 100).toFixed(0)
      return `soit ${pourcent}%`
    },
    updateSelectedPermis(typePermis) {
      let index = this.conducteur.permis.findIndex(p => p.type === typePermis)
      this.conducteur.permis[index].checked = !this.conducteur.permis[index].checked
    },
    updateSelectedCylindre(typeCylindre) {
      let index = this.conducteur.experience.cylindres.findIndex(p => p.type === typeCylindre)
      this.conducteur.experience.cylindres[index].checked = !this.conducteur.experience.cylindres[index].checked
    },
    backStep() {
      this.$store.commit('setQWStepTarificateur', { step: { indexStep: 0, active: 'choix' } })
      this.$store.commit('setQWCheckedRisque', null)
      this.$store.commit('setQWHasProduitAutoriser', { hasProduitAutoriser: false })
      this.$store.commit('setProduitTarificateur', { produit: {} })
    },
    checkDateQAssure() {
      let dateAcquisition = this.conducteur.vehicule.date_acquisition
      let dateQAssure = this.conducteur.vehicule.duree_non_assure
      if (dateAcquisition != null && dateQAssure != null) {
        dateAcquisition = moment(dateAcquisition, 'DD/MM/YYYY')
        dateQAssure = moment(dateQAssure, 'DD/MM/YYYY')

        if (dateQAssure < dateAcquisition) this.errorDateQAssure = 'La date ne peut pas être antérieur à la date d’acquisition'
        else this.errorDateQAssure = null
      }
    },
    async checkValidateField() {
      this.errorsTarif = []
      window.scrollTo({ top: 0, behavior: 'smooth' })

      if(this.conducteur.s_is_conducteur){
          if (this.souscripteur.date_naissance && moment().diff(moment(this.souscripteur.date_naissance, 'DD-MM-YYYY'), 'years', true) < 18) {
          this.messageToast('', 'Le souscripteur doit être majeur (égale ou supérieur à 18 ans).', 'danger', 'XIcon')
          return 0
        }
      }

      if (this.checkSinistresHasTwoVol) {
        const erreurs = [
          {
            attribut: 'ERREUR_CRM',
            message: 'Compte-tenu de vos antécédents, nous ne pouvons vous délivrer de tarif.',
          },
        ]
        this.errorsTarif = this.filterErrorsTarif(erreurs)
        return 0
      }

      const isValid = await this.$refs.AssureRules.validate()
      if (!isValid || this.errorDateQAssure) {
        this.checkCollapseHasErrors()
        this.showLoading = false
        return 0
      }
      this.getTarifData()
    },
    async getTarifData() {
      try {
        this.showLoading = true
        const data = {
          txCom: 10,
          assure: this.souscripteur,
          conducteur: this.conducteur,
          fullFormule: true
        }

        await this.$http
          .post('quickwins/getQwTarif2Roues', data)
          .then(response => {
            this.errorsTarif = []
            if (!response.data[0]['produits'][0].hasOwnProperty('errors')) {
              this.$store.commit('setQWObjet', { objet: this.conducteur })
              this.$store.commit('setQWProspect', { prospect: this.souscripteur })
              this.$store.commit('setQWItemsRisque', { itemsRisque: response.data })
              this.$store.commit('setQWStepTarificateur', { step: { indexStep: 2, active: 'comparatif' } })
            } else {
              this.errorsTarif = this.filterErrorsTarif(response.data[0]['produits'][0]['errors'])
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }
            this.showLoading = false
          })
          .catch(() => {
            this.showLoading = false
          })
        this.showLoading = false
      } catch (e) {
        this.showLoading = false
        this.errors = e.response.data.errors
      }
    },
    checkCollapseHasErrors() {
      for (let index = 0; index < 7; index++) {
        let hasErrors = this.$refs[`tab-collapse-item-${index}`].$el.querySelector('div.d-block.invalid-feedback, input.is-invalid')
        if (hasErrors) {
          this.$refs[`tab-collapse-item-${index}`].visible = true
        } else this.$refs[`tab-collapse-item-${index}`].visible = false
      }
    },
    filterErrorsTarif(arrayErrors) {
      arrayErrors = arrayErrors.filter((value, index, self) => index === self.findIndex(e => e.attribut === value.attribut && e.message === value.message))
      return arrayErrors
    },
    repeateAgain() {
      let sinistres = this.conducteur.antecedents_sinistres.sinistres
      sinistres.push({
        type_vehicule: null,
        date_survenance: null,
        nature_sinistre: null,
        is_responsable: null,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.heightSinistreWrap)
      })
    },
    removeItem() {
      let sinistres = this.conducteur.antecedents_sinistres.sinistres
      sinistres.splice(-1)
      this.trTrimHeight(this.heightSinistreWrap)
    },
    initTrHeight() {
      this.trSetHeight(this.conducteur.antecedents_sinistres.nbr_sinistre * this.heightSinistreWrap + 20)
      this.$nextTick(() => {
        if (this.$refs.cylindresRef !== undefined && this.$refs.cylindresRef.scrollHeight != 0) {
          this.trSetHeight(this.$refs.cylindresRef.scrollHeight)
        }
      })
    },
    preventForAttribute(evt) {
      let exeptEvent = ['BDI', 'DIV']
      if (exeptEvent.find(el => el == evt.target.tagName)) {
        evt.preventDefault()
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-slider.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/pages/page-pricing.scss';

.block-cylindre .custom-checkbox .custom-control-label::before,
.block-cylindre .custom-checkbox .custom-control-label::after {
  top: 0.7rem;
}

.label-cylindre {
  margin-top: 0.7rem;
  min-width: 125px;
}

.title-custom-wizard {
  color: #4d25bc;
  margin-bottom: 0px;
}

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}

.min-w-150 {
  min-width: 150px;
}

#collapse-besoin .card:has(div.d-block.invalid-feedback, input.is-invalid) span h4 {
  color: #ea5455;
}

.w-80px {
  width: 80px !important;
}

.w-100px {
  width: 100px !important;
}
</style>

<style lang="scss" scoped>
label {
  font-weight: 900;
}

.w-125 {
  width: 125px;
}

.w-190 {
  width: 190px !important;
}

.h-40p {
  height: 40px;
}
</style>

<!-- 
<TheFormSpinbuttonWithInput
  id="my-number"
  class="mx-2"
  :size="'sm'"
  v-model="conducteur.bonus_moto"
  :step="0.01"
  :min="0.5"
  :max="3.5"
  inline
/>
-->
