<template>
    <div>
      <b-overlay :show="showLoading" no-wrap />
      <validation-observer ref="AssureRules">
      <b-row>
        <b-col md="12">
          <b-row>
            <b-col md="4">
              <b-form-group>
                <label for="date-effet" class="font-weight-normal">Date d'effet *</label>
                <span
                  v-b-popover.top.hover.html="`<p><strong>ECA :</strong> A partir du 16 du mois, la date d'effet minimum doit être le 1er jour du mois suivant.</p> <br><b>SPVIE</b> : La date d’effet minimum doit être la date du jour+1`"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'" title="Date d'effet" class="ml-1 cursor-pointer"
                  variant="outline-primary">
                  <feather-icon icon="InfoIcon" size="17" />
                </span>
                <flat-pickr class="form-control" autocomplete="off" v-model="objetSante.date_effet" id="date-effet" :config="configDateEffet"
                  placeholder="date d'effet" auto />
                <b-form-invalid-feedback :state="!$v.objetSante.date_effet.$error">
                  Veuillez renseigner le(s) champ(s) obligatoire(s)
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

          </b-row>
        </b-col>
        
      </b-row>
  
    <!--   <b-row class="mb-2">
        <b-col md="12">
          <label>Le client souhaite-t-il bénéficier de la loi Madelin ?</label><br>
          <b-form-radio-group
              label=""
              name="madelinValue"
              v-model="objetSante.loi_madelin"
              :options="BooleanValue1"
          ></b-form-radio-group>
        </b-col>
      </b-row> -->
  
      <b-row>
        <b-col md="12">
          <b-row>
            <b-col md="12">
              <h4 class="title-custom-wizard mt-2"><u>Assure principal (signataire)</u></h4></b-col>
            <b-col md="3">
              <b-form-group label="Date de naissance *" label-for="date-naissance">
                <flat-pickr class="form-control" autocomplete="off" v-model="prospect.date_naissance" id="date-naissance" :config="config"
                            placeholder="Date de naissance" data-max-date="today" />
                <b-form-invalid-feedback :state="!$v.prospect.date_naissance.$error">
                  Veuillez renseigner le(s) champ(s) obligatoire(s)
                </b-form-invalid-feedback>
                <b-form-invalid-feedback :state="errorDateNaissanceProspect ? false : null"> {{ errorDateNaissanceProspect }} </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <!-- Régime de sécurité sociale -->
          <b-col md="3">
            <b-form-group label="Régime de sécurité sociale *" label-for="regime">
              <validation-provider #default="{ errors }" name="régime de sécurité sociale" rules="required">
                <b-form-select v-model="prospect.regime" @change="changeRegime()"
                  :options="options.regime" :state="errors.length > 0 ? false : null" />
                  <b-form-invalid-feedback :state="!$v.prospect.regime.$error">
                  Veuillez renseigner le(s) champ(s) obligatoire(s)
                </b-form-invalid-feedback>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Régime AMEXA -->
          <b-col md="3" v-if="show_agricole">
            <b-form-group label="Régime AMEXA" label-for="regime">
              <validation-provider v-slot="{ errors }" name="regime amexa" rules="required">
                <b-form-radio-group v-model="objetSante.amexa" :options="options.agricoleOptions"></b-form-radio-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
            <b-col md="3">
              <b-form-group label="Code postal *" label-for="code postal">
                <cleave id="code-postal" v-model="prospect.code_postal" :options="options.codePostal" :raw="false" class="form-control" placeholder="Code postal" type="number" />
                <b-form-invalid-feedback :state="!$v.prospect.code_postal.$error">
                  Veuillez renseigner le(s) champ(s) obligatoire(s)
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col v-if="prospect.regime == 'RÉGIME_SOCIAL'">
                <b-form-group label="Le client souhaite-t-il bénéficier de la loi Madelin ?">
                  <validation-provider v-slot="{ errors }" name="madelinValue" rules="required">
                    <b-form-radio-group v-model="objetSante.loi_madelin" :options="BooleanValue"
                      label="madelinValue"></b-form-radio-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
          </b-row>
         <b-row class="my-1">
          <b-col md="12" v-if="!objetSante.loi_madelin">
            <b-alert-regime-non-salaries :regimeSelected="prospect.regime"/>
          </b-col>
         </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
            <b-row class="align-items-center">
                <b-col md="4">
                <b-form-group label="Nombre d'enfants à assurer *" label-for="enfant">
                    <b-form-input id="enfant" type="number" @keyup="validateNbrEnfant" v-mask="'##'" v-model="objetSante.enfantCharge" placeholder="Nombre d'enfants" />
                    <b-form-invalid-feedback :state="!$v.objetSante.enfantCharge.$error">
                    <span v-if="!$v.objetSante.enfantCharge.required">Veuillez renseigner le(s) champ(s) obligatoire(s)</span>
                    <span v-if="!$v.objetSante.enfantCharge.between" class="error">Veuillez renseigner une valeur entre 0 et
                        8</span>
                    </b-form-invalid-feedback>
                </b-form-group>
                </b-col>
                <b-col md="4">
                <label>Conjoint à assurer ?</label>
                <b-form-radio-group label="Option conjoint" id="conjoint_option" v-model="objetSante.conjoint"
                    :options="BooleanValue"></b-form-radio-group>
                </b-col>
            </b-row>
            </b-col>
      </b-row>
      <b-row>
        <b-col md="12" v-if="objetSante.conjoint">
          <h4 class="title-custom-wizard mt-2"><u>Conjoint</u></h4>
          <b-row>
            <b-col md="4">
              <b-form-group>
                <label for="date_naissance_conjoint">Date de naissance :</label>
                <flat-pickr class="form-control" autocomplete="off" v-model="conjoint.date_naissance" id="date_naissance_conjoint" :config="config"
                            placeholder="Date de naissance" data-max-date="today" />
              </b-form-group>
              <b-form-invalid-feedback :state="!$v.conjoint.date_naissance.$error">
                Veuillez renseigner le(s) champ(s) obligatoire(s)
              </b-form-invalid-feedback>
              <b-form-invalid-feedback :state="errorDateNaissanceConjoint ? false : null"> {{ errorDateNaissanceConjoint }} </b-form-invalid-feedback>
            </b-col>
            <b-col md="4">
                <b-form-group label="Régime de sécurité sociale *" label-for="regime">
                <validation-provider #default="{ errors }" name="régime de sécurité sociale" rules="required">
                    <b-form-select v-model="conjoint.regime"
                    :options="options.regime" :state="errors.length > 0 ? false : null" />
                    <b-form-invalid-feedback :state="!$v.conjoint.regime.$error">
                  Veuillez renseigner le(s) champ(s) obligatoire(s)
                </b-form-invalid-feedback>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }}
                    </b-form-invalid-feedback>
                </validation-provider>
                </b-form-group>
            </b-col>

          </b-row>
          <b-row class="my-1">
          <b-col md="12" v-if="!objetSante.loi_madelin">
            <b-alert-regime-non-salaries :regimeSelected="conjoint.regime"/>
          </b-col>
         </b-row>
        </b-col>
      </b-row>
      <b-row v-if="objetSante.enfantCharge>0">
        <b-col md="12">
          <b-row v-for="(enf,index) in enfantsArray" :key="index">
            <b-col md="12">
               <h4 class="title-custom-wizard mt-2"><u>{{ `Enfant ${index + 1}` }}</u></h4>
            </b-col>
            <b-col md="6" >
              <b-form-group>
                <label for="date_naissance">Date de naissance :</label>
                <flat-pickr class="form-control" autocomplete="off" v-model="enf.date_naissance" id="date-naissance" :config="config"
                  placeholder="Date de naissance" data-max-date="today" />
                <b-form-invalid-feedback :state="!$v.enfantsArray.$each[index].date_naissance.$error">
                  Veuillez renseigner le(s) champ(s) obligatoire(s)
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col md="6">
                <b-form-group label="Régime de sécurité sociale *" label-for="regime">
                <validation-provider #default="{ errors }" name="régime de sécurité sociale" rules="required">
                    <b-form-select v-model="enf.regime"
                    :options="options.regime" :state="errors.length > 0 ? false : null" />
                    <b-form-invalid-feedback :state="!$v.enfantsArray.$each[index].regime.$error">
                    Veuillez renseigner le(s) champ(s) obligatoire(s)
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }}
                    </b-form-invalid-feedback>
                </validation-provider>
                </b-form-group>
            </b-col>
            <b-col md="12" v-if="!objetSante.loi_madelin">
            <b-alert-regime-non-salaries :regimeSelected="enf.regime"/>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      
    </validation-observer>
      <b-row class="mt-4">
        <b-col lg="12">
            <b-button variant="primary" class="float-left" size="lg" @click="backStep"> Précédent </b-button>
            <b-button variant="primary" class="float-right" size="lg" @click="checkValidateField(0)"> Suivant
            </b-button>
        </b-col>
      </b-row>
    </div>
  </template>
  
  <script>
  import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
  
  import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
  import vSelect from 'vue-select'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import flatPickr from 'vue-flatpickr-component'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import BAlertRegimeNonSalaries from '@core/components/b-alert/BAlertRegimeNonSalaries.vue'
  import { mapGetters } from 'vuex'
  import moment from 'moment'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import { BRow, BCol, BOverlay, BFormGroup, BFormInput, BFormInvalidFeedback, BFormRadioGroup, BFormRadio, BTabs, BTab, BFormSelect, BTable, BTableSimple, BThead, BTr, BTh, BTd, BTbody, BButton, BCard, BCardText, BListGroup, BListGroupItem, BFormTextarea, VBTooltip, VBPopover, BForm, BCardBody, BAlert, BBadge } from 'bootstrap-vue'
  import { required, minLength, maxLength, between, email } from 'vuelidate/lib/validators'
  import Cleave from 'vue-cleave-component'
  
  export default {
    components: {
      Cleave,
      ValidationProvider,
      ValidationObserver,
      BRow,
      BCol,
      BOverlay,
      BFormGroup,
      BFormInput,
      vSelect,
      BFormInvalidFeedback,
      // eslint-disable-next-line vue/no-unused-components
      ToastificationContent,
      BAlertRegimeNonSalaries,
      BFormRadioGroup,
      BFormRadio,
      BTabs,
      BTab,
      flatPickr,
      BFormSelect,
      BTable,
      BTableSimple,
      BThead,
      BTr,
      BTh,
      BTd,
      BTbody,
      BButton,
      BCard,
      BCardText,
      BListGroup,
      BListGroupItem,
      BFormTextarea,
      BForm,
      BCardBody,
      BAlert,
      AppCollapse,
      AppCollapseItem,
      BBadge
    },
    directives: {
      'b-tooltip': VBTooltip,
      'b-popover': VBPopover,
    },
    data() {
      return this.initState()
    },
    validations: {
      objetSante: { 
        date_effet:  { required },
        enfantCharge:{ required,between: between(0,10) },
      },
      prospect: {
        date_naissance:{ required},
        code_postal:{ required},
        regime :{required},
      },
      conjoint: { 
        date_naissance:{ required },
        regime :{required},
      },
      enfantsArray: {
        $each: {
          date_naissance: {
            required
          },
          regime :{
            required
          },
        }
      },
    },
    computed: {
      ...mapGetters(["getQWCheckedRisque"]),
      // enfantsHasValidDateNaissance: function () {
      //   let enfantNovalidDate = _.filter(this.enfantsArray, enf => enf.date_naissance && moment().diff(enf.date_naissance.split("/").reverse().join("-"), 'years',true)>25 );
      //   return this.objetSante.enfantCharge==0 || enfantNovalidDate.length==0 ? true : false
      // }
    },
    watch: {
      'objetSante.enfantCharge': {
        handler: function (val, old) {
          if(val && val <= 10)
            this.addEnfants(val)
        },
        deep:true,
      },
      getQWCheckedRisque: {
        immediate: true,
        handler(val) {
          if(val) {
            this.getProduits(val)
          }
        }
      }
    },
    created() {
  
    },
    mounted() {
      this.objetSante.date_effet = this.getFirstDayOfNextMonth();
  },
    methods: {
      initDateEffet() {
        const today = moment()
        const DayOfMonth = today.clone().startOf('day').format('DD')
        if (DayOfMonth >= 15 && DayOfMonth <= 31) {
          today.set('month', today.month() + 1)
          today.startOf('month').startOf('day')
        } else {
          today.startOf('day')
        }

        this.objetSante.date_effet = moment(today).format('DD/MM/YYYY')
      },
      getFirstDayOfNextMonth() {
        const today = new Date();
        const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
        const day = String(nextMonth.getDate()).padStart(2, '0');
        const month = String(nextMonth.getMonth() + 1).padStart(2, '0'); 
        const year = nextMonth.getFullYear();
        return `${day}/${month}/${year}`;
      },
      handleToastDanger(text) {
        this.$toast({
          component: ToastificationContent,
          props: {
          title: text,
          icon: 'XIcon',
          variant: 'danger',
          },
        })
      },
      validationDateNaissance() {
        // Declaration
        const endDate = moment(this.objetSante.date_effet, 'DD/MM/YYYY').format('YYYY-MM-DD')
        const dateNaissanceAssurePrincipal = moment(this.prospect.date_naissance, 'DD/MM/YYYY').format('YYYY-MM-DD')
        if (moment(endDate).diff(dateNaissanceAssurePrincipal, "years", true) < 65) 
        {
          // this.errorDateNaissanceProspect = null
        } else {
          // this.errorDateNaissanceProspect = "L'assuré principal ne doit pas avoir plus de 65 ans"
          this.handleToastDanger('L\'assuré principal ne doit pas avoir plus de 65 ans')
          return false
        }
        if (this.objetSante.conjoint == 1) {
          const dateNaissanceConjoint = moment(this.conjoint.date_naissance, 'DD/MM/YYYY').format('YYYY-MM-DD')
          if (moment(endDate).diff(dateNaissanceConjoint, "years", true) < 65) {
            // this.errorDateNaissanceConjoint = null
          } else {
            // this.errorDateNaissanceConjoint = "Le conjoint ne doit pas avoir plus de 65 ans"
            this.handleToastDanger('Le conjoint ne doit pas avoir plus de 65 ans')
            return false
          }
        } else {
          this.errorDateNaissanceConjoint = null
        }
        return true
      },
      initState() {
        return {
          errorDateNaissanceProspect: null,
          errorDateNaissanceConjoint: null,
          showLoading: false,
          show_agricole:false,
          produit: {
            produit_id: '',
            risque_id: '',
            produit_nom: '',
            nom_assureur: '',
            nom_com_assureur: ''
          },
          objetSante:{
            date_effet:null,
            enfantCharge:0,
            conjoint: 0,
            taux_commission: 15,
            amexa : null,
            loi_madelin : 0,
            /* loi_madelin: false */
          },
          prospect:{
            date_naissance: null,
            code_postal: null,
            regime:null
          },
          conjoint:{
            date_naissance:null,
            type_lien_fam: 'CONJOINT',
            regime:null
          },
          enfantsArray:[],
          BooleanValue1: [
            { value: true, text: "Oui" },
            { value: false, text: "Non" },
          ],
          configDateEffet: {
              dateFormat: 'd/m/Y',
              disable: [
                  function(date) {
                      let today = new Date();
                      today.setDate(today.getDate())
                      today.setHours(0, 0, 0, 0); 
                      return date < today;
                  },
                  function(date) {
                      let today = new Date();
                      let oneYearFromToday = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());
                      oneYearFromToday.setHours(0, 0, 0, 0);
                      return date > oneYearFromToday;
                  }
              ],
              allowInput: true,
              onKeyDown: (selectedDates, dateStr, instance, event) => {
                  if (event.keyCode != 8) { // if not the backspace key
                      var ele = document.activeElement;
                      var val = ele.value;

                      if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                          val += "/";
                      }
                      ele.value = val;
                      ele.dispatchEvent(new Event("input"));
                  }
              },
          },
          config: {
            dateFormat: "d/m/Y",
            allowInput: true,
            onKeyDown: (selectedDates, dateStr, instance, event) => {
              if (event.keyCode != 8) {
                var ele = document.activeElement;
                var val = ele.value;
  
                if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                  val += "/";
                }
                ele.value = val;
                ele.dispatchEvent(new Event("input"));
              }
            },
          },
          BooleanValue: [
            { value: 1, text: "Oui" },
            { value: 0, text: "Non" },
          ],
          options: {
            departement: {
              blocks: [3],
            },
            codePostal: {
              blocks: [5]
            },
            regime : [
                {
                value: null,
                text: 'Choisissez',
                disabled: true,
                },
                {
                    value: 'REGIME_GENERAL',
                    text: 'Régime général de la sécurité sociale',
                },
                {
                    value: 'RÉGIME_SOCIAL',
                    text: 'Régime des travailleurs non salariés et non agricoles (ex RSI)',
                },
                {
                    value: 'REGIME_AGRICOLE',
                    text: 'Mutualité sociale agricole (AMEXA)',
                },
                {
                    value: 'REGIME_ALSACE_MOSELLE',
                    text: 'Régime Alsace-Moselle',
                }
            ],
            agricoleOptions: [
                {
                    value: 'SALARIE_AGRICOLE',
                    text: 'Salarié agricole',
                },
                {
                    value: 'EXPLOITANTS_AGRICOLES',
                    text: 'Exploitant agricole',
                },
            ],
          },
          tokens: {
            '#': {pattern: /[0-8]/}
          }
        }
      },
      changeRegime() {
          if (this.prospect.regime == "REGIME_AGRICOLE") {
              this.objetSante.loi_madelin = 0
              this.show_agricole = true
          }
          else if(this.prospect.regime != "RÉGIME_SOCIAL"){
          this.objetSante.loi_madelin = 0
          this.show_agricole = false
          }else{
            this.show_agricole = false
          }
        },
      getProduits(label) {
        this.$http.get(`ecaglobal/getProduitsByRisque/${label}`).then((response)=>{
          let risque = response.data
          if(Object.keys(risque).length != 0 && risque.produits.length > 0) {
            var produit = risque.produits[0]
            this.initProduit(produit)
            this.$store.commit("setProduitTarificateur", {produit: this.produit});
          } else {
            this.$store.commit("setProduitTarificateur", {produit: this.initState().produit});
          }
        }).catch((err)=>{
          
        })
      },
      initProduit(produit) {
        this.produit.produit_id       = produit.id
        this.produit.risque_id        = produit.risque_id
        this.produit.produit_nom      = produit.produit_nom
        this.produit.nom_assureur     = produit.assureur.nom_assureur
        this.produit.nom_com_assureur = produit.assureur.nom_com_assureur
      },
      validateNbrEnfant() {
        if(this.objetSante.enfantCharge > 10 || this.objetSante.enfantCharge < 0) {
           return this.objetSante.enfantCharge = 0 
        }
      },
      backStep() {
          this.$store.commit("setQWStepTarificateur", { step: { indexStep: 0, active: 'choix'}});
          this.$store.commit("setQWCheckedRisque", null);
          this.$store.commit("setQWHasProduitAutoriser", { hasProduitAutoriser: false});
          this.$store.commit("setProduitTarificateur", {produit: this.initState().produit});
      },
      addEnfants(nbrEnfant){
        this.enfantsArray = []
        for (let index = 0; index < nbrEnfant; index++) {
          this.enfantsArray.push({
            date_naissance:null,
            type_lien_fam: 'ENFANT',
            regime : null,
          })
        }
      },
      async checkValidateField(index){

        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        this.$v.$reset();
        this.$v.objetSante.$touch();
        this.$v.conjoint.$touch();
        this.$v.prospect.$touch();
        this.$v.enfantsArray.$touch();
        
      if (this.$v.objetSante.$error || this.$v.prospect.$error ||  (this.objetSante.conjoint && this.$v.conjoint.$error  ) || (this.objetSante.enfantCharge > 0 && this.$v.enfantsArray.$error) ) {
          return 0;
      }
      const isValidFields = await this.$refs.AssureRules.validate()
      if (!isValidFields) { this.showLoading = false; return 0 }
      
        this.getTarifData()
      },
      async getTarifData(){
        try {
          this.showLoading = true
          let data={
            conjoint:this.conjoint,
            enfant:this.enfantsArray,
            assure:this.prospect,
            objet: this.objetSante,
          }
          // const isValidDateNaissance = await this.validationDateNaissance()
         // if (!isValidDateNaissance) { this.showLoading = false; return 0 }
          await this.$http.post(`quickwins/calculTarifSanteActifs`,data).then(async (response) => {
            await this.$store.commit("setQWObjet", {objet: this.objetSante});
            await this.$store.commit("setQWProspect", {prospect: this.prospect});
            await this.$store.commit("setQWConjoint", {conjoint: this.conjoint});
            await this.$store.commit("setQWEnfants", {enfants: this.enfantsArray});
            await this.$store.commit("setQWItemsRisque", {itemsRisque: response.data.data});
            await this.$store.commit("setQWStepTarificateur", {step: {indexStep: 2, active: 'comparatif'}});
          }).catch(e => {
            this.$store.commit("setQWItemsRisque", { itemsRisque: []});
            this.showLoading = false
          })
          this.showLoading=false
          } catch (e) {
            this.showLoading = false
            this.errors = e.response.data.errors;
          }
      },
    }
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  @import '@core/scss/vue/pages/page-pricing.scss';
  
  .resume > .card > .card-header {
    background-color: #4d25bc !important;
    color: #fff;
    justify-content: center !important;
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  </style>
  <style lang="scss" scoped>
    label{
        font-weight: 900;
    }
    .title-custom-wizard{
        color: #4d25bc ;
        margin-bottom: 20px;
    }
  #risque_prevoyance.btn-group {
    display: inline-flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: space-between;
  }
  
  #risque_prevoyance > .sante_risque {
    min-width: calc(25% - 2rem);
    margin: 1rem;
    padding: 25px 15px;
    border-right: 1px solid #4d25bc !important;
    border-radius: 0.358rem;
  }
  
  #risque_prevoyance > .sante_risque.active {
    background-color: #4d25bc;
    color: #fff;
  }
  
  #risque_prevoyance .btn-outline-primary:hover:not(.disabled):not(:disabled) {
    background-color: #4d25bc;
    color: #fff;
    transition: 0.4s;
  }
  
  #addprojectform .nav-tabs .nav-link {
    background-color: rgba(77, 37, 188, 0.08);
    color: #4d25bc;
  }
  
  #addprojectform .nav-tabs .nav-link.active {
    color: #ffffff;
    background-color: #4d25bc;
  }
  
  .vue-form-wizard .wizard-navigation .wizard-nav li .wizard-icon-circle .wizard-icon-container {
    background-color: #4d25bc !important;
  }
  
  .vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked {
    border-color: #4d25bc !important;
  }
  
  .vue-form-wizard .wizard-navigation .wizard-nav li a .checked {
    background-color: #4d25bc !important;
  }
  
  .vue-form-wizard .wizard-navigation .wizard-nav li a .checked i {
    color: #fff !important;
  }
  
  .wizard-btn {
    background-color: #4d25bc !important;
    border-color: #4d25bc !important;
  }
  
  .vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn {
    border-color: #4d25bc !important;
    color: #4d25bc !important;
  }
  
  .vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn:hover {
    color: #fff !important;
    background-color: #4d25bc !important;
    transition: 0.3s;
    box-shadow: 0 8px 25px -8px #4d25bc;
  }
  
  .vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked ~ .stepTitle {
    color: #4d25bc !important;
  }
  
  @media only screen and (max-width: 768px) {
    #risque_prevoyance > .sante_risque {
      max-width: calc(50% - 2rem);
    }
  }
  
  #addprojectform h4 span {
    font-size: 15px;
    color: #000;
  }
  
  .offere_thClass th {
    background-color: #4d25bc !important;
    color: #fff;
  }
  
  .padding_pricing {
    padding: 0rem 1.5rem 1.5rem;
  }
  
  .propositions .card-body {
    padding: 0 !important;
  }
  
  .propositions .card button {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    padding: 15px 0px;
  }
  </style>
  <style>