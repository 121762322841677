<template>
  <div>
    <b-overlay :show="showLoading" no-wrap />

    <b-row v-if="risqueLocal[0].produitsFiltred.length > 0">
      <b-col class="mb-2" cols="12">
        <h2 class="mb-0 text-uppercase font-weight-bolder">étape 3 : Comparaison des offres</h2>
        <h6>Sélectionner les offres répondant le mieux aux besoins exprimés (2 minimum, 3 maximum)</h6>
      </b-col>
      <b-col lg="12">
        <b-button class="float-left my-2" size="lg" variant="primary" @click="backStep"> Précédent </b-button>

        <b-button v-if="checkIfHasRisqueLocal && hasProduitAutoriser" variant="primary" class="float-right" size="lg" :disabled="disablePoursuivre"  @click="creeDevis()">Poursuivre vers la création d'un devis</b-button>
        <b-button v-if="checkIfHasRisqueLocal && !hasProduitAutoriser" variant="primary" class="float-right my-2" size="lg" @click="redirecToDemandeCode()">Demande d'ouverture de code</b-button>
      </b-col>
      <transition name="fade">
        <b-col v-if="showCompareTable" cols="12">
          <div v-for="(rl, index) in risqueLocal" :key="index + 'content'" class="mb-5">
            <b-dropdown class="mb-2 float-right" right split text="Trier par" variant="outline-primary">
              <b-dropdown-item @click="filtreUpDown(index)">Prix décroissant</b-dropdown-item>
              <b-dropdown-item @click="filtreDownUp(index)">Prix croissant</b-dropdown-item>
              <!-- <b-dropdown-item @click="filtreByTaux(index,'DownUp')">Taux croissant</b-dropdown-item> -->
            </b-dropdown>

            <b-table-simple v-for="(pr, val) in rl.produitsFiltred" :key="val + 'produits'" bordered caption-top
              class="mb-4" hover responsive>
              <b-thead class="offere_thClass">
                <b-tr>
                  <b-th class="text-center" style="width: 15%">produit</b-th>
                  <b-th class="text-center" style="width: 15%">documents</b-th>
                  <b-th class="text-center" style="width: 25%">options</b-th>
                  <b-th class="text-center" style="width: 25%">franchises</b-th>
                  <b-th class="text-center" style="width: 20%">prix</b-th>
                </b-tr>
              </b-thead>
              <b-tbody class="offere_tdClass">
                <b-td class="text-center">
                  <p class="box-custom-text">{{ pr.produit.produit_nom }}</p>
                  <b-img :src="require('@/assets/images/logo/assureur/fma-assurances.png')" alt="logo" class="mb-1"
                    height="auto" width="100px" />
                  <p class="box-custom-text">{{ pr.produit.assureur.nom_com_assureur }}</p>
                </b-td>
                <b-td>
                  <div v-for="(pda, compt) in pr.produit.document_assureur" :key="compt + 'pda'">
                    <span v-if="pda.document" class="text-nowrap cursor-pointer"
                      @click="showFile(pda.document.id, pda.document.document_type.type)">
                      <feather-icon color="#bf2626" icon="FileIcon" size="14" style="margin-bottom: 4px" />
                      <span class="text-nowrap">{{ pda.document.document_type.type }}</span>
                    </span>
                  </div>
                </b-td>
                <b-td>
                  <b-row v-for="(pgr, cpt) in pr.produitsgaranties" :key="cpt + 'produit_garantie'">
                    <b-col
                      v-if="cpt !== 0 && hasGarantieProduitNiveau(pgr.id, pgr.produitgarantiesniveaux, pr.tarif, false)"
                      md="12">
                      <span v-b-tooltip.hover.right="pgr.descriptif" v-b-tooltip.hover.v-primary class="cursor-pointer">{{
                        pgr.libelle_comm }}</span>
                      <!-- <span>{{ pgr.libelle_comm }}</span> -->
                    </b-col>
                    <b-col v-if="cpt !== 0" md="12">
                      <b-form-select v-if="hasGarantieProduitNiveau(pgr.id, pgr.produitgarantiesniveaux, pr.tarif, false)"
                        :value="pgr.produitGarantieNiveauID[0]" class="mb-2" size="sm"
                        @change="onChangeNiveau($event, index, val, cpt)">
                        <b-form-select-option
                          v-for="(pgn, ind) in $_.orderBy(pgr.produitgarantiesniveaux, ['order'], ['asc'])"
                          :key="ind + 'garantiesProduitNiveaux'" :value="pgn.id"> {{ pgn.libelle_commercial
                          }}</b-form-select-option>
                      </b-form-select>
                    </b-col>
                  </b-row>

                  <b-row v-if="pr.produit.produitcommissions && pr.produit.produitcommissions.length > 0">
                    <b-col md="12">
                      <span>Taux de commission :</span>
                    </b-col>
                    <b-col md="12">
                      <b-form-select size="sm" v-model="pr.tarif.taux_commission"
                        @change="onChangeTaux($event, index, val)">
                        <b-form-select-option v-for="(prc, prcIndex) in pr.produit.produitcommissions"
                          :value="prc.commission_value" :key="prcIndex + 'produit-commision'"> {{ prc.commission_value }}
                        </b-form-select-option>
                      </b-form-select>
                    </b-col>
                  </b-row>
                </b-td>
                <b-td>
                  <b-row v-for="(pgr, cpt) in pr.produitsgaranties" :key="cpt + 'produit_garantie'">
                    <b-col
                      v-if="cpt !== 0 && hasGarantieProduitNiveau(pgr.id, pgr.produitgarantiesniveaux, pr.tarif, true)"
                      md="12">
                      <span v-b-tooltip.hover.right="pgr.descriptif" v-b-tooltip.hover.v-primary class="cursor-pointer">{{
                        pgr.libelle_comm }}</span>
                    </b-col>
                    <b-col v-if="cpt !== 0" md="12">
                      <b-form-select v-if="hasGarantieProduitNiveau(pgr.id, pgr.produitgarantiesniveaux, pr.tarif, true)"
                        :value="pgr.produitGarantieNiveauID[0]" class="mb-2" size="sm"
                        @change="onChangeNiveau($event, index, val, cpt)">
                        <b-form-select-option
                          v-for="(pgn, ind) in $_.orderBy(checkRAFR(pgr.produitgarantiesniveaux, pr.tarif.optionsCompatibles), ['order'], ['asc'])"
                          :key="ind + 'garantiesProduitNiveaux'" :value="pgn.id"> {{ pgn.libelle_commercial
                          }}</b-form-select-option>
                      </b-form-select>
                    </b-col>
                  </b-row>
                </b-td>
                <b-td>
                  <div class="text-center">
                    <div class="px-0">
                      <h4>{{ pr.tarif.formule_commercial }}</h4>

                      <b-badge
                        :variant="getGtNvByNiveauVariant(getGtNvByNiveau(pr.produit.produit_garanties[0], pr.tarif.codeFormule))"
                        class="mb-1">
                        {{ getGtNvByNiveau(pr.produit.produit_garanties[0], pr.tarif.codeFormule) }}
                      </b-badge>
                      <p class="text-left">{{ pr.tarif.formule_descriptif }}</p>
                      <p class="card-text">{{ pr.tarif.libelle == 'Enfant' ? 'Adulte Enfant' : pr.tarif.libelle }}</p>
                      <p class="card-text small-text" v-html="getLibelleRAFR(pr.tarif.optionsCompatibles)"></p>
                      <div class="annual-plan">
                        <div class="plan-price my-2">
                          <sup class="font-medium-1 font-weight-bold text-primary">€</sup>
                          <span class="pricing-basic-value font-weight-bolder text-primary">{{
                            Number(pr.tarif.tarif).toFixed(2) }}</span>
                          <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/mois</sub>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-td>
              </b-tbody>
            </b-table-simple>
          </div>
        </b-col>
      </transition>
    </b-row>
    <b-row v-else>
      <b-col lg="12">
        <h2 style="color:red;">Aucun tarif disponible pour la compagnie : FMA</h2>
      </b-col>
    </b-row>
    <app-collapse id="collapse-besoin" type="margin" class="mb-3">

      <app-collapse-item ref="tab-collapse-item-0" :is-visible="false"
        title="<h4 class='title-custom-wizard'><u>FMA - Détails garanties/franchises</u></h4>">
        <b-row>
          <b-col cols="12" class="mb-2" style="text-align: justify;">
            <p class="px-1">
              Garantie des équipements du conducteur
            <ul>
              <li><strong>Equip 300€</strong> couvre les équipements moto (blouson, pantalon, bottes) à concurrence de 300
                euros. Casque et gants garantis en valeur à neuf pendant 36 mois.
                <br>Avec application d'une franchise égale à 10% du montant des dommages avec un minimum de 76 euros.
              </li>
              <li><strong>Equip 750€</strong> couvre les équipements moto (blouson, pantalon, bottes) à concurrence de 750
                euros. Casque et gants garantis en valeur à neuf pendant 36 mois.
                <br>Avec application d'une franchise égale à 10% du montant des dommages avec un minimum de 76 euros.
              </li>
              <li><strong>Equip 1500€</strong> couvre les équipements moto (blouson, pantalon, bottes) sont couverts à
                concurrence de 1500 euros. Casque et gants garantis en valeur à neuf pendant 36 mois.
                <br>Avec application d'une franchise égale à 10% du montant des dommages avec un minimum de 76 euros
              </li>

            </ul>
            </p>

            <p class="px-1">
              Garantie dommages corporels du conducteur
            <ul>
              <li><strong>Protection du Pilote Essentielle</strong> indemnise le conducteur en cas d’atteinte permanente à
                l’intégralité
                physique et psychique partielle (capital de 250 000 euros multiplié par le taux d’AIPP défini par le
                médecin expert) et en cas de décès (20 000 euros au conjoint ou concubin, 7 500 euros par enfant à
                charge et remboursement des frais d’obsèques à hauteur de 4 000 euros, le total de ces indemnités de
                dépassera pas 250 000 euros). Le capital versé à l’assuré est doublé en cas de port du gilet airbag
                (jusqu'à 500 000 euros).</li>
              <li><strong>Protection du Pilote Etendue</strong> indemnise le conducteur en cas d’atteinte permanente à
                l’intégralité
                physique et psychique totale (capital de 500 000 euros), d’atteinte permanente à l’intégralité physique
                et psychique partielle (capital de 500 000 euros multiplié par le taux d’AIPP défini par le médecin
                expert) et en cas de décès (30 000 euros au conjoint ou concubin, 10 000 euros par enfant à charge et
                remboursement des frais d’obsèques à hauteur de 4 000 euros, le total de ces indemnités de dépassera pas
                500 000 euros). Le capital versé à l’assuré est doublé en cas de port du gilet airbag (jusqu'à 1 000 000
                euros).</li>
            </ul>
            </p>

            <p class="px-1">
              Garantie du contenu du Top Case
            <ul>
              <li><strong>Contenu du Top Case 250 </strong> garantie le vol des équipements du motard contenus dans le Top
                Case à hauteur de
                250€ (par sinistre et par an) suite à l’effraction du Top Case ou suite au vol de la moto.</li>
              <li><strong>Contenu du Top Case 500+</strong> garantie le vol des équipements du motard ainsi que le vol des
                clés et des
                papiers contenus dans le Top Case, à hauteur de 500€ (par sinistre et par an), suite à l’effraction du
                Top Case ou suite au vol de la moto.</li>
            </ul>
            </p>

            <p class="px-1">
              Garantie des accessoires du véhicule (hors série)
            <ul>
              <li><strong>Access 1 550 €</strong> couvre, en cas de sinistre, les accessoires hors série du véhicules à
                concurrence de 1 500 euros. Avec application d’une franchise égale à 10% du montant des dommages avec un
                minimum de 76 euros. </li>
              <li><strong>Access 3 000 €</strong> couvre, en cas de sinistre, les accessoires hors série du véhicules à
                concurrence de 3 000 euros. Avec application d’une franchise égale à 10% du montant des dommages avec un
                minimum de 76 euros.</li>
            </ul>
            </p>
            <p class="px-1">
              Garantie valeur d'acquisition du véhicule
            <ul>
              <li><strong> Valeur d'acquisition 12 mois</strong> indemnise le véhicule sur sa valeur d’acquisition. </li>
            </ul>
            </p>
            <p class="px-1">
              Franchise en cas de vol ou incendie (par sinistre)
            <ul>
              <li>Avec l’option <strong> Franchise de base (ou partielle)</strong> en cas de vol ou d’incendie, la
                franchise retenue et à la charge
                du souscripteur, est réduite au montant indiqué (sous réserve des conditions d’acceptation pour un rachat
                de franchise partiel).</li>
              <li>Avec l’option <strong> Franchise à 0 €</strong> en cas de vol ou d’incendie, aucune franchise n'est
                retenue (sous réserve
                des conditions d’acceptation).</li>
            </ul>
            </p>

            <p class="px-1">
              Franchise en cas de dommages à votre véhicule (par sinistre)
            <ul>
              <li>Avec l’option <strong> Franchise de base (ou partielle)</strong> Dommages, en cas de sinistre totalement
                ou partiellement
                responsable, une franchise réduite au montant indiqué est retenue et à charge du souscripteur (sous
                réserve des conditions d’acceptation pour un rachat de franchise partiel).</li>
              <li>Avec l’option <strong> Franchise à 0 €</strong> Dommages, en cas de sinistre totalement ou partiellement
                responsable,
                aucune franchise n'est retenue (sous réserve des conditions d’acceptation).</li>
            </ul>
            </p>
          </b-col>
        </b-row>
      </app-collapse-item>
    </app-collapse>

    <b-row>
      <b-col lg="12">
        <b-button class="float-left" size="lg" variant="primary" @click="backStep"> Précédent </b-button>
        <b-button v-if="checkIfHasRisqueLocal && hasProduitAutoriser" variant="primary" class="float-right" :disabled="disablePoursuivre"  size="lg" @click="creeDevis()">Poursuivre vers la création d'un devis</b-button>
        <b-button v-if="checkIfHasRisqueLocal && !hasProduitAutoriser" variant="primary" class="float-right" size="lg" @click="redirecToDemandeCode()">Demande d'ouverture de code</b-button>
      </b-col>
    </b-row>
    <b-modal id="modal-display-document" ref="modal-display-document"
      :size="document.extensionDocument === 'PDF' ? 'xl' : 'sm'" :title="document.name" ok-only ok-title="Fermer"
      ok-variant="outline-secondary" @ok="clearDocumentDisplay">
      <form ref="form" :style="{ height: document.extensionDocument === 'PDF' ? '80vh' : 'auto', overflow: 'hidden' }">
        <b-img v-if="document.extensionDocument === 'IMAGE'" :alt="document.name" :src="document.base64" fluid />
        <iframe v-else-if="document.extensionDocument === 'PDF'" :src="document.base64" height="100%" width="100%"
          :style="hideToolbar()" />
      </form>
    </b-modal>
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { mapGetters } from 'vuex'
import { BBadge, BButton, BCol, BDropdown, BDropdownItem, BFormSelect, BFormSelectOption, BImg, BModal, BOverlay, BRow, BTableSimple, BTbody, BTd, BTh, BThead, BTr, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { selectRisqueAndProduct, updateProduitTarif } from '../../../../shared/utils/TarifCalcul'

export default {
  components: {
    BOverlay,
    BBadge,
    BRow,
    BImg,
    BCol,
    BFormSelect,
    BFormSelectOption,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BButton,
    BDropdown,
    BDropdownItem,
    AppCollapseItem,
    AppCollapse,
    BModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return this.initState()
  },
  computed: {
    ...mapGetters(['getQWSelectedRisque']),
    ...mapGetters(['getQWObjet']),
    ...mapGetters(['getQWItemsRisque']),
    ...mapGetters(['getProduitTarificateur']),
    ...mapGetters(['getQWHasProduitAutoriser']),
    ...mapGetters(['getQWCheckedRisque']),
    ...mapGetters(['getQWProspect']),
    checkIfHasRisqueLocal() {
      if (this.risqueLocal.length > 0 && this.risqueLocal[0].produitsFiltred.length > 0) {
        return true
      }
      return false
    },
  },
  watch: {
    getQWObjet: {
      handler(val) {
        this.objet = val
      },
    },
    getQWProspect: {
      handler(val) {
        this.assure = val
      }
    },
    getQWItemsRisque: {
      handler(val) {
        if (val.length > 0) {
          this.risqueLocal = val
          this.arrayProduit()
          this.statistiqueObjet = this.initState().statistiqueObjet
          this.statistiqueQWTarif()
        }
      },
    },
    getProduitTarificateur: {
      handler(val) {
        this.produitLocal = val.produit
        this.initProduitAutoriser(this.produitLocal)
      },
    },
    hasProduitAutoriser: {
      immediate: true,
      handler(val) {
        this.$store.commit('setQWHasProduitAutoriser', { hasProduitAutoriser: val })
      },
    },
    getQWCheckedRisque: {
      immediate: true,
      handler(val) {
        this.checkedRisque = val
      },
    }
  },
  methods: {
    creeDevis() {
      this.disablePoursuivre = true
      this.updateStatistiqueQWTarif(1, 0)
      this.addProspectWithDevis()
    },
    async addProspectWithDevis() {
      this.showLoading = true
      const prodpectData = {
        civilite: this.assure.civilite,
        situation_familiale: this.assure.situation_familiale,
        statut: this.assure.csp,
        date_naissance: this.assure.date_naissance,
        s_is_conducteur : this.objet.s_is_conducteur
      }

      this.$http
        .post('prospect/saveorupdateQWPersonnePhysique', prodpectData)
        .then(res => {
          if (res.status) {
            const prospectData = res.data.prospect
            this.recordDevis(prospectData)
          }
        })
        .catch(() => {
          this.showLoading = false
          this.disablePoursuivre = false
        })
    },
    async recordDevis(prospect) {
      let etudeData = {}
      let etudeNom = `Devis ${this.checkedRisque} Pour Tarificateur`
      etudeData.prospect = prospect
      etudeData.etude = etudeNom

      await this.$http.post('portefeuilles/initEtude', etudeData).then(res => {
        this.getEtude.etudeID = res.data
      }).catch(e => {
        console.log(e)
        this.disablePoursuivre = false
      })
      this.getEtude.etudeNom = etudeNom

      await this.$http.get(`portefeuilles/getEtudeSingle/${this.getEtude.etudeID}`).then(res => {
        this.getEtude.etude_no = res.data.data.etude_no
        this.getEtude.created_at = res.data.data.created_at
      }).catch(e => {
        console.log(e)
        this.disablePoursuivre = false
      })

      const data = {
        risques: [{
          risque: {
            id: this.produitLocal.risque_id,
            label: '2-Roues',
            type: 'IARD'
          },
          devis: null,
          objets: { objet_moto: this.objet },
        }],
        prospect,
        etudeID: this.getEtude.etudeID,
        tarificationRapideID: this.statistiqueObjet.id
      }

      await this.$http.post('quickwins/getQWObjectPortefeuille', data).then(() => {
        this.$router.push({
          name: 'projets-proposition',
          params: { id: this.getEtude.etudeID }
        })
        this.showLoading = false
      }).catch(() => {
        this.showLoading = false
        this.disablePoursuivre = false
      })
    },
    statistiqueQWTarif() {
      this.statistiqueObjet.courtier_fk = this.currentUser.courtier_user[0].courtier_id
      this.statistiqueObjet.user_fk = this.currentUser.courtier_user[0].user_id
      this.statistiqueObjet.risque_fk = this.produitLocal.risque_id
      this.statistiqueObjet.risque_nom  = this.checkedRisque
      this.statistiqueObjet.tarifs      = this.risqueLocal[0].produitsFiltred
      this.statistiqueObjet.qw_tarif    = {
        "assure"    : this.assure,
        "objet"     : this.objet
      } 

      this.$http.post('/tarification-rapide/addOrUpdateTarififcationRapide', this.statistiqueObjet).then(res => {
        if (res.data.success) {
          this.statistiqueUpdated = false
          this.statistiqueObjet.id = res.data.data.id
        }
      })
    },
    updateStatistiqueQWTarif(marketplace, demandeCode) {
      this.showLoading = true
      if (!this.statistiqueUpdated) {
        if (this.checkIfHasRisqueLocal) {
          if (this.hasProduitAutoriser && marketplace) this.statistiqueObjet.marketplace = 1
          else if (demandeCode) {
            this.statistiqueObjet.demandeCode = 1
          }

          this.$http.post('/tarification-rapide/addOrUpdateTarififcationRapide', this.statistiqueObjet).then(res => {
            if (res.data.success) {
              this.statistiqueUpdated = true
            }
          }).catch((e) => {
          this.showLoading = false
          this.disablePoursuivre = false
        });
        }
      }
      this.showLoading = false
    },
    initState() {
      return {
        getEtude: {
          etudeID: null,
          etudeNo: null,
          etude_n: null,
          created: null
        },
        assure: null,
        hasProduitAutoriser: false,
        produitLocal: {
          produit_id: '',
          risque_id: '',
          produit_nom: '',
          nom_assureur: '',
        },
        objet: null,
        statistiqueObjet: {
          id: null,
          courtier_fk: null,
          user_fk: null,
          risque_fk: null,
          marketplace: 0,
          demandeCode: 0,
          risque_nom: null,
          tarifs: [],
          qw_tarif: {}
        },
        currentUser: JSON.parse(localStorage.getItem('userData')),
        checkerExplicationOptions: [],
        optionsFrequencePaiement: [
          { value: 'MOIS', text: 'Mensuelle' },
          { value: 'ANNEE', text: 'Annuelle' },
        ],
        risqueLocal: [],
        produitGarantieNiveau: [],
        tarif: Math.floor(Math.random() * 100) + 60,
        loading: false,
        indexLocal: 0,
        test: null,
        indexRisque: 0,
        hasProduct: false,
        showCompareTable: true,
        showLoading: false,
        document: {
          base64: null,
          name: null,
          nameToDownload: null,
          extensionDocument: null,
        },
        disablePoursuivre:false

      }
    },
    initProduitAutoriser(produit) {
      const produitsAutorised = this.currentUser.courtier_user[0].courtier.produits_autorise
      if (produitsAutorised.length > 0) {
        const result = produitsAutorised.find(p => p.produit_id === produit.produit_id)
        if (result !== undefined) {
          this.hasProduitAutoriser = true
        } else {
          this.hasProduitAutoriser = false
        }
      } else {
        this.hasProduitAutoriser = false
      }
    },
    getLibelleRAFR(optionsCompatibles) {
      const blocLibelleRAFR = []
      if (optionsCompatibles.length > 0) {
        optionsCompatibles.forEach(option => {
          console.log()
          if (option.RAFRx !== undefined) blocLibelleRAFR.push(`Vol/Incendie - ${option.RAFRx.libelleGarantie2} ${this.getlibraf3(optionsCompatibles)}`)
          if (option.RAFRy !== undefined) blocLibelleRAFR.push(`Dommages - ${option.RAFRy.libelleGarantie2} ${this.getlibraf4(optionsCompatibles)}`)
        })
      }
      return blocLibelleRAFR.length > 0 ? blocLibelleRAFR.join('</br>') : null
    },
    getlibraf3(optionsCompatibles) {
      let blocLibelleRAFR = ''
      if (optionsCompatibles.length > 0) {
        optionsCompatibles.forEach(option => {
          if (option.RAFR3 !== undefined) blocLibelleRAFR = `et, pour un rachat partiel, ${option.RAFR3.libelleGarantie2}`
        })
      }
      return blocLibelleRAFR
    },
    getlibraf4(optionsCompatibles) {
      let blocLibelleRAFR = ''
      if (optionsCompatibles.length > 0) {
        optionsCompatibles.forEach(option => {
          if (option.RAFR4 !== undefined) blocLibelleRAFR = `et, pour un rachat partiel, ${option.RAFR4.libelleGarantie2}`
        })
      }
      return blocLibelleRAFR
    },
    checkRAFR(produitGarantieNiveaux, optionsCompatibles) {
      let produitGarantieNiveauxChanged = []
      let RAFR3 = false
      let RAFR4 = false
      if (optionsCompatibles.length > 0) {
        optionsCompatibles.forEach(option => {
          if (option.RAFR3) {
            RAFR3 = true
          } else if (option.RAFR4) {
            RAFR4 = true
          }
        })

        if (!RAFR3 && !RAFR4) {
          produitGarantieNiveauxChanged = produitGarantieNiveaux.filter(pgn => pgn.libelle !== 'RAFR3' && pgn.libelle !== 'RAFR4')
        } else if (!RAFR3 && RAFR4) {
          produitGarantieNiveauxChanged = produitGarantieNiveaux.filter(pgn => pgn.libelle !== 'RAFR3')
        } else if (RAFR3 && !RAFR4) {
          produitGarantieNiveauxChanged = produitGarantieNiveaux.filter(pgn => pgn.libelle !== 'RAFR4')
        } else {
          produitGarantieNiveauxChanged = produitGarantieNiveaux
        }
      }
      return produitGarantieNiveauxChanged
    },
    getGarantieProduit(produitGarantieId, produitgarantiesniveaux) {
      const found = produitgarantiesniveaux.find(pg => pg.produit_garantie_id === produitGarantieId)
      return found ? found.libelle_commercial : ''
    },
    getGarantieProduitCommercial(garantie, produitGarantie) {
      const found = produitGarantie.find(pg => pg.garantie_id === garantie)
      return found ? found.libelle_comm : ''
    },
    hasGarantieNiveau(gn, options) {
      const tmpOption = _.find(options.optionsCompatibles, (v, key) => key === gn)
      return !!tmpOption
    },
    hasGarantieProduitNiveau(produitGarantieId, produitgarantiesniveaux, options, ifFranchise) {
      const listFranchisesChecker = ['RAFR2', 'RAFR1', 'RAFRx', 'RAFRy', 'RAFR3', 'RAFR4']
      let found
      if (ifFranchise) {
        found = produitgarantiesniveaux.find(pg => pg.produit_garantie_id === produitGarantieId && listFranchisesChecker.includes(pg.libelle))
      } else {
        found = produitgarantiesniveaux.find(pg => {
          if (listFranchisesChecker.includes(pg.libelle)) {
            return false
          }
          return pg.produit_garantie_id === produitGarantieId && !pg.libelle.includes('non')
        })
      }

      if (found) {
        const tmpOption = _.find(options.optionsCompatibles, (v, key) => {
          return Object.keys(v).length > 0 ? Object.keys(v)[0] == found.libelle : false
        })
        return !!tmpOption
      }
      return false
    },
    getGtNvByNiveauVariant(niveau) {
      switch (niveau) {
        case 'Economique':
          return 'light-success'
        case 'Intermédiaire':
          return 'light-warning'
        case 'Haut de gamme':
          return 'light-danger'
        default:
          return 'light-success'
      }
    },
    getGtNvByNiveau(produitGarantie, niveau) {
      const tmpGNPOption = _.find(produitGarantie.garantiesniveaux, value => value.pivot.libelle == niveau)
      return tmpGNPOption ? tmpGNPOption.garantie_niveau_libel : ''
    },
    titleClass(word) {
      return word ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : ''
    },
    updateIndexDevis(value) {
      this.indexRisque = value
      this.$store.commit('setSelectedRisque', {
        selectedRisque: value,
      })
    },
    dupliquer(risque, produit) {
      const produitD = this.risqueLocal[risque].produits[produit]
      this.risqueLocal[risque].produits.push(produitD)
    },
    selectionnerProduit(risque, produit) {
      const produitsCpt = this.risqueLocal[risque].produitsFiltred.filter(pr => pr.tarif.presente)
      if (produitsCpt.length < 3) {
        this.risqueLocal[risque].produitsFiltred[produit].tarif.presente = true
      }
      if (produitsCpt.length == 3) {
        this.messageToast('', 'Vous ne pouvez pas sélectionner plus de 3 offres', 'danger', 'EditIcon')
      }
    },
    deselectionnerProduit(risque, produit) {
      this.risqueLocal[risque].produitsFiltred[produit].tarif.presente = false
      this.risqueLocal[risque].produitsFiltred[produit].tarif.preconise = false
    },
    getPtGtNvExig(risque, produit, exigence, formule) {
      const result = this.risqueLocal[risque].produitsFiltred[produit].produit.produit_garanties[0].produitgarantiesniveaux.filter(pgn => pgn.libelle === formule)
      if (result.length > 0) {
        if (result[0].exigencesniveaux.length > 0) {
          const resultFiltre = result[0].exigencesniveaux.filter(egn => egn.exigence_id === exigence)
          return resultFiltre.length > 0 ? resultFiltre[0].exigence_niveau_libel : ''
        }
      } else {
        return ''
      }
    },
    getPtGtNvExigCorrespondant(risque, produit, exigence, formule) {
      const result = this.risqueLocal[risque].produitsFiltred[produit].produit.produit_garanties[0].produitgarantiesniveaux.filter(pgn => pgn.libelle === formule)
      if (result.length > 0) {
        if (result[0].exigencesniveaux.length > 0) {
          const resultFiltre = result[0].exigencesniveaux.filter(egn => egn.exigence_id === exigence)
          return resultFiltre.length > 0 ? (resultFiltre[0].pivot ? resultFiltre[0].pivot.label_correspondant : '') : ''
        }
      } else {
        return ''
      }
    },

    comparerExig(ex1, ex2) {
      return ex1 == ex2
    },
    filtreDownUp(risque) {
      this.showCompareTable = false
      setTimeout(() => {
        this.risqueLocal[risque].produitsFiltred.sort((a, b) => a.tarif.tarif - b.tarif.tarif)
        this.showCompareTable = true
      }, 10)
    },
    filtreUpDown(risque) {
      this.showCompareTable = false
      setTimeout(() => {
        this.risqueLocal[risque].produitsFiltred.sort((a, b) => b.tarif.tarif - a.tarif.tarif)
        this.showCompareTable = true
      }, 10)
    },
    filtreByTaux(risque, type) {
      this.showCompareTable = false
      setTimeout(() => {
        this.risqueLocal[risque].produitsFiltred = type == 'UpDown' ? this.sortByTauxDec(this.risqueLocal[risque].produitsFiltred) : this.sortByTauxCr(this.risqueLocal[risque].produitsFiltred)
        this.showCompareTable = true
      }, 10)
    },
    backStep() {
      this.clearData()
    },
    clearData() {
      this.$store.commit('setQWStepTarificateur', { step: { indexStep: 1, active: 'besoins' } })
      this.$store.commit('setQWObjet', { objet: null })
      this.$store.commit('setQWProspect', { prospect: null })
      this.$store.commit('setQWConjoint', { conjoint: null })
      this.$store.commit('setQWEnfants', { enfants: [] })
      this.$store.commit('setQWItemsRisque', { itemsRisque: [] })
    },
    getTarif(selected, pg, tarif) {
      const found = pg.find(pgv => pgv.id === selected)
      const tmpTarif = found ? tarif.find(tf => tf.formule === found.libelle) : 'NaN'
      return tmpTarif.tarif
    },
    getScore(value) {
      return value ? (value * 100).toFixed(1) : '0'
    },
    arrayProduit() {
      let produitFiltre = []
      if (this.risqueLocal.length > 0) {
        this.risqueLocal.map(risque => {
          produitFiltre = []
          risque.produitsFiltred = []
          risque.produits.map(produit => {
            if (produit.tarif) {
              produit.tarif.map(tf => {
                const { tarif, ...newProduit } = produit
                const garantiesLocal = tf.garanties
                const produitsGarantiesLocal = tf.produitsgaranties
                const { garanties, ...tarifLocal } = tf
                produitFiltre.push({
                  produit: newProduit,
                  garanties: garantiesLocal,
                  produitsgaranties: produitsGarantiesLocal,
                  tarif: tarifLocal,
                })
              })
            }
          })
          if (produitFiltre.length > 0) {
            const data = {
              produits: produitFiltre,
              exigences: risque.exigences,
              garanties: risque.garanties,
              risque: risque.risque.label,
              objets: risque.objets,
            }
            const tmpProduits = selectRisqueAndProduct(data)

            risque.produitsFiltred = this.sortByTauxDec(tmpProduits)
          }
        })
      }
    },
    showFile(id, type) {
      this.displayDocument(id, type, type)
    },
    sortByTauxDec(data) {
      return data.sort((a, b) => b.tarif.tauxGn - a.tarif.tauxGn || b.tarif.tauxCouv - a.tarif.tauxCouv || a.tarif.tarif.tarif - b.tarif.tarif.tarif)
    },
    sortByTauxCr(data) {
      function DownUpcompare(a, b) {
        if (a.tarif.taux == b.tarif.taux) {
          if (a.tarif.tauxtype < b.tarif.tauxtype) {
            return -1
          }
          if (a.tarif.tauxtype > b.tarif.tauxtype) {
            return 1
          }
        } else {
          if (a.tarif.taux < b.tarif.taux) {
            return -1
          }
          if (a.tarif.taux > b.tarif.taux) {
            return 1
          }
        }
        return 0
      }

      data.sort(DownUpcompare)
      return data
    },
    getExigence(egn, egnArray) {
      const tmpOption = _.find(egnArray, value => value.id === egn)
      return tmpOption ? tmpOption.exigence_niveau_libel : ''
    },
    formatText(str) {
      const map = {
        '-': ' ',
        '-': '_',
        a: 'á|à|ã|â|ä|À|Á|Ã|Â|Ä',
        e: 'é|è|ê|ë|É|È|Ê|Ë',
        i: 'í|ì|î|ï|Í|Ì|Î|Ï',
        o: 'ó|ò|ô|õ|ö|Ó|Ò|Ô|Õ|Ö',
        u: 'ú|ù|û|ü|Ú|Ù|Û|Ü',
        c: 'ç|Ç',
        n: 'ñ|Ñ',
      }
      for (const pattern in map) { str = str.replace(new RegExp(map[pattern], 'g'), pattern) }

      return str.split(' ').join('').toUpperCase()
    },

    async onChangeNiveau(event, index, val, gr) {
      const produitTmp = this.risqueLocal[index].produitsFiltred[val]
      const ProduitsGarantiesTmp = this.risqueLocal[index].produitsFiltred[val].produitsgaranties

      ProduitsGarantiesTmp[gr].produitGarantieNiveauID[0] = event

      const { objets } = this.risqueLocal[index]
      const result = updateProduitTarif(ProduitsGarantiesTmp, produitTmp, this.risqueLocal[index].risque.label, objets)

      this.risqueLocal[index].produitsFiltred[val].tarif.tarif = result.prime
    },

    // ---------------------------------------------
    onChangeTaux(event, index, val) {
      this.showLoading = true
      const data = {
        txCom: event,
        assure: this.risqueLocal[index].objets.objet_moto.assure,
        conducteur: this.risqueLocal[index].objets.objet_moto.conducteur,
        fullFormule: false,
        formule: this.risqueLocal[index].produitsFiltred[val].tarif.codeFormule,
        produitId: this.risqueLocal[index].produitsFiltred[val].produit.id
      }

      this.$http
        .post('quickwins/getQwTarif2Roues', data)
        .then(response => {
          console.log(response.data)
          this.risqueLocal[index].produitsFiltred[val].tarif.tarif = response.data[0].tarif
          this.risqueLocal[index].produitsFiltred[val].tarif.tarif_annuel = response.data[0].tarif_annuel
          this.risqueLocal[index].produitsFiltred[val].tarif.tarif_base = response.data[0].tarif_base
          this.risqueLocal[index].produitsFiltred[val].tarif.tarif_annuel_base = response.data[0].tarif_annuel_base
          this.risqueLocal[index].produitsFiltred[val].tarif.optionsCompatibles = response.data[0].optionsCompatibles

          this.risqueLocal[index].produitsFiltred[val].tarif.produitsgaranties.map((pg, j) => {
            if (pg.produitGarantieNiveauID !== undefined && pg.produitGarantieNiveauID.length > 0) {
              this.onChangeNiveau(pg.produitGarantieNiveauID[0], index, val, j)
            }
          })
          this.$store.commit('setQWItemsRisque', { itemsRisque: this.risqueLocal })
          this.showLoading = false
        })
        .catch(() => {
          this.showLoading = false
        })
    },
    clearDocumentDisplay() {
      this.document.base64 = null
      this.document.name = null
      this.document.nameToDownload = null
      this.document.extensionDocument = null
    },
    displayDocument(id, name, NomDuDocument) {
      this.$http
        .get(`/document/generate_base64_for_document/${id}`)
        .then(res => {
          if (res.data.success) {
            this.clearDocumentDisplay()
            if (!res.data.data.isDownload && res.data.data.extensionDocument === 'IMAGE') {
              this.document.base64 = res.data.data.base64
              this.document.name = NomDuDocument || name
              this.document.nameToDownload = name
              this.document.extensionDocument = res.data.data.extensionDocument
              this.$refs['modal-display-document'].show()
            } else if (!res.data.data.isDownload && res.data.data.extensionDocument === 'PDF') {
              this.document.base64 = res.data.data.base64
              this.document.name = NomDuDocument || name
              this.document.nameToDownload = name
              this.document.extensionDocument = res.data.data.extensionDocument
              this.$refs['modal-display-document'].show()
            }
          } else {
            this.clearDocumentDisplay()
            this.messageToast(res.data.message, 'Erreur', 'error')
          }
        })
        .catch(err => {
          this.clearDocumentDisplay()
          this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
          console.error(err)
        })
    },
    redirecToDemandeCode() {
      this.updateStatistiqueQWTarif(0, 1)
      const isAdministrateurCabinet = this.currentUser.courtier_user[0].isPrincipal
      const civiliteAdministrateurCabinet = this.capitalizeFirstLetter(this.currentUser.courtier_user[0].courtier.courtier_user[0].user.personne_physique.civilite.toLowerCase())
      const prenomAdministrateurCabinet = this.currentUser.courtier_user[0].courtier.courtier_user[0].user.personne_physique.prenom
      const nomAdministrateurCabinet = this.currentUser.courtier_user[0].courtier.courtier_user[0].user.personne_physique.nom
      if (isAdministrateurCabinet) {
        this.$router.push({name: 'fiche-demande-ouverture-code', query: {tarification_rapide: this.statistiqueObjet.id}})
      } else {
        this.$swal({
          html: `<p class="text-justify" style="font-size: 15px">Pour faire une demande de code, merci de vous rapprocher de votre administrateur de cabinet : ${civiliteAdministrateurCabinet} ${prenomAdministrateurCabinet} ${nomAdministrateurCabinet}.</p>`,
          icon: 'info',
          showCancelButton: false,
          showConfirmButton: false
        })
      }
    },
    // ShowFile(document) {
    //   var str = document.path + document.nom
    //   var width = 1000
    //   var height = 600
    //   var left = parseInt(screen.availWidth / 2 - width / 2)
    //   var top = parseInt(screen.availHeight / 2 - height / 2)
    //   var windowFeatures = 'width=' + width + ',height=' + height + ',status,resizable,left=' + left + ',top=' + top + 'screenX=' + left + ',screenY=' + top
    //   window.open(path, 'subWind', windowFeatures)
    // }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/pages/page-pricing.scss';

.offere_thClass th {
  background-color: #ebe9f1 !important;
  color: #4d25bc;
}
</style>

<style lang="scss" scoped>
#risque_prevoyance.btn-group {
  display: inline-flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-between;
}

#risque_prevoyance>.sante_risque {
  min-width: calc(25% - 2rem);
  margin: 1rem;
  padding: 25px 15px;
  border-right: 1px solid #4d25bc !important;
  border-radius: 0.358rem;
}

#risque_prevoyance>.sante_risque.active {
  background-color: #4d25bc;
  color: #fff;
}

#risque_prevoyance .btn-outline-primary:hover:not(.disabled):not(:disabled) {
  background-color: #4d25bc;
  color: #fff;
  transition: 0.4s;
}

#addprojectform .nav-tabs .nav-link {
  background-color: rgba(77, 37, 188, 0.08);
  color: #4d25bc;
}

#addprojectform .nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #4d25bc;
}

.vue-form-wizard .wizard-navigation .wizard-nav li .wizard-icon-circle .wizard-icon-container {
  background-color: #4d25bc !important;
}

.vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked {
  border-color: #4d25bc !important;
}

.vue-form-wizard .wizard-navigation .wizard-nav li a .checked {
  background-color: #4d25bc !important;
}

.vue-form-wizard .wizard-navigation .wizard-nav li a .checked i {
  color: #fff !important;
}

.wizard-btn {
  background-color: #4d25bc !important;
  border-color: #4d25bc !important;
}

.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn {
  border-color: #4d25bc !important;
  color: #4d25bc !important;
}

.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn:hover {
  color: #fff !important;
  background-color: #4d25bc !important;
  transition: 0.3s;
  box-shadow: 0 8px 25px -8px #4d25bc;
}

.vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked~.stepTitle {
  color: #4d25bc !important;
}

@media only screen and (max-width: 768px) {
  #risque_prevoyance>.sante_risque {
    max-width: calc(50% - 2rem);
  }
}

#addprojectform h4 span {
  font-size: 15px;
  color: #000;
}

.padding_pricing {
  padding: 0rem 1.5rem 1.5rem;
}

.propositions .card-body {
  padding: 0 !important;
}

.propositions .card-header {
  background-color: #4d25bc;
  color: #fff;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2rem;
}

.propositions .card button {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 15px 0px;
}

.box-custom-text {
  color: #7353ca;
}

.small-text {
  font-size: 12px;
  text-align: left;

}
</style>
