<template>
  <div>
    <b-overlay :show="showLoading" no-wrap />

      <b-row v-if="checkIfHasRisqueLocal">
        <b-col cols="12" class="mb-2">
          <h2 class="mb-0 text-uppercase font-weight-bolder d-inline-block">
            étape 3 : Comparaison des offres
          </h2>
        </b-col>
          <b-col lg="12">
              <b-button variant="primary" class="float-left my-2" size="lg" @click="backStep">
                  Précédent
              </b-button>
              <b-button v-if="checkIfHasRisqueLocal && hasProduitAutoriser" variant="primary" class="float-right" :disabled="disablePoursuivre" size="lg" @click="creeDevis()">Poursuivre vers la création d'un devis</b-button>
              <b-button v-if="checkIfHasRisqueLocal && !hasProduitAutoriser" variant="primary" class="float-right my-2" size="lg" @click="redirecToDemandeCode()">Demande d'ouverture de code</b-button>
          </b-col>
          <b-col lg="12">
            <b-alert variant="warning" show v-show="getAssureursRisque()" class="mt-1">
              <div class="alert-body">
                <p v-html="getAssureursRisque()"></p>
              </div>
            </b-alert>
          </b-col>
        <transition name="fade">
          <b-col cols="12" v-if="showCompareTable">
            <div v-for="(rl, index) in this.risqueLocal" :key="index + 'content'" class="mb-5">
              <b-dropdown variant="outline-primary" class="mb-2 float-right" right split text="Trier par">
                <b-dropdown-item @click="filtreUpDown(index)">Prix décroissant</b-dropdown-item>
                <b-dropdown-item @click="filtreDownUp(index)">Prix croissant</b-dropdown-item>
                <!-- <b-dropdown-item @click="filtreByTaux(index,'UpDown')">Adéquation</b-dropdown-item> -->
              </b-dropdown>

              <b-table-simple bordered hover caption-top responsive v-for="(pr, val) in rl.produitsFiltred"
                :key="val + 'produits'" class="mb-4">
                <b-thead class="offere_thClass">
                  <b-tr>
                    <b-th style="width:20%;" class="text-center">produit</b-th>
                    <b-th style="width:25%;" class="text-center">documents</b-th>
                    <b-th style="width:15%;" class="text-center">compléments</b-th>
                    <b-th style="width:25%;" class="text-center">couverture</b-th>
                    <b-th style="width:15%;" class="text-center">prix</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody class="offere_tdClass">
                  <b-td class="text-center">
                    <p class="box-custom-text">{{ pr.produit.produit_nom }}</p>
                      <b-img :src="require('@/assets/images/logo/assureur/eca-assurances.png')" alt="logo" class="mb-1" height="auto" width="100px" />
                    <p class="box-custom-text">{{ pr.produit.assureur.nom_com_assureur }}</p>
                  </b-td>
                  <b-td>
                    <div v-for="(pda,compt) in $_.orderBy(pr.produit.document_assureur, ['document.document_type.type'], ['asc'])" :key="compt+'pda'">
                      <span class="text-nowrap cursor-pointer"
                        v-if="pda.document && pda.produit_garantie_id && hasSurComp(pr.tarif)"
                        @click="showFile(pda.document.id,pda.document.document_type.type)">
                        <feather-icon style="margin-bottom: 4px;" icon="FileIcon" size="14" color="#bf2626" />
                        <span class="text-nowrap">{{pda.document.document_type.type}}
                          Surcomplémentaire </span>
                      </span>
                      <span class="text-nowrap cursor-pointer" v-else-if="pda.document && !pda.produit_garantie_id"
                        @click="showFile(pda.document.id,pda.document.document_type.type)">
                        <feather-icon style="margin-bottom: 4px;" icon="FileIcon" size="14" color="#bf2626" />
                        <span class="text-nowrap">{{pda.document.document_type.type}}</span>
                      </span>
                    </div>
                  </b-td>
                  <b-td>
                    <b-row>
                      <b-col md="12" v-if="rl.objets.objet_ijh.isConjoint">
                        <b-form-group label="Indemnité journalière du conjoint" label-for="Indemnité journalière du conjoint">
                          <b-form-select class="mb-2" size="sm" @change="onChangeCapitalConjoint($event, index, val)" v-model="pr.tarif.formuleConjoint">
                              <b-form-select-option v-for="(gn, ind) in getFilteredGarantiesNiveaux(rl, pr.tarif.formule_comm)" :key="ind + 'garantiesNiveaux'" :value="gn.pivot.libelle"> Capital: {{gn.pivot.libelle}} €</b-form-select-option>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-td>
                  <b-td>
                    <b-row>
                      <b-col class="m-0 p-0" lg="12">
                          <span>Indemnité journalière de l'assuré: {{ pr.tarif.formule }} €</span>
                      </b-col>
                    </b-row>
                  </b-td>
                  <b-td>
                    <div class="text-center">
                      <div class="px-0">
                        <h4>Formule {{ pr.tarif.formule_comm}}</h4>
                        <p class="card-text">
                          {{ pr.tarif.tarif.libelle}}</p>
                        <div class="annual-plan">
                          <div class="plan-price mt-2">
                            <sup class="font-medium-1 font-weight-bold text-primary">€</sup>
                            <span
                              class="pricing-basic-value font-weight-bolder text-primary">{{ pr.tarif.tarif.tarif}}</span>
                            <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/mois</sub>
                          </div>
                        </div>
                        <p class="card-text mt-1" style="font-size:12px" v-if="pr.tarif.tarif.tarif_pmss">
                          Soit {{ pr.tarif.tarif.tarif_pmss}} % du PMSS <span v-show="hasPmssPrivilege">***</span>
                        </p>
                      </div>
                    </div>
                  </b-td>
                </b-tbody>
              </b-table-simple>
            </div>
          </b-col>
        </transition>
      </b-row>
      <section id="knowledge-base-search" v-else>
        <!-- <b-card class="knowledge-base-bg custom-tarif  mb-4">
          <h3 class="text-primary">
            Nous sommes désolés. Aucune offre n'est disponible pour les informations saisies.
          </h3>
          <ul class="mt-2">
            <li>
              <span>Pour rappel :</span>
              <ul class="mt-1">
                <li class="font-weight-bolder">L'âge limite est de 65 ans.</li>
                <li class="font-weight-bolder">La date d'effet doit être sur l'année en cours.</li>
                <li class="font-weight-bolder">Certains départements ne sont pas éligibles.</li>
              </ul>
            </li>
          </ul>
        </b-card> -->
        <b-col lg="12">
          <b-alert variant="warning" show  class="mt-1">
            <div class="alert-body">
              <p v-html="getAssureursRisque()"></p>
            </div>
          </b-alert>
        </b-col>
      </section>
      <b-row v-show="ifChooseSurComp()">
        <b-col cols="12" class="mb-2">
          <h6>
            ** L’option surcomplémentaire permet de bénéficier de garanties renforcées chez les praticiens non adhérents à l’OPTAM
          </h6>
        </b-col>
      </b-row>
      <b-row v-show="hasPmssPrivilege">
        <b-col cols="12" class="mb-2">
          <h6>
            *** Pour {{currentYear+1}}, le tarif est donné à titre indicatif sur la base d’une estimation du PMSS
          </h6>
        </b-col>
      </b-row>
      <b-row>
          <b-col lg="12">
              <b-button variant="primary" class="float-left" size="lg" @click="backStep">
                  Précédent
              </b-button>
              <b-button v-if="checkIfHasRisqueLocal && hasProduitAutoriser" variant="primary" class="float-right" :disabled="disablePoursuivre"  size="lg" @click="creeDevis()">Poursuivre vers la création d'un devis</b-button>
              <b-button v-if="checkIfHasRisqueLocal && !hasProduitAutoriser" variant="primary" class="float-right" size="lg" @click="redirecToDemandeCode()">Demande d'ouverture de code</b-button>
          </b-col>
      </b-row>
      <b-modal id="modal-display-document" ref="modal-display-document"
        :size="document.extensionDocument === 'PDF' ? 'xl' : 'sm'" :title="document.name" ok-only
        ok-variant="outline-secondary" ok-title="Fermer" @ok="clearDocumentDisplay">
        <form ref="form"
          :style="{height : document.extensionDocument === 'PDF' ? '80vh' : 'auto', 'overflow': 'hidden'}">
          <b-img v-if="document.extensionDocument === 'IMAGE'" :alt="document.name" :src="document.base64" fluid />
          <iframe v-else-if="document.extensionDocument === 'PDF'" :src="document.base64" height="100%" width="100%"
            :style="hideToolbar()" />
        </form>
      </b-modal>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { mapGetters } from 'vuex'
import { selectRisqueAndProduct,updateProduitTarif } from './../../../../shared/utils/TarifCalcul'
import {
  BOverlay,
  BImg,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BButton,
  BFormSelectOption,
  BDropdown,
  BDropdownItem,
  BModal,
  VBTooltip,
  BBadge,
  BAlert
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BFormGroup,
    BImg,
    BFormSelect,
    BFormSelectOption,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BButton,
    BDropdown,
    BDropdownItem,
    BModal,
    BBadge,
    BAlert
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return this.initState()
  },
  computed: {
    ...mapGetters(['getQWSelectedRisque']),
    ...mapGetters(['getQWObjet']),
    ...mapGetters(['getQWItemsRisque']),
    ...mapGetters(['getQWProspect']),
    ...mapGetters(['getQWConjoint']),
    ...mapGetters(['getProduitTarificateur']),
    ...mapGetters(['getQWHasProduitAutoriser']),
    ...mapGetters(['getQWCheckedRisque']),
    hasPmssPrivilege() {
      if (this.risqueLocal.length > 0) {
        const dateEffetYear = moment(this.risqueLocal[0].objets.objet_ijh.date_effet, 'DD/MM/YYYY').year()
        if (this.currentYear + 1 == dateEffetYear) {
          return true
        }
      }
      return false
    },
    checkIfHasRisqueLocal() {
      return this.risqueLocal.length > 0 && this.risqueLocal[0].produitsFiltred.length > 0
    },
  },
  watch: {
    getQWObjet: {
      handler(val) {
        this.objet = val
      }
    },
    getQWProspect: {
      handler(val) {
        this.assure = val
      }
    },
    getQWConjoint: {
      handler(val) {
        this.conjoint = val
      }
    },
    getQWItemsRisque: {
      handler(val) {
        if (val.length > 0) {
          this.risqueLocal = val
          this.arrayProduit()
          this.getAssureursRisque()
          this.statistiqueObjet = this.initState().statistiqueObjet
          this.statistiqueQWTarif()
        }
      }
    },
    getProduitTarificateur: {
      handler(val) {
        this.produitLocal = val.produit
        this.initProduitAutoriser(this.produitLocal)
      }
    },
    hasProduitAutoriser: {
      immediate: true,
      handler(val) {
        this.$store.commit('setQWHasProduitAutoriser', { hasProduitAutoriser: val})
      }
    },
    getQWCheckedRisque: {
      immediate: true,
      handler(val) {
        this.checkedRisque = val
      }
    }
  },
  methods: {
    getFilteredGarantiesNiveaux(pr, formule) {
      // Check if '20' exists
      const formule20Exists = pr.produitsFiltred.some(produit => produit.tarif.formuleConjointFix == 20)
        // Filter guarantees based on the provided formule
        const filteredGarantiesNiveaux = pr.garanties[0].produit_garantie[0].garantiesniveaux.filter(gn => {
          const libelle = parseInt(gn.pivot.libelle, 10)
          if (formule20Exists) {
            return (libelle === 20 || libelle >= 40) && libelle <= formule
          } else {
            return libelle >= 40 && libelle <= formule
          }
        })

        return filteredGarantiesNiveaux
    },
    onChangeCapitalConjoint($event, index, val) {
      const data = {
        capitalConjoint: $event,
        capitalAssure: this.risqueLocal[index].produitsFiltred[val].tarif.formule_comm,
        tarification_id: null,
        dateEffet: this.risqueLocal[index].objets.objet_ijh.dateEffet,
        situation_familiale: this.assure.situation_familiale,
        dateNaissance: this.assure.dateNaissance,
        dateNaissanceConjoint: this.conjoint.dateNaissance,
        isTarifRapid: true
      }
      this.$http.post('portefeuilles/updateTarification', data)
        .then(response => {
          if (response.data.error === false) {
            // Update the UI with the new data
            this.risqueLocal[index].produitsFiltred[val].tarif.tarif.tarif = response.data.data.prime
            this.risqueLocal[index].produitsFiltred[val].tarif.tarif.tarif_base = response.data.data.prime
            this.risqueLocal[index].produitsFiltred[val].tarif.formuleConjoint = response.data.data.capital_conjoint
          } else {
            this.risqueLocal[index].produitsFiltred[val].tarif.formuleConjoint = this.risqueLocal[index].produitsFiltred[val].tarif.formuleConjointFix
            this.messageToast('', 'Un problème est survenu, merci de contacter le support', 'danger', 'EditIcon')
          }
        })
        .catch(() => {
          this.risqueLocal[index].produitsFiltred[val].tarif.formuleConjoint = this.risqueLocal[index].produitsFiltred[val].tarif.formuleConjointFix
          this.messageToast('', 'Un problème est survenu, merci de contacter le support', 'danger', 'EditIcon')
        })
        // const data = [capital_conjoint = $event, ]
    },
    explicationExigenceLibel(exProduitsDetails, produitSelected) {
      const expDetail = exProduitsDetails.find(exp => exp.produit_id == produitSelected)
      return expDetail != undefined ? expDetail.info : ''
    },
    initState() {
      return {
        checkedRisque: null,
        getEtude: {
          etudeID: null,
          etudeNo: null,
          etude_n: null,
          created: null
        },
        hasProduitAutoriser: false,
        showCompareTable: true,
        showLoading: false,
        produitLocal: {
          produit_id: '',
          risque_id: '',
          produit_nom: '',
          nom_assureur: '',
        },
        statistiqueUpdated: false,
        statistiqueObjet: {
          id: null,
          courtier_fk: null,
          user_fk: null,
          risque_fk: null,
          marketplace: 0,
          demandeCode: 0,
          risque_nom: null,
          tarifs: [],
          qw_tarif: {}
        },
        indexRisque: null,
        objet: null,
        assure: null,
        conjoint: null,
        risqueLocal: [],
        produitsFiltred: [],
        document: {
          base64: null,
          name: null,
          nameToDownload: null,
          extensionDocument: null
        },
        currentUser: JSON.parse(localStorage.getItem('userData')),
        currentYear: moment(new Date(), 'DD/MM/YYYY').year(),
        disablePoursuivre: false
      }
    },
    statistiqueQWTarif() {
      this.statistiqueObjet.courtier_fk = this.currentUser.courtier_user[0].courtier_id
      this.statistiqueObjet.user_fk = this.currentUser.courtier_user[0].user_id
      this.statistiqueObjet.risque_fk = this.produitLocal.risque_id
      this.statistiqueObjet.risque_nom = this.checkedRisque
      this.statistiqueObjet.tarifs = this.risqueLocal[0].produitsFiltred
      this.statistiqueObjet.qw_tarif = {
        assure: this.assure,
        conjoint: this.conjoint,
        objet: this.objet
      }

      this.$http.post('/tarification-rapide/addOrUpdateTarififcationRapide', this.statistiqueObjet).then(res => {
        if (res.data.success) {
          this.statistiqueUpdated = false
          this.statistiqueObjet.id = res.data.data.id
        }
      })
    },
    updateStatistiqueQWTarif(marketplace, demandeCode) {
      this.showLoading = true
      if (!this.statistiqueUpdated) {
        if (this.checkIfHasRisqueLocal) {
          if (this.hasProduitAutoriser && marketplace) {
            this.statistiqueObjet.marketplace = 1
          } else if (demandeCode) {
            this.statistiqueObjet.demandeCode = 1
          }

          this.$http.post('/tarification-rapide/addOrUpdateTarififcationRapide', this.statistiqueObjet).then(res => {
            if (res.data.success) {
              this.statistiqueUpdated = true
            }
          }).catch(e => {
            this.showLoading = false
            this.disablePoursuivre = false
          })
        }
      }
      this.showLoading = false

    },
    async recordDevis(prospect) {
      let etudeData = {}
      let etudeNom = 'Devis ' + this.checkedRisque + ' Pour Tarificateur'
      etudeData['prospect'] = prospect
      etudeData['etude'] = etudeNom

      const response = (await this.$http.post('portefeuilles/initEtude', etudeData)).data

      this.getEtude.etudeID = response
      this.getEtude.etudeNom = etudeNom

      this.$http.get(`portefeuilles/getEtudeSingle/${response}`).then(res => {

        this.getEtude.etude_no = res.data.data.etude_no
        this.getEtude.created_at = res.data.data.created_at
      }).catch(e => {
        console.log(e)
        this.disablePoursuivre = false

      })

      let data = {
        risques: [{
          risque: {
            id: this.produitLocal.risque_id,
            label: 'IJH',
            type: 'ADP'
          },
          devis: null,
          objets: {
            objet_ijh: this.objet
          },
        }],
        prospect: prospect,
        etudeID: this.getEtude.etudeID,
        tarificationRapideID: this.statistiqueObjet.id
      }

      this.$http.post('quickwins/getQWObjectPortefeuille', data).then(res => {
        this.$router.push({
          name: 'projets-proposition',
          params: { id: this.getEtude.etudeID }
        })
        this.showLoading = false
      }).catch(() => {
        this.showLoading = false
        this.disablePoursuivre = false

      })
    },
    initProduitAutoriser(produit) {
      let produitsAutorised = this.currentUser.courtier_user[0].courtier.produits_autorise
      if (produitsAutorised.length > 0) {
        const result = produitsAutorised.find(p => p.produit_id == produit.produit_id)
        if (result !== undefined) {
          this.hasProduitAutoriser = true
        } else {
          this.hasProduitAutoriser = false
        }
      } else {
        this.hasProduitAutoriser = false
      }
    },
    creeDevis() {
      this.disablePoursuivre = true
      this.updateStatistiqueQWTarif(1, 0)
      this.addProspectWithDevis()
    },
    async addProspectWithDevis() {
      this.showLoading = true
      this.$http
        .post('prospect/saveorupdateQWPersonnePhysique', this.assure)
        .then(r => {
          if (r.status) {
            const prospectData = r.data.prospect
            this.addConjoint(prospectData)
            this.recordDevis(prospectData)
          }
        })
        .catch(e => {
          this.showLoading = false
        })
    },
    async addConjoint(prospect) {
      if (this.conjoint.dateNaissance) {
        let data = {
          prospect: prospect,
          connexite: [this.conjoint]
        }
        this.$http
          .post('prospect/saveQWConnexitePhysique', data)
          .then(r => {
            if (r.status) {
            }
          })
          .catch(() => {
            this.disablePoursuivre = false

          })
      }
    },
    redirecToDemandeCode() {
      this.updateStatistiqueQWTarif(0, 1)
      let isAdministrateurCabinet = this.currentUser.courtier_user[0].isPrincipal
      const civiliteAdministrateurCabinet = this.capitalizeFirstLetter(this.currentUser.courtier_user[0].courtier.courtier_user[0].user.personne_physique.civilite.toLowerCase())
      const prenomAdministrateurCabinet = this.currentUser.courtier_user[0].courtier.courtier_user[0].user.personne_physique.prenom
      const nomAdministrateurCabinet = this.currentUser.courtier_user[0].courtier.courtier_user[0].user.personne_physique.nom
      if (isAdministrateurCabinet) {
        this.$router.push({name: 'fiche-demande-ouverture-code', query: {tarification_rapide: this.statistiqueObjet.id}})
      } else {
        this.$swal({
          html: `<p class="text-justify" style="font-size: 15px">Pour faire une demande de code, merci de vous rapprocher de votre administrateur de cabinet : ${civiliteAdministrateurCabinet} ${prenomAdministrateurCabinet} ${nomAdministrateurCabinet}.</p>`,
          icon: 'info',
          showCancelButton: false,
          showConfirmButton: false
        })
      }
    },
    clearDocumentDisplay() {
      this.document.base64 = null
      this.document.name = null
      this.document.nameToDownload = null
      this.document.extensionDocument = null
    },
    getAssureursRisque() {
      let innerHTML = null
      let assureurs = this.risqueLocal[0].produits.reduce((assureurs, pr) => {
        if (pr.tarif <= 0) {
          assureurs.push(`${pr.assureur.nom_com_assureur} (${pr.produit_nom})`)
        }
        return assureurs
      }, [])

      if (assureurs.length > 0) {
        innerHTML = `Aucun tarif disponible pour ${assureurs.length == 1 ? 'la compagnie' : 'les companies'} : <strong>${assureurs.join(', ')}</strong>`
      }
      return innerHTML
    },
    displayDocument(id, name, NomDuDocument) {
      this.$http
        .get(`/document/generate_base64_for_document/${id}`)
        .then(res => {
          if (res.data.success) {
            this.clearDocumentDisplay()
            if (!res.data.data.isDownload && res.data.data.extensionDocument === 'IMAGE') {
              this.document.base64 = res.data.data.base64
              this.document.name = NomDuDocument || name
              this.document.nameToDownload = name
              this.document.extensionDocument = res.data.data.extensionDocument
              this.$refs['modal-display-document'].show()
            } else if (!res.data.data.isDownload && res.data.data.extensionDocument === 'PDF') {
              this.document.base64 = res.data.data.base64
              this.document.name = NomDuDocument || name
              this.document.nameToDownload = name
              this.document.extensionDocument = res.data.data.extensionDocument
              this.$refs['modal-display-document'].show()
            }
          } else {
            this.clearDocumentDisplay()
            this.messageToast(res.data.message, 'Erreur', 'error')
          }
        })
        .catch(err => {
          this.clearDocumentDisplay()
          this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
          console.error(err)
        })
    },
    showFile(id,type){
      this.displayDocument(id,type,type)
    },
    async onChangeNiveau(event,index,val,gr) {
      let produitTmp =this.risqueLocal[index].produitsFiltred[val]
      let risqueTmp =this.risqueLocal[index].risque
      let garantiesTmp=this.risqueLocal[index].produitsFiltred[val].garanties
      garantiesTmp[gr].garantieNiveauID[0]=event

      let objets=this.risqueLocal[index].objets
      let result=await updateProduitTarif(garantiesTmp,produitTmp,this.risqueLocal[index].risque.label,objets)
      this.risqueLocal[index].produitsFiltred[val].tarif.tarif=result
    },
    async onChangeTaux(event,index,val){
      this.showLoading=true
      let data={
        tauxCommision:event,
        produitTarif:this.risqueLocal[index].produitsFiltred[val].tarif,
        produitAssureur:this.risqueLocal[index].produitsFiltred[val].produit.assureur.nom_assureur,
        objet:this.risqueLocal[index].objets,
        risque:this.risqueLocal[index].risque,
        assure:this.assure
      }
      await this.$http.post('portefeuilles/getTarifByFormule',data).then(response=>{
        if (response.data.statut==201) {
          this.risqueLocal[index].produitsFiltred[val].tarif.tarif=response.data.data.tarif
          this.risqueLocal[index].produitsFiltred[val].tarif.tarif_base=response.data.data.tarif_base
          this.risqueLocal[index].produitsFiltred[val].tarif.optionsCompatibles=response.data.data.optionsCompatibles
          let produitTmp =this.risqueLocal[index].produitsFiltred[val]
          let garantiesTmp=this.risqueLocal[index].produitsFiltred[val].garanties
          let result=updateProduitTarif(garantiesTmp,produitTmp,this.risqueLocal[index].risque.label,this.risqueLocal[index].objets)
          this.risqueLocal[index].produitsFiltred[val].tarif.tarif=result
          this.showLoading=false
        }
      }).catch(err=>{
        this.showLoading=false
      })
    },
    titleClass(word){
      return word ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : ''
    },
    getExByScoringNumber (ex1) {
      switch (ex1){
        case 'NON':
          return 0
        case 'MIN':
          return 1
        case 'MOYEN':
          return 2
        case 'FORT':
          return 3
        case 'MAX':
          return 4
        case 'MAXPLUS':
          return 5
        default:
          return 1
      }
    },
    getPtGtNvExig(risque,produit,exigence,formule){
      let result = this.risqueLocal[risque].produitsFiltred[produit].produit.produit_garanties[0].produitgarantiesniveaux.filter(pgn => pgn.libelle === formule)
      if (result.length>0){
        if (result[0].exigencesniveaux.length> 0){
          let resultFiltre=result[0].exigencesniveaux.filter(egn => egn.exigence_id === exigence)
          return resultFiltre.length>0 ? resultFiltre[0].exigence_niveau_libel : ''
        }
      } else {
        return ''
      }
    },
    comparerExig(ex1,ex2){
      let i=this.getExByScoringNumber(ex1)
      let j=this.getExByScoringNumber(ex2)
      if (i<j) return 1
      else if (i>j) return -1
      else return 0
    },
    getExigence(egn,egnArray){
      let tmpOption=_.find(egnArray, (value,key) => value.id==egn)
      return tmpOption ? tmpOption.exigence_niveau_libel : ''
    },
    getPtGtNvExigCorrespondant(risque,produit,exigence,formule){
      let result = this.risqueLocal[risque].produitsFiltred[produit].produit.produit_garanties[0].produitgarantiesniveaux.filter(pgn => pgn.libelle === formule)
      if (result.length>0){
        if (result[0].exigencesniveaux.length> 0){
          let resultFiltre=result[0].exigencesniveaux.filter(egn => egn.exigence_id === exigence)
          return resultFiltre.length>0 ? resultFiltre[0].pivot ? resultFiltre[0].pivot.label_correspondant : '': ''
        }
      } else {
        return ''
      }
    },
    getGarantieProduit(garantie,produitGarantie){
      let found = produitGarantie.find(pg => pg.garantie_id === garantie)
      return found ? found.libelle : ''
    },
    getGarantieProduitCommercial(garantie,produitGarantie){
      let found = produitGarantie.find(pg => pg.garantie_id === garantie)
      return found ? found.libelle_comm : ''
    },
    hasGarantieNiveau(gn,options){
      let tmpOption=_.find(options.optionsCompatibles, (v,key) => key==gn)
      return tmpOption ? true : false
    },
    ifHasSurComp(produit){
      produit.hasSurComp=false
      if (produit.garanties && produit.garanties.length>0){
        produit.garanties.map(gn=>{
          let result=_.find(gn.garantieniveaux, function(gnr) { return gnr.id == gn.garantieNiveauID[0] && gnr.garantie_niveau_libel=='Oui' })
          if (result){
            produit.hasSurComp=true
          }
        })
      }
      return produit
    },
    hasSurComp(tarif) {
      let surComp = tarif.optionsCompatibles
      return (Array.isArray(surComp) && surComp.length <= 0) ? false : true
    },
    getGtNvByNiveauVariant(niveau) {
      switch (niveau) {
        case 'Economique':
          return 'light-success'
        case 'Complète':
          return 'light-warning'
        case 'Haut de gamme':
          return 'light-danger'
        default:
          return 'light-success'
      }
    },
    arrayProduit(){
      let produitFiltre=[]
      if (this.risqueLocal.length>0){
        this.risqueLocal.map(risque=>{
          produitFiltre=[]
          risque.produitsFiltred=[]
          risque.produits.map(produit=>{
            if (produit.tarif){
              produit.tarif.map(tf=>{
                const { tarif, ...newProduit } = produit
                let garantiesLocal=tf.garanties
                const {garanties, ...tarifLocal} = tf
                produitFiltre.push({
                  produit:newProduit,
                  garanties: garantiesLocal,
                  tarif:tarifLocal
                })
              })
            }
          })
          if (produitFiltre.length>0){
          // let data={
          //   produits:produitFiltre,
          //   exigences:risque.exigences,
          //   garanties:risque.garanties,
          //   risque:risque.risque.label,
          //   objets:risque.objets
          // }
          // let tmpProduits=selectRisqueAndProduct(data)

            risque.produitsFiltred = this.sorByPrice('UP', produitFiltre)

            this.sorByPrice('UP', risque.produitsFiltred)
          // risque.produitsFiltred=this.sortByTauxDec(tmpProduits);
          }
        })
      }
    },
    sorByPrice(typeSort, produitFiltre) {
      switch (typeSort) {
        case 'UP':
          return produitFiltre.sort((a, b) => a.tarif.tarif.tarif - b.tarif.tarif.tarif)
        case 'DOWN':
          return produitFiltre.sort((a, b) => b.tarif.tarif.tarif - a.tarif.tarif.tarif)
        default:
          return produitFiltre.sort((a, b) => b.tarif.tarif.tarif - a.tarif.tarif.tarif)
      }
    },
    getGtNvByNiveau(garantie,niveau){
      let tmpGNPOption = null
      garantie.produit_garantie.forEach(element => {
        element.garantiesniveaux.forEach(element1 => {
          if (element1.pivot.libelle == niveau) {
            tmpGNPOption = element1.garantie_niveau_libel
            return tmpGNPOption
          }
        })
      })
      return tmpGNPOption
    },
    filtreDownUp(risque){
      this.showCompareTable=false
      setTimeout(() => {
        this.risqueLocal[risque].produitsFiltred.sort((a, b) => a.tarif.tarif.tarif - b.tarif.tarif.tarif)
        this.showCompareTable=true
      }, 10)
    },
    filtreUpDown(risque){
      this.showCompareTable=false
      setTimeout(() => {
        this.risqueLocal[risque].produitsFiltred.sort((a, b) => b.tarif.tarif.tarif - a.tarif.tarif.tarif)
        this.showCompareTable=true
      }, 10)
    },
    filtreByTaux(risque,type){
      this.showCompareTable=false
      setTimeout(() => {
        this.risqueLocal[risque].produitsFiltred=type=='UpDown' ? this.sortByTauxDec(this.risqueLocal[risque].produitsFiltred) : this.sortByTauxCr(this.risqueLocal[risque].produitsFiltred)
        this.showCompareTable=true
      }, 10)
    },
    sortByTauxDec(data){
      return data.sort(function (a, b) {
        return b.tarif.tauxGn - a.tarif.tauxGn || b.tarif.tauxCouv - a.tarif.tauxCouv || a.tarif.tarif - b.tarif.tarif 
      })
    },
    sortByTauxCr(data){
      function DownUpcompare( a, b ) {
        if (a.tarif.tauxGn == b.tarif.tauxGn){
          if (a.tarif.tauxCouv == b.tarif.tauxCouv){
            if ( a.tarif.tarif < b.tarif.tarif ){
              return 1
            }
            if ( a.tarif.tarif > b.tarif.tarif ){
              return -1
            }

          } else {
            if ( a.tarif.tauxCouv < b.tarif.tauxCouv ){
              return -1
            }
            if ( a.tarif.tauxCouv > b.tarif.tauxCouv ){
              return 1
            }
          }
        }
        else {
          if ( a.tarif.tauxGn < b.tarif.tauxGn ){
            return -1
          }
          if ( a.tarif.tauxGn > b.tarif.tauxGn ){
            return 1
          }
        }
        return 0
      }
      data.sort(DownUpcompare)
      return data
    },
    ifChooseSurComp(){
      if (this.risqueLocal.length > 0 && this.risqueLocal[0].produitsFiltred && this.risqueLocal[0].produitsFiltred.length>0){
        let choosedSurComp=_.find(this.risqueLocal[0].produitsFiltred, (value,key) => value.hasSurComp)
        return choosedSurComp ? true : false
      } else {
        return false
      }
    },
    backStep(){
      this.clearData()
    },
    clearData() {
      this.$store.commit('setQWStepTarificateur', { step: { indexStep: 1, active: 'besoins'}})
      this.$store.commit('setQWObjet', {objet: null})
      this.$store.commit('setQWProspect', {prospect: null})
      this.$store.commit('setQWConjoint', {conjoint: null})
      this.$store.commit('setQWItemsRisque', {itemsRisque: []})
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import '@core/scss/vue/pages/page-pricing.scss';
@import '@core/scss/vue/pages/page-knowledge-base.scss';

.offere_thClass th {
background-color: #ebe9f1  !important;
color: #4d25bc;
}
</style>
<style lang="scss" scoped>
#risque_prevoyance.btn-group {
display: inline-flex;
flex-wrap: wrap;
text-align: center;
justify-content: space-between;
}
#risque_prevoyance > .sante_risque {
min-width: calc(25% - 2rem);
margin: 1rem;
padding: 25px 15px;
border-right: 1px solid #4d25bc !important;
border-radius: 0.358rem;
}
#risque_prevoyance > .sante_risque.active {
background-color: #4d25bc;
color: #fff;
}
#risque_prevoyance .btn-outline-primary:hover:not(.disabled):not(:disabled) {
background-color: #4d25bc;
color: #fff;
transition: 0.4s;
}
#addprojectform .nav-tabs .nav-link {
background-color: rgba(77, 37, 188, 0.08);
color: #4d25bc;
}
#addprojectform .nav-tabs .nav-link.active {
color: #ffffff;
background-color: #4d25bc;
}
.vue-form-wizard
.wizard-navigation
.wizard-nav
li
.wizard-icon-circle
.wizard-icon-container {
background-color: #4d25bc !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked {
border-color: #4d25bc !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li a .checked
{
background-color: #4d25bc !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li a .checked i
{
color: #fff !important;
}
.wizard-btn {
background-color: #4d25bc !important;
border-color: #4d25bc !important;
}
.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn {
border-color: #4d25bc !important;
color: #4d25bc !important;
}
.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn:hover {
color: #fff !important;
background-color: #4d25bc !important;
transition: 0.3s;
box-shadow: 0 8px 25px -8px #4d25bc;
}
.vue-form-wizard
.wizard-navigation
.wizard-nav
li.active
a
.checked
~ .stepTitle {
color: #4d25bc !important;
}
@media only screen and (max-width: 768px) {
#risque_prevoyance > .sante_risque {
  max-width: calc(50% - 2rem);
}
}

#addprojectform h4 span {
font-size: 15px;
color: #000;
}

.padding_pricing
{
padding: 0rem 1.5rem 1.5rem;
}
.propositions .card-body
{
padding: 0 !important;
}
.propositions .card-header
{
background-color: #4d25bc;
color: #fff;
justify-content: center;
font-weight:bold;
font-size: 1.2rem;
}
.propositions .card button
{
border-top-left-radius: 0 !important;
border-top-right-radius: 0 !important;
padding: 15px 0px;
}
.box-custom-text{
color: #7353ca;
}
.custom-tarif{
    background-color: rgb(171 153 223 / 12%) !important;
    padding: 0;
}
</style>