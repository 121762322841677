<template>
    <div style="margin-top: 30px">
      <b-overlay :show="showLoading" no-wrap />
  
      <validation-observer ref="AssureRules">
        <b-form>
          <!--Assuré principal-->
          <b-row>
            <b-col cols="12">
              <b-row>
                <!-- Date d'effet -->
                <b-col md="3">
                  <span v-b-popover.hover.top.html="infoDateEffet" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    title="Information" class="" variant="outline-primary">
  
                    <b-form-group label="Date d'effet *" label-for="date-effet">
                      <validation-provider #default="{ errors }" name="date d'effet" rules="required">
                        <flat-pickr id="date-effet-fma" v-model="composeData.objet.date_effet"
                          :class="errors.length > 0 ? 'is-invalid' : ''" :config="options.dateEffet" class="form-control"
                          placeholder="Date d'effet" rules="required" autocomplete="off" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </span>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="12">
              <h4 class="title-custom-wizard"><u>Assuré principal (signataire)</u></h4>
            </b-col>
  
            <!-- Date de naissance -->
            <b-col md="3">
              <b-form-group label="Date de naissance *" label-for="date-naissance">
                <validation-provider #default="{ errors }" name="date de naissance" rules="required">
                  <flat-pickr id="date-naissance-assure-principal-fma" v-model="composeData.assure.date_naissance"
                    :class="errors.length > 0 ? 'is-invalid' : ''" :config="options.dateNaissance" class="form-control"
                    placeholder="Date de naissance" autocomplete="off" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }}
                  </b-form-invalid-feedback>
                  <b-form-invalid-feedback :state="errorDateNaissanceProspect ? false : null"> {{ errorDateNaissanceProspect
                  }} </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row  class="mt-2">
            <!-- Propreitaire residence principal -->
            <!-- <b-col md="3">
                <b-form-group label="Propriétaire d'une résidence principale ? *">
                  <validation-provider v-slot="{ errors }" name="propriétaire d'une résidence principale"
                    rules="required">
                    <b-form-radio-group v-model="composeData.objet.propreitaire_residence_principal" :options="options.booleanValue"/>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col> -->

              <!-- Propreitaire residence secondaire -->
              <!-- <b-col md="4">
                <b-form-group label="Propriétaire d'une résidence secondaire ? *">
                  <validation-provider v-slot="{ errors }" name="propriétaire d'une résidence secondaire"
                    rules="required">
                    <b-form-radio-group v-model="composeData.objet.propreitaire_residence_secondaire" :options="options.booleanValue" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col> -->
          </b-row>
        </b-form>
      </validation-observer>
      <!-- Buttons -->
      <b-row class="mt-4">
        <b-col lg="12">
          <b-button class="float-left" size="lg" variant="primary" @click="backStep"> Précédent </b-button>
          <b-button class="float-right" size="lg" variant="primary" @click="getTarification()"> Suivant </b-button>
        </b-col>
      </b-row>
    </div>
  </template>
  
  <script>
  import {
    BButton, VBPopover, BCol, BForm, BFormGroup, BFormInvalidFeedback, BFormRadioGroup, BFormSelect, BOverlay, BRow, VBTooltip, BFormInput
  } from 'bootstrap-vue'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import flatPickr from 'vue-flatpickr-component'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import Cleave from 'vue-cleave-component'
  import moment from 'moment'
  import Ripple from 'vue-ripple-directive'
  import { mapGetters } from 'vuex'
  
  export default {
    components: {
      BRow,
      BCol,
      BButton,
      BForm,
      BOverlay,
      BFormGroup,
      BFormInvalidFeedback,
      BFormRadioGroup,
      BFormSelect,
      ValidationProvider,
      ValidationObserver,
      flatPickr,
      ToastificationContent,
      Cleave,
      BFormInput
    },
    directives: {
      'b-tooltip': VBTooltip,
      'b-popover': VBPopover,
      Ripple,
    },
    computed: {
      ...mapGetters(["getQWCheckedRisque"]),
    },
    watch: {
      getQWCheckedRisque: {
        immediate: true,
        handler(val) {
          if(val) {
            this.getProduits(val)
          }
        }
      }
    },
    data() {
      return {
        infoDateEffet:"<b>ECA</b> : la date d'effet doit être au 1er du mois et incluse dans un bimestre suivant la date du jour.",
        composeData: {
            objet: {
              date_effet: null,
              // propreitaire_residence_principal: 0,
              // propreitaire_residence_secondaire: 0
            },
            assure: {
                date_naissance: null,
            }
        },
        produit: {
          produit_id: '',
          risque_id: '',
          produit_nom: '',
          nom_assureur: '',
          nom_com_assureur: ''
        },
        showLoading: false,
        errorDateNaissanceProspect: null,
        // Configuration
        options: {
          booleanValue: [
            {
              value: 1,
              text: 'Oui',
            },
            {
              value: 0,
              text: 'Non',
            },
          ],
          dateNaissance: {
            dateFormat: 'd/m/Y',
            maxDate: new Date(),
            allowInput: true,
            onKeyDown: (selectedDates, dateStr, instance, event) => {
              if (event.keyCode != 8) {
                const ele = document.activeElement
                let val = ele.value
                if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                  val += '/'
                }
                ele.value = val
                ele.dispatchEvent(new Event('input'))
              }
            },
          },
          dateEffet: {
            dateFormat: 'd/m/Y',
            disable: [
              function (date) {
                const today = new Date()
                today.setDate(today.getDate())
                today.setHours(0, 0, 0, 0)
                return date < today
              },
              function (date) {
                const dateMaxECA = new Date()
                dateMaxECA.setFullYear(dateMaxECA.getFullYear() + 1, 0, 1);
                dateMaxECA.setHours(0, 0, 0, 0)
                return date.getDate() != 1 || dateMaxECA < date 
              },
            ],
            allowInput: true,
            onKeyDown: (selectedDates, dateStr, instance, event) => {
              if (event.keyCode != 8) {
                var ele = document.activeElement
                var val = ele.value
  
                if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                  val += '/'
                }
                ele.value = val
                ele.dispatchEvent(new Event('input'))
              }
            },
          },
          config: {
            dateFormat: "d/m/Y",
            allowInput: true,
            onKeyDown: (selectedDates, dateStr, instance, event) => {
              if (event.keyCode != 8) {
                var ele = document.activeElement;
                var val = ele.value;
  
                if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                  val += "/";
                }
                ele.value = val;
                ele.dispatchEvent(new Event("input"));
              }
            },
          },
        },
      }
    },
    validations: {
      composeData: {
      }
    },
    mounted() {
      this.initDateEffet()
    },
    methods: {
      getProduits(label) {
        this.$http.get(`ecaglobal/getProduitsByRisque/${label}`).then((response)=>{
          let risque = response.data
          if(Object.keys(risque).length != 0 && risque.produits.length > 0) {
            var produit = risque.produits[0]
            this.initProduit(produit)
            this.$store.commit("setProduitTarificateur", {produit: this.produit});
          } else {
            this.$store.commit("setProduitTarificateur", {produit: this.initState().produit});
          }
        }).catch((err)=>{
          
        })
      },
      initProduit(produit) {
        this.produit.produit_id       = produit.id
        this.produit.risque_id        = produit.risque_id
        this.produit.produit_nom      = produit.produit_nom
        this.produit.nom_assureur     = produit.assureur.nom_assureur
        this.produit.nom_com_assureur = produit.assureur.nom_com_assureur
      },
      async getTarification() {
        this.showLoading = true
        // First validation fields
        const isValidFields = await this.$refs.AssureRules.validate()
        if (!isValidFields) { this.showLoading = false; return 0 }

        const isValidDateEffet = await this.validationDateEffet()
        if (!isValidDateEffet) { this.showLoading = false; return 0 }

        // Send request to get tarification
        await this.$http.post(`quickwins/calculTarifProtectionJuridique`, this.composeData).then(async (response) => {
          await this.$store.commit("setQWObjet", {objet: this.composeData.objet});
          await this.$store.commit("setQWProspect", {prospect: this.composeData.assure});
          await this.$store.commit("setQWItemsRisque", {itemsRisque: response.data.data});
          await this.$store.commit("setQWStepTarificateur", {step: {indexStep: 2, active: 'comparatif'}});
        }).catch(e => {
          this.$store.commit("setQWItemsRisque", { itemsRisque: []});
          this.showLoading = false
        })

        this.showLoading=false
      },
      getTarifs(dataTarifs, dataProduits) {
        // switch case for get products by assureur 
        let assureur = Object.keys(dataTarifs)[0];
        switch (assureur) {
          case 'ECA_ASSURANCES':
            // that's the point, ECA has 2 products so we get each one
            let dpECAPJ = dataProduits.find(produit => 'PROTECTION_JURIDIQUE' in produit) ? dataProduits.find(produit => 'PROTECTION_JURIDIQUE' in produit)['PROTECTION_JURIDIQUE'] : {};
            let prgnECACS = dpECAPJ.hasOwnProperty('produitGaranties') ? dpECAPJ.produitGaranties.find(obj => obj.produit_id == 19).produitgarantiesniveaux : {}
            let filterdDataTarifsCS = dataTarifs['ECA_ASSURANCES'];            
            let tarifCS = filterdDataTarifsCS.length > 0 ? this.getTarifFront(filterdDataTarifsCS, dpECAPJ, prgnECACS) : [];  
            let tarifFinal = [tarifCS]
            let dpFinal = [dpECAPJ]
            return {
              tarif: tarifFinal,
              dp: dpFinal
            }
          default:
            return []
        }
      },
      getTarifFront(dt, dp, prgn) {
        let tarif = []
        if(dp.hasOwnProperty('produitGaranties')) {
          tarif = dt.map(obj => {
            const newArray = dp.produitGaranties.map(item => {
              return ({
              ...item,
              tarif: obj.optionsCompatibles[item.libelle]?.tarif,
              isShow: item.produitgarantiesniveaux.length > 0,
              isSelectedProduitGarantieNiveauID: item.produitgarantiesniveaux.find(pgn => pgn.libelle.toLowerCase() === 'non')?.id,
              produitName: dp.produit_nom
            })}).filter(item => item.tarif) // filter out undefined tarif values
            const sortedArray = newArray.sort((a, b) => a.order - b.order);
            const matchingNiveau = prgn.find(niv => niv.libelle === obj.formule)
            return {
              ...obj,
              tauxDeCommission: 0,
              tarifsBase: { ...obj.tarifs },
              optionCompatiblesWithGaranties: sortedArray || null,
              niveauGarantie: matchingNiveau || null,
            }
          })
        }
        return tarif
      },
      backStep() {
        this.$store.commit('setQWStepTarificateur', { step: { indexStep: 0, active: 'choix' } });
        this.$store.commit("setQWCheckedRisque", null);
        this.$store.commit("setQWHasProduitAutoriser", { hasProduitAutoriser: false });
        this.$store.commit("setProduitTarificateur", { produit: {} });
      },
      changeDisabledDate(day) {
        this.options.dateEffet.disable = [
          function (date) {
            const today = new Date()
            today.setDate(today.getDate() + day)
            today.setHours(0, 0, 0, 0)
            return date < today
          },
          function (date) {
            const dateMaxECA = new Date()
            dateMaxECA.setFullYear(dateMaxECA.getFullYear() + 1, 0, 1);
            dateMaxECA.setHours(0, 0, 0, 0)
            return date.getDate() != 1 || dateMaxECA < date 
          },
        ]
      },
      initDateEffet() {
        const today = moment()
        const DayOfMonth = today.clone().startOf('day').format('DD')
        if (DayOfMonth >= 15 && DayOfMonth <= 31) {
          today.set('month', today.month() + 1)
          today.startOf('month').startOf('day')
        } else {
          today.startOf('day')
        }
  
        this.composeData.objet.date_effet = moment(today).format('DD/MM/YYYY')
      },
      validationDateEffet() {
        const dateDuJour = moment().format('YYYY-MM-DD')
        const effetDate = moment(this.composeData.objet.date_effet, 'DD/MM/YYYY').format('YYYY-MM-DD')
        const dateDuJourPlus9Mois = moment().add(9, 'months').format('YYYY-MM-DD')
        this.changeDisabledDate(0)
        return moment(effetDate).isBetween(dateDuJour, dateDuJourPlus9Mois, null, '[]')
      },
    },
  }
  </script>
  
  <style lang="scss"></style>
  
  <style lang="scss" scoped>
  .title-custom-wizard {
    color: #4d25bc;
    margin-bottom: 20px;
  }
  
  label {
    font-weight: 900;
  }
  
  ::-webkit-input-placeholder {
    /* WebKit browsers */
    text-transform: none;
  }
  
  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    text-transform: none;
  }
  
  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    text-transform: none;
  }
  
  :-ms-input-placeholder {
    /* Internet Explorer 10+ */
    text-transform: none;
  }
  
  ::placeholder {
    /* Recent browsers */
    text-transform: none;
  }</style>
  